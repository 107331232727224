import Layout from '../../components/layouts/base.jsx';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { axiosInstance as axios } from '../../lib/axios.js';
import { useState, useRef, useEffect, useCallback } from 'react';

const LanguageSchema = Yup.object().shape({
  name: Yup.string().required('Name must Required'),
  seoName: Yup.string(),
  seoDescription: Yup.string(),
});

const customStyles = {
  rows: {
    style: {
      minHeight: '72px',
      width: '100%',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      width: '10vw',
      height: '7vh',
    },
  },
};

export default function Language() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [refetch, setrefetch] = useState(false);
  const [activeCategory, setActiveCategory] = useState({});

  const fetchData = useCallback(
    async (page) => {
      setLoading(true);
      const res = await axios.get(
        `/language/paginate?page=${page}&limit=${perPage}`
      );
      setData(res.data);
      setTotalRows(res.data.total);
      setLoading(false);
    },
    [perPage]
  );

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const res = await axios.get(
      `/language/paginate?page=${page}&limit=${newPerPage}`
    );
    setData(res.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [fetchData, refetch]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);

  const toast = useToast();

  const fetch = () => setrefetch(!refetch);

  const deleteLanguageType = async (_) => {
    const _res = await axios.delete(`/language/${_._id}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
  };

  const _openeditModel = (_data) => {
    onOpen();
    setActiveCategory({ ..._data });
  };

  const columns = [
    {
      name: 'S/N',
      selector: (row, index) => index + 1,
      sortable: true,
      width: '7%',
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: 'Delete',
      cell: (_r) => (
        <Button colorScheme={'red'} onClick={() => deleteLanguageType(_r)}>
          Delete
        </Button>
      ),
    },
    {
      name: 'Edit',
      cell: (_r) => (
        <Button colorScheme={'blue'} onClick={() => _openeditModel(_r)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
      <Layout>
        <Flex
          as={'main'}
          w={'100%'}
          bg={useColorModeValue('gray.50', 'gray.800')}
        >
          <Box
            bg={useColorModeValue('white', 'gray.700')}
            px={6}
            boxShadow={'md'}
            p={6}
            m={6}
            rounded={'xl'}
            w={'30%'}
          >
            <Text as={'h1'} fontSize={'2xl'} pb={'4'}>
              Managing Languages
            </Text>
            <Formik
              initialValues={{
                name: '',
                seoName: '',
                seoDescription: '',
              }}
              onSubmit={async (
                { name, seoName, seoDescription },
                { resetForm }
              ) => {
                const res = await axios.post('/language/', {
                  name,
                  seoName,
                  seoDescription,
                });

                if (res.data.ok) {
                  toast({
                    description: res.data.message,
                    status: 'success',
                    position: 'bottom-right',
                  });
                  resetForm();
                  fetch();
                } else {
                  toast({
                    description: res.data.message,
                    status: 'error',
                    position: 'bottom-right',
                  });
                }
              }}
              validationSchema={LanguageSchema}
            >
              {({ errors, touched, resetForm, values, setFieldValue }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl
                      id="name"
                      isInvalid={errors.name}
                      onChange={(e) => {
                        setFieldValue('seoName', e.target.value);
                      }}
                    >
                      <FormLabel>Name</FormLabel>
                      <Field
                        as={Input}
                        type="text"
                        name="name"
                        placeholder="Language"
                      />
                      {touched.name && errors.name && (
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="seoName"
                      isInvalid={touched.seoName && errors.seoName}
                    >
                      <FormLabel>Seo Title</FormLabel>
                      <Field as={Input} type="text" name="seoName" />
                      {errors.seoName && (
                        <FormErrorMessage>{errors.seoName}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="seoDescription"
                      name="seoDescription"
                      isInvalid={
                        touched.seoDescription && errors.seoDescription
                      }
                    >
                      <FormLabel>Seo Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="seoDescription"
                        value={values.seoDescription}
                      />
                      {errors.seoDescription && (
                        <FormErrorMessage>
                          {errors.seoDescription}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <Button
                    bg={'blue.400'}
                    mt={4}
                    type={'submit'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                  >
                    Create
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
          <Box
            rounded={'xl'}
            px={4}
            boxShadow={'md'}
            w={'70%'}
            m={6}
            backgroundColor={'#FFFFFF'}
            border={'1px'}
            borderColor={'gray.200'}
          >
            <DataTable
              striped
              columns={columns}
              data={data.data}
              pagination
              paginationServer
              progressPending={loading}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeader
              persistTableHead
              customStyles={customStyles}
            />
          </Box>
        </Flex>
      </Layout>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setActiveCategory({});
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Gazettes Type</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={'flex'} flexDirection={'column'}>
            <Formik
              initialValues={{
                _name: activeCategory.name,
                _seoName: activeCategory.seoName,
                _seoDescription: activeCategory.seoDescription,
              }}
              onSubmit={async (
                { _name, _seoName, _seoDescription },
                { resetForm }
              ) => {
                const res = await axios.put(`/language/${activeCategory._id}`, {
                  name: _name,
                  seoName: _seoName,
                  seoDescription: _seoDescription,
                });

                if (res.data.ok) {
                  toast({
                    description: res.data.message,
                    status: 'success',
                    position: 'bottom-right',
                  });
                  resetForm();
                  onClose();
                  fetch();
                } else {
                  toast({
                    description: res.data.message,
                    status: 'error',
                    position: 'bottom-right',
                  });
                }
              }}
            >
              {({
                errors: _e,
                touched: _t,
                resetForm,
                values,
                setFieldValue,
              }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl id="_name" isInvalid={_e._name}>
                      <FormLabel>Name</FormLabel>
                      <Field as={Input} type="text" name="_name" />
                      {_t._name && _e._name && (
                        <FormErrorMessage>{_e._name}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="_seoName"
                      isInvalid={_t._seoName && _e._seoName}
                    >
                      <FormLabel>Seo Title</FormLabel>
                      <Field as={Input} type="text" name="_seoName" />
                      {_e._seoName && (
                        <FormErrorMessage>{_e._seoName}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="_seoDescription"
                      name="_seoDescription"
                      isInvalid={_t._seoDescription && _e._seoDescription}
                    >
                      <FormLabel>Seo Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="_seoDescription"
                        value={values._seoDescription}
                      />
                      {_e._seoDescription && (
                        <FormErrorMessage>
                          {_e._seoDescription}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <ModalFooter>
                    <Button type="submit" colorScheme="blue" mr={3}>
                      Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
