import "./styles/globals.scss";
import "react-phone-input-2/lib/style.css";

import GlobalContext from "./context/GlobalContext";
import { useState, useEffect } from "react";
import { get } from "./common/storage";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { Box } from "@chakra-ui/react";
import AuthComponent from "./pages/auth/action";

import {
  UserManagment,
  Sectors,
  ContractsAndClauses,
  Countries,
  Courts,
  JudgmentCategory,
  DraftingNotes,
  Plans,
  State,
  Lawsandregulatory,
  JudgmentandCaseLaws,
  ContactOptions,
  AboutOptions,
  MembersListing,
  LegalSoftware,
  ClickRateSoftware,
  Books,
  Resources,
  FaqManager,
  CookiesManagment,
  PrivacyPolicy,
  TermsAndConditions,
  Gallery,
  AggrementType,
  CheckList,
  IndustryType,
  Instituions,
  GazettesType,
  Language,
  Central,
  Gazzets,
  Local,
  District,
  MemberPolicy,
  ImportantLinks,
} from "./pages/admin";
import NotFound from "./pages/404";
import { Navigate } from "react-router-dom";

function App({ Component, pageProps }) {
  const [loggedIn, setLoggedIn] = useState();
  const [user, setUser] = useState();
  const [role, setRole] = useState();

  useEffect(() => {
    setLoggedIn(get("loggedIn"));
    setUser(get("user"));
    setRole(get("role"));
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        user,
        setUser,
        role,
        setRole,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={<Navigate to={"/auth/admin/signin"} />}
          />
          <Route exact path="/auth/admin/signin" element={<AuthComponent />} />
          <Route
            exact
            path="/auth/user/signin"
            element={<Navigate to={"/auth/admin/signin"} />}
          />
          <Route
            exact
            path="/admin/usermanagment"
            element={<UserManagment />}
          />
          <Route exact path="/admin/sectors" element={<Sectors />} />
          <Route exact path="/admin/plans" element={<Plans />} />
          <Route exact path="/admin/courts" element={<Courts />} />
          <Route exact path="/admin/countries" element={<Countries />} />
          <Route exact path="/admin/cac" element={<ContractsAndClauses />} />
          <Route exact path="/admin/instituions" element={<Instituions />} />
          <Route exact path="/admin/gazettestype" element={<GazettesType />} />
          <Route exact path="/admin/gazzets" element={<Gazzets />} />
          <Route exact path="/admin/language" element={<Language />} />
          <Route exact path="/admin/central" element={<Central />} />
          <Route exact path="/admin/local" element={<Local />} />
          <Route
            exact
            path="/admin/aggrementtype"
            element={<AggrementType />}
          />
          <Route
            exact
            path="/admin/lawsandregulatory"
            element={<Lawsandregulatory />}
          />
          <Route
            exact
            path="/admin/judgmentandcaselaws"
            element={<JudgmentandCaseLaws />}
          />
          <Route
            exact
            path="/admin/judgementcategory"
            element={<JudgmentCategory />}
          />
          <Route exact path="/admin/industrytype" element={<IndustryType />} />
          <Route exact path="/admin/states" element={<State />} />
          <Route exact path="/admin/district" element={<District />} />
          <Route exact path="/admin/members" element={<MembersListing />} />
          <Route exact path="/admin/libraryandebooks" element={<Books />} />
          <Route
            exact
            path="/admin/legalsoftware"
            element={<LegalSoftware />}
          />
          <Route
            exact
            path="/admin/clickrate"
            element={<ClickRateSoftware />}
          />
          <Route exact path="/admin/resources" element={<Resources />} />
          <Route exact path="/admin/contactsec" element={<ContactOptions />} />
          <Route exact path="/admin/aboutsec" element={<AboutOptions />} />
          {/* common settings pages */}
          <Route exact path="/admin/faq" element={<FaqManager />} />
          <Route exact path="/admin/cookies" element={<CookiesManagment />} />
          <Route exact path="/admin/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/admin/memberspolicy" element={<MemberPolicy />} />
          <Route exact path="/admin/terms" element={<TermsAndConditions />} />
          <Route exact path="/admin/gallery" element={<Gallery />} />
          <Route exact path="/admin/drafting" element={<DraftingNotes />} />
          <Route exact path="/admin/checklist" element={<CheckList />} />
          <Route
            exact
            path="/admin/importantlinks"
            element={<ImportantLinks />}
          />

          {/* all */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <Box id="loading" className="loader hidden">
        <HashLoader size={100} className="loading-bar" color="#f0f0f0" />
      </Box>
    </GlobalContext.Provider>
  );
}

export default App;
