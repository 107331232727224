export const save = function (key, data) {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const deletevalue = function (key) {
  sessionStorage.removeItem(key);
};

export const get = function (key) {
  return JSON.parse(sessionStorage.getItem(key));
};

export const wipeOut = function () {
  sessionStorage.clear();
};
