import axios from "axios";
import { get } from "../common/storage";
import { config } from "../common/config";
// import { _logoutuser } from "../functions/authfunctions";

const axiosInstance = axios.create({
  baseURL: config.baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((req) => {
  const loader = document.getElementById("loading");

  const url = req.url;

  const loadingExceptions = ["/search"];

  if (
    !url.includes(
      loadingExceptions.find((exception) => url.includes(exception))
    )
  )
    loader.classList.remove("hidden");

  if (get("role") === "admin") req.headers.authorization = get("admintoken");
  else req.headers.authorization = "";
  return req;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const loader = document.getElementById("loading");

    loader.classList.add("hidden");
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message === "Unauthorized"
    ) {
      const loader = document.getElementById("loading");

      loader.classList.add("hidden");
      return {
        data: {
          message: "Unauthorized",
          status: 401,
        },
      };
    } else {
      const loader = document.getElementById("loading");

      loader.classList.add("hidden");

      return {
        data: error.response.data,
        ok: false,
        status: error.response.status,
      };
    }
  }
);

export { axiosInstance };
