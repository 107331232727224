import Layout from "../../components/layouts/base.jsx";
import { useState, useMemo, useRef, useEffect } from "react";
import { Link as NextLink, useLocation } from "react-router-dom";

import {
  Box,
  Flex,
  Link,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Icon,
  IconButton,
  Textarea,
  FormHelperText,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { axiosInstance as axios } from "../../lib/axios.js";
import {
  FaEllipsisV,
  FaEdit,
  FaFile,
  FaTrash,
  FaPlusCircle,
} from "react-icons/fa";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      width: "100%",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      color: "black",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "6vw",
      height: "7vh",
      color: "black",
    },
  },
};

const PlansShcema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  countriesAllowed: Yup.number().required("Countries Allowed is Required"),
  modulesAllowed: Yup.number().required("Modules Allowed is Required"),
  frequency: Yup.string().required("Frequncy is Required"),
  amount: Yup.number().required("Amount is Required"),
  // discount: Yup.number().required("Discount is Required"),
  type: Yup.string().required("Type is Required"),
  isFeatured: Yup.boolean().required("Featured is Required"),
  tier: Yup.string().when("type", {
    is: (val) => val === "member",
    then: Yup.string().required("Tier is Required"),
  }),
});

export default function Lawsandregulatory() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [refetch, setrefetch] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/plans/paginate?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const res = await axios.get(
      `/plans/paginate?page=${page}&limit=${newPerPage}`
    );
    setData(res.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  const [selectedPlan, setselectedPlan] = useState({});

  const [currentOne, setCurrentOne] = useState({
    edit: false,
    data: {},
  });

  const fetch = () => setrefetch(!refetch);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: plansisOpen,
    onOpen: plansonOpen,
    onClose: plansonClose,
  } = useDisclosure();

  const toast = useToast();
  const initialRef = useRef(null);

  const openCreateModel = () => {
    setCurrentOne({
      edit: false,
      data: {},
    });
    onOpen();
  };

  const deletePlan = async (_r) => {
    const _res = await axios.delete("/plans/" + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    fetch();
  };

  const editCategory = async (_r) => {
    setCurrentOne({
      edit: true,
      data: _r,
    });
    onOpen();
  };

  const Actions = [
    {
      name: "Edit",
      icon: FaEdit,
      onClick: editCategory,
      colorScheme: "linkedin",
    },

    {
      name: "Delete",
      icon: FaTrash,
      onClick: deletePlan,
      colorScheme: "red",
    },
  ];

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "4%",
    },
    {
      name: "Name",
      cell: (_r) => (
        <Text
          onClick={() => {
            setselectedPlan(_r);
          }}
          _hover={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color={"#00A0DC"}
        >
          {_r.name}
        </Text>
      ),
      sortable: true,
    },
    {
      name: "For",
      selector: (row, i, column) => row.type?.toUpperCase(),
    },
    {
      name: "Countries",
      selector: (row, i, column) => row.countriesAllowed,
      sortable: true,
    },
    {
      name: "Modules",
      selector: (row, i, column) => row.modulesAllowed,
      sortable: true,
    },
    {
      name: "Frequency",
      selector: (row, i, column) => row.frequency,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row, i, column) => row.amount,
    },
    {
      name: "Discount",
      selector: (row, i, column) => row.discount,
    },
    {
      name: "Actions",
      cell: (_r) => (
        <Box position={"fixed"} zIndex={10}>
          <Popover closeOnBlur={true}>
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={6}>
              <PopoverHeader fontWeight="semibold" textAlign={"center"}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={"column"}>
                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      colorScheme={link.colorScheme}
                      onClick={() => link.onClick(_r)}
                      _hover={{
                        color: "black",
                      }}
                    >
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Box
        width={"100%"}
        display={"flex"}
        gap={4}
        margin={"0"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}>
          Plans
        </Text>
        <Box
          display={"flex"}
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <IconButton
            onClick={openCreateModel}
            m={4}
            colorScheme={"linkedin"}
            icon={<FaPlusCircle />}
          />
        </Box>
      </Box>
    );
  }, []);

  return (
    <Layout>
      <Flex
        as={"main"}
        w={"100%"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Modal
          initialFocusRef={initialRef}
          isOpen={isOpen}
          size={"6xl"}
          onClose={() => {
            onClose();
            setCurrentOne({
              edit: false,
              data: {},
            });
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {!currentOne.edit ? "Add New Plan" : "Edit"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
              <Box>
                <Formik
                  initialValues={{
                    name: currentOne.edit ? currentOne.data.name : "",
                    countriesAllowed: currentOne.edit
                      ? currentOne.data.countriesAllowed
                      : "",
                    modulesAllowed: currentOne.edit
                      ? currentOne.data.modulesAllowed
                      : "",
                    frequency: currentOne.edit ? currentOne.data.frequency : "",
                    type: currentOne.edit ? currentOne.data.type : "",
                    amount: currentOne.edit ? currentOne.data.amount : 0,
                    discount: currentOne.edit ? currentOne.data.discount : 0,
                    isFeatured: currentOne.edit
                      ? currentOne.data.isFeatured
                      : false,
                    tier: currentOne.edit ? currentOne.data.tier : "",
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    const url = currentOne.edit
                      ? "/plans/" + currentOne.data._id
                      : "/plans/create";

                    const _res = currentOne.edit
                      ? await axios.put(url, values)
                      : await axios.post(url, values);

                    if (_res.data.ok) {
                      toast({
                        description: _res.data.message,
                        status: "success",
                        position: "bottom-right",
                      });
                      onClose();
                      fetch();
                      setCurrentOne({
                        edit: false,
                        data: {},
                      });
                      //   !currentOne.edit && resetForm();
                    } else {
                      toast({
                        description: _res.data.message,
                        status: "error",
                        position: "bottom-right",
                      });
                    }
                  }}
                  validationSchema={PlansShcema}
                >
                  {({ errors, touched, values, setValues, setFieldValue }) => (
                    <Form>
                      <Stack spacing={4}>
                        <Flex
                          gap={10}
                          justifyContent={"space-between"}
                          alignItems={"start"}
                        >
                          <Stack spacing={8} width={"100%"}>
                            <FormControl
                              id="name"
                              isInvalid={touched.name && errors.name}
                            >
                              <FormLabel>Name of the Plan</FormLabel>
                              <Field
                                as={Input}
                                type="text"
                                name="name"
                                placeholder={"Name of the Plan"}
                              />
                              {errors.name && (
                                <FormErrorMessage>
                                  {errors.name}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="countriesAllowed"
                              isInvalid={
                                touched.countriesAllowed &&
                                errors.countriesAllowed
                              }
                            >
                              <FormLabel>Countries</FormLabel>
                              <Field
                                as={Input}
                                type={"number"}
                                placeholder={"Countries of the plan"}
                                name="countriesAllowed"
                              />
                              {errors.countriesAllowed && (
                                <FormErrorMessage>
                                  {errors.countriesAllowed}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="modulesAllowed"
                              isInvalid={
                                touched.modulesAllowed && errors.modulesAllowed
                              }
                            >
                              <FormLabel>Modules</FormLabel>
                              <Field
                                as={Input}
                                type={"number"}
                                placeholder={"Modules of the plan"}
                                name="modulesAllowed"
                              />
                              {errors.modulesAllowed && (
                                <FormErrorMessage>
                                  {errors.modulesAllowed}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="frequency"
                              isInvalid={touched.frequency && errors.frequency}
                              name={"frequency"}
                            >
                              <FormLabel>Frequency</FormLabel>
                              <Field
                                as={Select}
                                placeholder={"Select Frequency"}
                                name="frequency"
                              >
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                                <option value="daily">Daily</option>
                              </Field>
                              {errors.frequency && (
                                <FormErrorMessage>
                                  {errors.frequency}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="isFeatured"
                              isInvalid={
                                touched.isFeatured && errors.isFeatured
                              }
                              name={"isFeatured"}
                            >
                              <FormLabel>Featured Plan</FormLabel>
                              <Field
                                as={Select}
                                placeholder={"Select Frequency"}
                                name="isFeatured"
                              >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </Field>
                              {errors.isFeatured && (
                                <FormErrorMessage>
                                  {errors.isFeatured}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Stack>

                          <Stack spacing={8} width={"100%"}>
                            <FormControl
                              id="amount"
                              isInvalid={touched.amount && errors.amount}
                            >
                              <FormLabel>Amount</FormLabel>
                              <Field
                                as={Input}
                                type="number"
                                placeholder={"Select Amount of the plan"}
                                name="amount"
                              />

                              {errors.amount && (
                                <FormErrorMessage>
                                  {errors.amount}
                                </FormErrorMessage>
                              )}
                            </FormControl>

                            <FormControl
                              id="discount"
                              // isInvalid={touched.discount && errors.discount}
                            >
                              <FormLabel>Discount</FormLabel>
                              <Field
                                as={Input}
                                type="number"
                                placeholder={"Select Discount of the plan"}
                                name="discount"
                              />

                              {/* {errors.discount && (
                                <FormErrorMessage>
                                  {errors.discount}
                                </FormErrorMessage>
                              )} */}
                            </FormControl>
                            <FormControl
                              id="type"
                              isInvalid={touched.type && errors.type}
                              name={"type"}
                            >
                              <FormLabel>Plan For</FormLabel>
                              <Field
                                as={Select}
                                placeholder={"Select type"}
                                name="type"
                              >
                                <option value="user">User</option>
                                <option value="member">member</option>
                              </Field>
                              {errors.type && (
                                <FormErrorMessage>
                                  {errors.type}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            {values.type === "member" && (
                              <FormControl
                                id="tier"
                                isInvalid={touched.tier && errors.tier}
                                name={"tier"}
                              >
                                <FormLabel>Tier</FormLabel>
                                <Field
                                  as={Select}
                                  placeholder={"Select tier"}
                                  name="tier"
                                >
                                  <option value="basic">Basic</option>
                                  <option value="premium">Premium</option>
                                  <option value="gold">Gold</option>
                                </Field>
                                {errors.tier && (
                                  <FormErrorMessage>
                                    {errors.tier}
                                  </FormErrorMessage>
                                )}
                              </FormControl>
                            )}
                          </Stack>
                        </Flex>

                        <Box
                          display={"flex"}
                          gap={2}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Button
                            colorScheme={"linkedin"}
                            type={"submit"}
                            color={"white"}
                            _hover={{
                              bg: "blue.500",
                            }}
                            mb={"8"}
                          >
                            {currentOne.edit ? "Update" : "Create"}
                          </Button>

                          <Button
                            colorScheme={"red"}
                            type={"button"}
                            onClick={() => {
                              onClose();
                              setCurrentOne({
                                edit: false,
                                data: {},
                              });
                            }}
                            color={"white"}
                            _hover={{
                              bg: "red.500",
                            }}
                            mb={"8"}
                          >
                            Close
                          </Button>
                        </Box>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* Display the data of model */}

        <Modal
          initialFocusRef={initialRef}
          isOpen={plansisOpen}
          onClose={() => {
            plansonClose();
            setselectedPlan({});
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
              <Text as={"h3"}>Name</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedPlan.name}
              </Text>
              <Text as={"h3"}>Type</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedPlan.type}
              </Text>
              <Text as={"h3"}>Country</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedPlan.country}
              </Text>
              <Text as={"h3"}>Description</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedPlan.description}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme={"linkedin"}
                onClick={() => {
                  plansonClose();
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box
          rounded={"xl"}
          px={4}
          w={"100%"}
          m={6}
          backgroundColor={"#FFFFFF"}
          // m={10}
          border={"1px"}
          borderColor={"gray.200"}
        >
          <DataTable
            striped
            columns={columns}
            data={data.data}
            pagination
            // paginationResetDefaultPage={resetPaginationToggle}
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
          />
        </Box>
      </Flex>
    </Layout>
  );
}
