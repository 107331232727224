import Layout from '../../components/layouts/base.jsx';
import useFetch from '../../hooks/useFetch.jsx';
import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { Link as NextLink, useLocation } from 'react-router-dom';
import { config } from '../../common/config.js';
import { MultiSelect } from 'react-multi-select-component';
import { CloseIcon } from '@chakra-ui/icons';

import {
  Box,
  Flex,
  Link,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Select,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Icon,
  IconButton,
  Textarea,
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import { axiosInstance as axios } from '../../lib/axios.js';
import { FaEllipsisV, FaEdit, FaTrash, FaPlusCircle } from 'react-icons/fa';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import DeleteManyDialog from '../../components/admin/DeleteAll.jsx';

const customStyles = {
  rows: {
    style: {
      minHeight: '72px',
      width: '100%',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      color: 'black',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      width: '10vw',
      height: '7vh',
      color: 'black',
    },
  },
};

const GazetteSchema = Yup.object().shape({
  title: Yup.string().required('Title is Required'),
  type: Yup.string().required('Type is Required'),
  description: Yup.string().required('Description Required'),
  state: Yup.array(),
  local: Yup.array(),
  central: Yup.array(),
  country: Yup.array().required('Country is Required'),
  seoName: Yup.string(),
  seoDescription: Yup.string(),
  date: Yup.string().required('Date is Required'),
});

export default function JudgementandCase() {
  const [refetch, setrefetch] = useState(false);
  const [selectedLawsandReg, setSelectedLawsandReg] = useState({});
  const [seletedStates, setSelectedStates] = useState([]);

  const [selectedLocals, setSelectedLocals] = useState([]);
  const [selectedCentral, setSelectedCentral] = useState([]);

  const [selectedFile, changeFile] = useState(null);

  // states for table
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [currentSelectedRows, setcurrentSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [currentOne, setCurrentOne] = useState({
    edit: false,
    data: {},
  });

  const Types = [
    { _id: '1', name: 'State Gazette' },
    { _id: '2', name: 'Central Gazette' },
    { _id: '3', name: 'Local Gazette' },
  ];

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/gazettes/pagination?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handleSearch = async (e, page = 1) => {
    setLoading(true);
    const res = await axios.get(
      `/gazettes/search/${e}?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    if (!filterText) {
      fetchData(page);
      return;
    }
    handleSearch(filterText, page);
  };

  const handleRowSelected = useCallback((state) => {
    setcurrentSelectedRows(state.selectedRows);
  }, []);

  const handleMultipleDelete = async (s) => {
    let ids;
    if (Array.isArray(s)) {
      ids = s.map((row) => row._id).join(',');
    } else if (s === 'all') {
      ids = 'all';
    }
    const _res = await axios.delete(`/gazettes/deleteall/${ids}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
    setToggleCleared(!toggleCleared);
    setFilterText('');
    setcurrentSelectedRows([]);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (!filterText) {
      setLoading(true);
      const res = await axios.get(
        `/gazettes/pagination?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
      return;
    } else {
      setLoading(true);
      const res = await axios.get(
        `/gazettes/search/${filterText}?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  const {
    data: countries,
    loading: countriesLoading,
    error: countriesError,
  } = useFetch('/countries/');

  const fetch = () => setrefetch(!refetch);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: cacisOpen,
    onOpen: caconOpen,
    onClose: caconClose,
  } = useDisclosure();

  const toast = useToast();
  const initialRef = useRef(null);

  const openCreateModel = () => {
    setCurrentOne({
      edit: false,
      data: {},
    });
    onOpen();
  };

  const deleteCategory = async (_r) => {
    const _res = await axios.delete('/judgementandcase/' + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
    fetch();
  };

  // a custom component act like search bar

  const FilterComponent = ({ filterText, onFilter, onClear, addUser }) => (
    <Box display={'flex'} gap={4}>
      {currentSelectedRows.length > 0 && (
        <Button
          width={'120px'}
          colorScheme={'red'}
          onClick={() => {
            handleMultipleDelete(currentSelectedRows);
          }}
        >
          Delete ({currentSelectedRows.length})
        </Button>
      )}

      <Input
        id="search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        placeholder="Search"
        width={'300px'}
      />
      <IconButton
        colorScheme={'linkedin'}
        onClick={onClear}
        icon={<CloseIcon />}
      />
    </Box>
  );

  const editCategory = async (_r) => {
    setCurrentOne({
      edit: true,
      data: _r,
    });
    onOpen();

    const res = await axios.get(`/states/${_r.country._id}`);
    setSelectedStates(res.data.data);
  };

  const Actions = [
    {
      name: 'Edit',
      icon: FaEdit,
      onClick: editCategory,
      colorScheme: 'linkedin',
    },

    {
      name: 'Delete',
      icon: FaTrash,
      onClick: deleteCategory,
      colorScheme: 'red',
    },
  ];

  const columns = [
    {
      name: 'S/N',
      selector: (row, index) => index + 1,
      sortable: true,
      width: '7%',
    },
    {
      name: 'Title',
      cell: (_r) => (
        <Text
          onClick={() => {
            caconOpen();
            setSelectedLawsandReg(_r);
          }}
          _hover={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          color={'#00A0DC'}
        >
          {_r.title}
        </Text>
      ),
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row, i, column) => row.type,
      sortable: true,
    },

    {
      name: 'Place',
      selector: (row, i, column) =>
        row && row.country
          ? row.state.map((e) => e.name).join(' , ')
          : '-' + row && row.country
          ? row.country.map((e) => e.name).join(' , ')
          : '-',
    },

    {
      name: 'Month and Date',
      selector: (row, i, column) =>
        row.month > 1
          ? '0' + row.month + ',' + row.year
          : row.month + ',' + row.year,
      sortable: true,
    },
    // {
    //   name: "Document",
    //   selector: (row, i, column) =>
    //     row.document_name ? row.document_name : "Contract not uploaded",
    //   cell: (_r) =>
    //     _r.document_name ? (
    //       <Link
    //         href={`${config.baseURL}/CaC/download/${_r.document_name}`}
    //         target={"_blank"}
    //         _
    //       >
    //         <Button colorScheme={"linkedin"}>View</Button>
    //       </Link>
    //     ) : (
    //       <Button colorScheme={"linkedin"}>Upload</Button>
    //     ),
    // },
    {
      name: 'Actions',
      cell: (_r) => (
        <Box
          position={'fixed'}
          zIndex={10}
          _hover={{
            cursor: 'pointer',
          }}
        >
          <Popover closeOnBlur={true}>
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={6}>
              <PopoverHeader fontWeight="semibold" textAlign={'center'}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={'column'} flexGrow={'initial'}>
                  {_r.document_name ? (
                    <Button
                      onClick={() => {
                        window.open(
                          `${config.baseURL}/CaC/download/${_r.document_name}`,
                          '_blank'
                        );
                      }}
                      colorScheme={'linkedin'}
                    >
                      Document
                    </Button>
                  ) : (
                    <Button colorScheme={'linkedin'}>Upload</Button>
                  )}
                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      colorScheme={link.colorScheme}
                      onClick={() => link.onClick(_r)}
                      _hover={{
                        color: 'black',
                      }}
                    >
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        fetchData(1);
      }
    };

    return (
      <Box
        width={'100%'}
        display={'flex'}
        gap={4}
        margin={'0'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize="2xl" fontWeight={'extrabold'}>
          Manage Gazzets
        </Text>

        <Box display={'flex'} gap={2}>
          <DeleteManyDialog
            title={'Gazzets'}
            onDelete={() => handleMultipleDelete('all')}
          />
          <Box>
            <FilterComponent
              onFilter={(e) => {
                setFilterText(e.target.value);
                if (e.target.value && e.target.value.length > 3) {
                  handleSearch(e.target.value);
                } else if (!e.target.value) {
                  fetchData(1);
                }
                // get the input with id "search" and focus after 400 ms
                setTimeout(() => {
                  document.getElementById('search').focus();
                }, 200);
              }}
              onClear={handleClear}
              filterText={filterText}
            />
          </Box>
          <Button
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            onClick={() => {
              document.getElementById('file').value = '';
              document.getElementById('file').click();
            }}
          >
            Bulk Upload
          </Button>

          <IconButton
            onClick={openCreateModel}
            colorScheme={'linkedin'}
            icon={<FaPlusCircle />}
          />
        </Box>
      </Box>
    );
  }, [filterText, resetPaginationToggle, currentSelectedRows]);

  return (
    <Layout>
      <Flex
        as={'main'}
        w={'100%'}
        bg={useColorModeValue('gray.50', 'gray.800')}
      >
        <Modal
          initialFocusRef={initialRef}
          isOpen={isOpen}
          size={'6xl'}
          onClose={() => {
            onClose();
            setCurrentOne({
              edit: false,
              data: {},
            });
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader ml={10}>
              {!currentOne.edit ? 'Add New Gazette' : 'Edit'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6} display={'flex'} flexDirection={'column'}>
              <Formik
                initialValues={{
                  title: currentOne.edit ? currentOne.data.title : '',
                  type: currentOne.edit ? currentOne.data.type._id : '',
                  description: currentOne.edit
                    ? currentOne.data.description
                    : '',
                  state: currentOne.edit
                    ? currentOne.data.state.map((c) => ({
                        label: c.name,
                        value: c.name,
                      }))
                    : [],
                  local: currentOne.edit
                    ? currentOne.data.local.map((c) => ({
                        label: c.name,
                        value: c.name,
                      }))
                    : [],
                  central: currentOne.edit
                    ? currentOne.data.central.map((c) => ({
                        label: c.name,
                        value: c.name,
                      }))
                    : [],

                  country: currentOne.edit
                    ? currentOne.data.country.map((c) => ({
                        label: c.name,
                        value: c.name,
                      }))
                    : [],
                  // year: currentOne.edit ? currentOne.data.year : "",
                  // month: currentOne.edit ? currentOne.data.month : "",
                  date: currentOne.edit
                    ? currentOne.data.year +
                      '-' +
                      config.commonMonths[currentOne.data.month]
                    : '',
                  seoName: currentOne.edit ? currentOne.data.seoName : '',
                  seoDescription: currentOne.edit
                    ? currentOne.data.seoDescription
                    : '',
                }}
                onSubmit={async (values, { resetForm }) => {
                  const state = values.state.map((s) => s.value);
                  const country = values.country.map((s) => s.value);

                  let formData = new FormData();

                  formData.set('title', values.title);
                  formData.set(
                    'type',
                    Types.find((t) => t._id === values.type).name
                  );
                  formData.set('description', values.description);
                  formData.set('state', state);
                  formData.set('country', country);
                  formData.set('year', +values.date.split('-')[0]);
                  formData.set('month', +values.date.split('-')[1]);
                  formData.set('doc', selectedFile);
                  formData.set('seoName', values.seoName);
                  formData.set('seoDescription', values.seoDescription);

                  const url = currentOne.edit
                    ? '/gazettes/' + currentOne.data._id
                    : '/gazettes/create';

                  const _res = currentOne.edit
                    ? await axios.put(url, formData)
                    : await axios.post(url, formData);

                  if (_res.data.ok) {
                    toast({
                      description: _res.data.message,
                      status: 'success',
                      position: 'bottom-right',
                    });
                    // resetForm();
                    onClose();
                    fetch();
                    changeFile(null);
                    setCurrentOne({
                      edit: false,
                      data: {},
                    });
                  } else {
                    toast({
                      description: _res.data.message,
                      status: 'error',
                      position: 'bottom-right',
                    });
                  }
                }}
                validationSchema={GazetteSchema}
              >
                {({ errors, touched, values, setValues, setFieldValue }) => (
                  <Form>
                    <Flex
                      gap={16}
                      direction={'column'}
                      textAlign="center"
                      w={'100%'}
                    >
                      <Flex
                        direction={'row'}
                        justifyContent={'space-around'}
                        // alignItems={"center"}
                        gap={46}
                      >
                        <Stack spacing={4} width={'45%'}>
                          <FormControl
                            id="title"
                            isInvalid={touched.title && errors.title}
                            onChange={(e) => {
                              setFieldValue('seoName', e.target.value);
                            }}
                          >
                            <FormLabel>Title of the Gazette</FormLabel>
                            <Field as={Input} type="text" name="title" />
                            {errors.title && (
                              <FormErrorMessage>
                                {errors.title}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="type"
                            isInvalid={touched.type && errors.type}
                          >
                            <FormLabel>Type of the Gazette</FormLabel>
                            <Field
                              as={Select}
                              placeholder={'Select Type'}
                              name="type"
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  type: e.target.value,
                                  country: [],
                                });
                              }}
                            >
                              {Types.map((type) => (
                                <option key={type._id} value={type._id}>
                                  {type.name}
                                </option>
                              ))}
                            </Field>

                            {errors.type && (
                              <FormErrorMessage>{errors.type}</FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="country"
                            isInvalid={touched.country && errors.country}
                            isDisabled={!values.type}
                            name={'country'}
                          >
                            <FormLabel>Country</FormLabel>
                            <Field
                              as={MultiSelect}
                              placeholder={'Select Country'}
                              name="country"
                              labelledBy={'Select Country'}
                              value={values.country}
                              onChange={async (e) => {
                                try {
                                  setValues({
                                    ...values,
                                    country: e,
                                    state: [],
                                    court: [],
                                  });

                                  if (values.type === '2' && e.length > 0) {
                                    const _tempCont = e
                                      .map((_) => _.value)
                                      .join(',');

                                    const res = await axios.get(
                                      `/central/getbycountry/${_tempCont}`
                                    );
                                    setSelectedCentral(res.data.data);

                                    return;
                                  }

                                  const _tempCont = e
                                    .map((_) => _.value)
                                    .join(',');

                                  const res = await axios.get(
                                    `/states/${_tempCont}`
                                  );
                                  setSelectedStates(res.data.data);
                                } catch (err) {
                                  console.log(err);
                                }
                              }}
                              options={
                                !countriesLoading
                                  ? countries.data.map((e, i) => ({
                                      label: e.name,
                                      value: e.name,
                                    }))
                                  : []
                              }
                            ></Field>
                            {errors.country && (
                              <FormErrorMessage>
                                {errors.country}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          {values.type === '1' || values.type === '3' ? (
                            <FormControl
                              id="state"
                              isDisabled={values.country == ''}
                              isInvalid={touched.state && errors.state}
                            >
                              <FormLabel>States</FormLabel>
                              <Field
                                as={MultiSelect}
                                placeholder={'Select State'}
                                labelledBy={'Select State'}
                                name="state"
                                onChange={async (e) => {
                                  setValues({
                                    ...values,
                                    state: e,
                                    local: [],
                                  });
                                  try {
                                    if (values.type === '3' && e.length > 0) {
                                      const _tempCont = e
                                        .map((_) => _.value)
                                        .join(',');

                                      const res = await axios.get(
                                        '/local/state/' + _tempCont
                                      );

                                      setSelectedLocals(res.data.data);
                                    }
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                                options={
                                  seletedStates && seletedStates.length > 0
                                    ? seletedStates.map((e, i) => ({
                                        label: e.name,
                                        value: e.name,
                                      }))
                                    : []
                                }
                              ></Field>

                              {errors.country && (
                                <FormErrorMessage>
                                  {errors.country}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          ) : null}

                          {values.type === '3' ? (
                            <FormControl
                              id="local"
                              isDisabled={values.state == ''}
                              isInvalid={touched.local && errors.local}
                            >
                              <FormLabel>Local</FormLabel>
                              <Field
                                as={MultiSelect}
                                placeholder={'Select Local'}
                                labelledBy={'Select Local'}
                                name="local"
                                onChange={async (e) => {
                                  setValues({
                                    ...values,
                                    local: e,
                                  });
                                  try {
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                                options={
                                  selectedLocals && selectedLocals.length > 0
                                    ? selectedLocals.map((e, i) => ({
                                        label: e.name,
                                        value: e.name,
                                      }))
                                    : []
                                }
                              ></Field>

                              {errors.country && (
                                <FormErrorMessage>
                                  {errors.country}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          ) : null}

                          {values.type === '2' ? (
                            <FormControl
                              id="central"
                              isDisabled={values.country == ''}
                              isInvalid={touched.central && errors.central}
                            >
                              <FormLabel>Central</FormLabel>
                              <Field
                                as={MultiSelect}
                                placeholder={'Select Central'}
                                labelledBy={'Select Central'}
                                name="central"
                                onChange={async (e) => {
                                  setValues({
                                    ...values,
                                    central: e,
                                  });
                                  try {
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                                options={
                                  selectedCentral && selectedCentral.length > 0
                                    ? selectedCentral.map((e, i) => ({
                                        label: e.name,
                                        value: e.name,
                                      }))
                                    : []
                                }
                              ></Field>

                              {errors.country && (
                                <FormErrorMessage>
                                  {errors.country}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          ) : null}

                          <FormControl id="file">
                            <FormLabel>Document</FormLabel>
                            <Input
                              type={'file'}
                              // required={1}
                              accept={'application/pdf'}
                              onChange={(e) => {
                                changeFile(e.target.files[0]);
                                console.log(e.target.files);
                              }}
                            />
                          </FormControl>
                        </Stack>

                        <Stack spacing={4} width={'45%'}>
                          <FormControl
                            id="date"
                            isInvalid={touched.date && errors.date}
                          >
                            <FormLabel>Date</FormLabel>
                            <Field as={Input} type="month" name="date" />
                            {errors.date && (
                              <FormErrorMessage>{errors.date}</FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="description"
                            name="description"
                            isInvalid={
                              touched.description && errors.description
                            }
                          >
                            <FormLabel>Description</FormLabel>
                            <Field
                              as={Textarea}
                              type="text"
                              name="description"
                              value={values.description}
                            />
                            {errors.description && (
                              <FormErrorMessage>
                                {errors.description}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="seoName"
                            isInvalid={touched.seoName && errors.seoName}
                          >
                            <FormLabel>Seo Title</FormLabel>
                            <Field as={Input} type="text" name="seoName" />
                            {errors.seoName && (
                              <FormErrorMessage>
                                {errors.seoName}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="seoDescription"
                            name="seoDescription"
                            isInvalid={
                              touched.seoDescription && errors.seoDescription
                            }
                          >
                            <FormLabel>Seo Description</FormLabel>
                            <Field
                              as={Textarea}
                              type="text"
                              name="seoDescription"
                              value={values.seoDescription}
                            />
                            {errors.seoDescription && (
                              <FormErrorMessage>
                                {errors.seoDescription}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Stack>
                      </Flex>
                      <Flex
                        spacing={10}
                        ml={`${54 - 10}%`}
                        width={'40%'}
                        gap={6}
                      >
                        <Button
                          colorScheme={'linkedin'}
                          type={'submit'}
                          color={'white'}
                          _hover={{
                            bg: 'blue.500',
                          }}
                          mb={'8'}
                        >
                          {currentOne.edit ? 'Update' : 'Create'}
                        </Button>
                        <Button
                          colorScheme={'red'}
                          onClick={() => {
                            onClose();
                            setCurrentOne({
                              edit: false,
                              data: {},
                            });
                          }}
                          type={'submit'}
                          color={'white'}
                          _hover={{
                            bg: 'red.600',
                          }}
                          mb={'8'}
                        >
                          Close
                        </Button>
                      </Flex>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* Display the data of model */}

        <Modal
          initialFocusRef={initialRef}
          isOpen={cacisOpen}
          onClose={() => {
            caconClose();
            setSelectedLawsandReg({});
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody pb={6} display={'flex'} flexDirection={'column'}>
              <Text as={'h3'}>Name</Text>
              <Text
                fontWeight={'normal'}
                style={{
                  textIndent: '30px',
                }}
              >
                {selectedLawsandReg.name}
              </Text>
              <Text as={'h3'}>Type</Text>
              <Text
                fontWeight={'normal'}
                style={{
                  textIndent: '30px',
                }}
              >
                {selectedLawsandReg.type}
              </Text>
              <Text as={'h3'}>Country</Text>
              <Text
                fontWeight={'normal'}
                style={{
                  textIndent: '30px',
                }}
              >
                {selectedLawsandReg.country}
              </Text>
              <Text as={'h3'}>Description</Text>
              <Text
                fontWeight={'normal'}
                style={{
                  textIndent: '30px',
                }}
              >
                {selectedLawsandReg.description}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme={'linkedin'} onClick={caconClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box
          rounded={'xl'}
          px={4}
          w={'100%'}
          m={6}
          backgroundColor={'#FFFFFF'}
          // m={10}
          border={'1px'}
          borderColor={'gray.200'}
        >
          <DataTable
            striped
            columns={columns}
            data={data.data}
            paginationRowsPerPageOptions={[10, 100, 500, 1000, 2000, 5000]}
            pagination
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            paginationComponentOptions={{
              selectAllRowsItemText: 'All',
            }}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            selectableRows
            selectedRows={currentSelectedRows}
            onSelectedRowsChange={handleRowSelected}
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
            clearSelectedRows={toggleCleared}
          />
        </Box>
      </Flex>
    </Layout>
  );
}

const NavItem = ({ icon, children, url, ...rest }) => {
  const router = useLocation();
  return (
    <Link
      as={NextLink}
      href={url}
      color={'whatsapp.100'}
      style={{ textDecoration: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color={router.pathname == url ? 'whiteAlpha.900' : 'gray.400'}
        _hover={{
          bg: 'lightWhite',
          color: 'black',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'black',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

export async function getServerSideProps({ req }) {
  return {
    props: {},
  };
}
