import Layout from '../../components/layouts/base.jsx';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Textarea,
  Stack,
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Select,
  Badge,
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import { Formik, Field, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import useFetch from '../../hooks/useFetch.jsx';
import { axiosInstance as axios } from '../../lib/axios.js';
import { useState, useRef, useEffect, useMemo } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Dropdown } from 'primereact/dropdown';

const InstituionsSchema = Yup.object().shape({
  name: Yup.string().required('Instituions name is Mandatory'),
  country: Yup.string().required('Country is Mandatory'),
  description: Yup.string().required('Description is Mandatory'),
  seoName: Yup.string(),
  seoDescription: Yup.string(),
});

const customStyles = {
  responsiveWrapper: {
    style: {
      minHeight: '72px',
      overflowX: 'hidden',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      color: 'black',
      fontWeight: 'bold',
      width: '80%',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      width: '10vw',
      height: '7vh',
      color: 'black',
    },
  },
};

const fetchCountry = async () => {
  const res = await axios.get('/countries');
  return res.data;
};

export default function Instituions() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [country, setCountry] = useState([]);
  const [refetch, setrefetch] = useState(false);
  const [file, setFile] = useState(null);
  const [activeCountry, setActiveCountry] = useState({});

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/instituions/paginate?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const res = await axios.get(
      `/instituions/paginate?page=${page}&limit=${newPerPage}`
    );
    setData(res.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
    fetchCountry().then((res) => {
      const data = res.data.map((r) => {
        return {
          label: r.name,
          value: r._id,
        };
      });
      setCountry(data);
    });
  }, [refetch]);

  // a custom component act like search bar

  const FilterComponent = ({ filterText, onFilter, onClear, addUser }) => (
    <Box display={'flex'} gap={4}>
      <Input
        id="search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        placeholder="Search"
      />
      <IconButton
        colorScheme={'linkedin'}
        onClick={onClear}
        icon={<CloseIcon />}
      />
    </Box>
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);

  const toast = useToast();

  const fetch = () => setrefetch(!refetch);

  const deleteInstituions = async (_) => {
    const _res = await axios.delete(`/instituions/${_._id}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
  };

  const _openeditModel = (_data) => {
    onOpen();
    setActiveCountry({ ..._data });
  };

  const columns = [
    {
      name: 'S/N',
      selector: (row, index) => index + 1,
      sortable: true,
      width: '7%',
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Country',
      selector: (row) =>
        country?.find((_) => _.value === row.country)?.label || 'N/A',
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: 'Delete',
      cell: (_r) => (
        <Button colorScheme={'red'} onClick={() => deleteInstituions(_r)}>
          Delete
        </Button>
      ),
    },
    {
      name: 'Edit',
      cell: (_r) => (
        <Button colorScheme={'blue'} onClick={() => _openeditModel(_r)}>
          Edit
        </Button>
      ),
    },
  ];

  const handleSubmit = async (
    { name, country, description, seoName, seoDescription },
    { resetForm }
  ) => {
    if (!file) {
      toast({
        description: 'Please select a file',
        status: 'error',
        position: 'bottom-right',
      });
      return;
    }

    const formData = new FormData();
    formData.append('doc', file);
    formData.append('name', name);
    formData.append('country', country);
    formData.append('description', description);
    formData.append('seoName', seoName);
    formData.append('seoDescription', seoDescription);

    const res = await axios.post('/instituions', formData);

    if (res.data.ok) {
      toast({
        description: res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      resetForm();
      setFile(null);
      fetch();
    } else {
      toast({
        description: res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
  };

  const BulkUpload = async (file) => {
    if (!file)
      return toast({
        description: 'Please Select a file',
        status: 'error',
        position: 'bottom-right',
      });

    const formData = new FormData();
    formData.append('file', file);
    const res = await axios.post('/instituions/bulk', formData);

    if (res.data.ok) {
      toast({
        description: res.data.message,
        status: 'success',
        position: 'bottom-right',
        isClosable: true,
      });
      fetch();
    } else {
      toast({
        description: res.data.message,
        status: 'error',
        position: 'bottom-right',
        isClosable: true,
      });
    }

    document.getElementById('file').value = '';
  };

  let filteredItems =
    data && data.data
      ? data?.data?.filter((item) =>
          item.name.toLowerCase().includes(filterText.toLowerCase())
        )
      : [];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        margin={'0'}
      >
        <Text fontSize="2xl" fontWeight={'extrabold'}></Text>
        <FilterComponent
          onFilter={(e) => {
            setFilterText(e.target.value);
            // get the input with id "search" and focus after 400 ms
            setTimeout(() => {
              document.getElementById('search').focus();
            }, 100);
          }}
          onClear={handleClear}
          filterText={filterText}
        />
      </Box>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <input
        type={'file'}
        id={'file'}
        accept={
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        }
        onChange={(e) => {
          BulkUpload(e.target.files[0]);
        }}
        style={{ display: 'none' }}
      />
      <Layout>
        <Flex
          as={'main'}
          w={'100%'}
          bg={useColorModeValue('gray.50', 'gray.800')}
        >
          <Box
            bg={useColorModeValue('white', 'gray.700')}
            px={6}
            m={6}
            rounded={'xl'}
            w={'30%'}
          >
            <Text as={'h1'} fontSize={'2xl'} pb={'4'}>
              Managing Instituions
            </Text>
            <Formik
              initialValues={{
                name: '',
                country: '',
                description: '',
                seoName: '',
                seoDescription: '',
              }}
              onSubmit={handleSubmit}
              validationSchema={InstituionsSchema}
            >
              {({ errors, touched, resetForm, values, setFieldValue }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl
                      id="name"
                      isInvalid={errors.name}
                      onChange={(e) => {
                        setFieldValue('seoName', e.target.value);
                      }}
                    >
                      <FormLabel>Name of the Institution</FormLabel>
                      <Field as={Input} type="text" name="name" />
                      {touched.name && errors.name && (
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="country"
                      isInvalid={touched.country && errors.country}
                    >
                      <FormLabel>Country</FormLabel>
                      {/* <Field
                        as={Select}
                        placeholder={"Select country"}
                        name="country"
                      >
                        {country.map((e, i) => (
                          <option key={i} value={e.value}>
                            {e.label}
                          </option>
                        ))}
                      </Field> */}
                      <Dropdown
                        filter
                        inputId="country"
                        name="country"
                        style={{ width: '100%' }}
                        value={values.country}
                        options={
                          country.length > 0
                            ? country?.map((id) => ({
                                name: id.label,
                                value: id.value,
                              }))
                            : []
                        }
                        optionLabel="name"
                        placeholder="Select a country"
                        onChange={(e) => {
                          setFieldValue('country', e.value);
                        }}
                      />
                      {errors.country && (
                        <FormErrorMessage>{errors.country}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl id="file">
                      <FormLabel>Image</FormLabel>
                      <Input
                        required
                        type={'file'}
                        accept={'image/*'}
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                    </FormControl>
                    <FormControl
                      id="description"
                      isInvalid={touched.description && errors.description}
                    >
                      <FormLabel>Description</FormLabel>
                      <Field as={Input} type="text" name="description" />
                      {errors.description && (
                        <FormErrorMessage>
                          {errors.description}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="seoName"
                      isInvalid={touched.seoName && errors.seoName}
                    >
                      <FormLabel>Seo Title</FormLabel>
                      <Field as={Input} type="text" name="seoName" />
                      {errors.seoName && (
                        <FormErrorMessage>{errors.seoName}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="seoDescription"
                      name="seoDescription"
                      isInvalid={
                        touched.seoDescription && errors.seoDescription
                      }
                    >
                      <FormLabel>Seo Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="seoDescription"
                        value={values.seoDescription}
                      />
                      {errors.seoDescription && (
                        <FormErrorMessage>
                          {errors.seoDescription}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <Button
                    bg={'blue.400'}
                    mt={4}
                    type={'submit'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                  >
                    Create
                  </Button>
                  <Button
                    bg={'blue.400'}
                    mt={4}
                    mx={4}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    onClick={() => {
                      document.getElementById('file').value = '';
                      document.getElementById('file').click();
                    }}
                  >
                    Bulk Upload
                  </Button>

                  <Badge my={3} variant="subtle" colorScheme="green">
                    <a
                      href="/assets/sample/institutions.xlsx"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sample Excel File
                    </a>
                  </Badge>
                </Form>
              )}
            </Formik>
          </Box>
          <Box
            rounded={'xl'}
            px={4}
            w={'70%'}
            m={6}
            backgroundColor={'#FFFFFF'}
            // m={10}
            border={'1px'}
            borderColor={'gray.200'}
          >
            <DataTable
              striped
              columns={columns}
              data={filteredItems.length > 0 ? filteredItems : data.data}
              paginationRowsPerPageOptions={[10, 100, 500, 1000, 2000, 5000]}
              pagination
              paginationServer
              progressPending={loading}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              subHeaderComponent={subHeaderComponentMemo}
              onChangePage={handlePageChange}
              subHeader
              persistTableHead
              customStyles={customStyles}
            />
          </Box>
        </Flex>
      </Layout>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setActiveCountry({});
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={'flex'} flexDirection={'column'}>
            <Formik
              initialValues={{
                name: activeCountry.name,
                country: activeCountry?.country?._id,
                description: activeCountry.description,
                seoName: activeCountry.seoName,
                seoDescription: activeCountry.seoDescription,
              }}
              onSubmit={async (
                { name, country, description, seoName, seoDescription },
                { resetForm }
              ) => {
                const formData = new FormData();
                formData.append('name', name);
                formData.append('country', country);
                formData.append('description', description);
                formData.append('seoName', seoName);
                formData.append('seoDescription', seoDescription);

                if (file) {
                  formData.append('doc', file);
                }

                const res = await axios.put(
                  `/instituions/${activeCountry._id}`,
                  formData
                );

                if (res.data.ok) {
                  toast({
                    description: res.data.message,
                    status: 'success',
                    position: 'bottom-right',
                  });
                  resetForm();
                  onClose();
                  fetch();
                } else {
                  toast({
                    description: res.data.message,
                    status: 'error',
                    position: 'bottom-right',
                  });
                }
              }}
            >
              {({ errors: _e, touched: _t, resetForm }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl id="name" isInvalid={_e.name}>
                      <FormLabel>Name</FormLabel>
                      <Field as={Input} type="text" name="name" />
                      {_t.name && _e.name && (
                        <FormErrorMessage>{_e.name}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="country"
                      isInvalid={_t.country && _e.country}
                    >
                      <FormLabel>Country</FormLabel>
                      <Field
                        as={Select}
                        placeholder={'Select country'}
                        name="country"
                      >
                        {country.map((e, i) => (
                          <option key={i} value={e.value}>
                            {e.label}
                          </option>
                        ))}
                      </Field>
                      {_t.country && _e.country && (
                        <FormErrorMessage>{_e.country}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl id="file">
                      <FormLabel>Image</FormLabel>
                      <Input
                        type={'file'}
                        accept={'image/*'}
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        isRequired={false}
                      />
                    </FormControl>
                    <FormControl
                      id="description"
                      isInvalid={_t.description && _e.description}
                    >
                      <FormLabel>Description</FormLabel>
                      <Field as={Input} type="text" name="description" />
                      {_t.description && _e.description && (
                        <FormErrorMessage>{_e.description}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="seoName"
                      isInvalid={_t.seoName && _e.seoName}
                    >
                      <FormLabel>Seo Title</FormLabel>
                      <Field as={Input} type="text" name="seoName" />
                      {_t.seoName && _e.seoName && (
                        <FormErrorMessage>{_e.seoName}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="seoDescription"
                      isInvalid={_t.seoDescription && _e.seoDescription}
                    >
                      <FormLabel>Seo Description</FormLabel>
                      <Field as={Textarea} type="text" name="seoDescription" />
                      {_t.seoDescription && _e.seoDescription && (
                        <FormErrorMessage>{_e.seoDescription}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <ModalFooter>
                    <Button type="submit" colorScheme="blue" mr={3}>
                      Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
