import Layout from "../../components/layouts/base.jsx";
import useFetch from "../../hooks/useFetch.jsx";
import { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { config } from "../../common/config.js";
import { MultiSelect } from "react-multi-select-component";
import Editor from "../../components/admin/Editor.jsx";
import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  IconButton,
  Textarea,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { axiosInstance as axios } from "../../lib/axios.js";
import { FaEllipsisV, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import DeleteManyDialog from "../../components/admin/DeleteAll.jsx";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      color: "black",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      height: "7vh",
      color: "black",
    },
  },
};

const judgementandCaseSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  type: Yup.string().required("Type is Required"),
  court: Yup.array().required("Court is Required"),
  description: Yup.string().required("Description Required"),
  state: Yup.array().required("State is Required"),
  judgmentCategory: Yup.array().required("Judgment Category is Required"),
  similar: Yup.array().optional(),
  country: Yup.array().required("Country is Required"),
  tribunals: Yup.string().required("Tribunals is Required"),
  seoName: Yup.string(),
  seoDescription: Yup.string(),
  date: Yup.string().required("Date is Required"),
});

export default function JudgementandCase() {
  const [refetch, setrefetch] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedLawsandReg, setSelectedLawsandReg] = useState({});
  const [seletedStates, setSelectedStates] = useState([]);
  const [selectedCourts, setSelectedCourts] = useState([]);
  const [selectedFile, changeFile] = useState(null);
  const [selectedFile2, changeFile2] = useState(null);
  const [similarList, setSimilarList] = useState({});
  const [editorText, setEditorText] = useState(false);

  // states for table
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [currentSelectedRows, setcurrentSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [selectAll, setSelectAll] = useState(false);

  const [currentOne, setCurrentOne] = useState({
    edit: false,
    data: {},
  });

  const [filterText, setFilterText] = useState("");

  const judgementandcaseByType = async (type) => {
    const res = await axios.get(`/judgementandcase/getbytype/${type}`);
    const resData = await res.data;
    setSimilarList(resData);
  };

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/judgementandcase/page/${page}/limit/${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    if (!filterText) {
      fetchData(page);
      return;
    }
    handleSearch(filterText, page);
  };

  const handleSearch = async (e, page = 1) => {
    setLoading(true);
    const res = await axios.get(
      `/judgementandcase/searchdocs/${e}?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handleRowSelected = useCallback((state) => {
    setcurrentSelectedRows(state.selectedRows);
  }, []);

  const handleMultipleDelete = async (s) => {
    let ids;
    if (s === "all") {
      ids = "all";
    } else {
      ids = currentSelectedRows.map((row) => row._id).join(",");
    }

    const _res = await axios.delete(`/judgementandcase/deleteall/${ids}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    setToggleCleared(!toggleCleared);
    setFilterText("");
    setcurrentSelectedRows([]);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (!filterText) {
      setLoading(true);
      const res = await axios.get(
        `/judgementandcase/page/${page}/limit/${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
      return;
    }
    setLoading(true);
    const res = await axios.get(
      `/judgementandcase/searchdocs/${filterText}?page=${page}&limit=${newPerPage}`
    );
    setData(res.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  const {
    data: countries,
    loading: countriesLoading,
    error: countriesError,
  } = useFetch("/countries/");
  const {
    data: judgmentCategory,
    loading: judgmentCategoryLoading,
    error: judgmentCategoryError,
  } = useFetch("/jcategories/");

  const fetch = () => setrefetch(!refetch);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: cacisOpen,
    onOpen: caconOpen,
    onClose: caconClose,
  } = useDisclosure();

  const toast = useToast();
  const initialRef = useRef(null);

  const openCreateModel = () => {
    setCurrentOne({
      edit: false,
      data: {},
    });
    onOpen();
  };

  const deleteCategory = async (_r) => {
    const _res = await axios.delete("/judgementandcase/" + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    fetch();
  };

  const editCategory = async (_r) => {
    setCurrentOne({
      edit: true,
      data: _r,
    });
    onOpen();

    console.log(_r);

    setEditorText(_r.docContent ? _r.docContent : "");

    const res = await axios.get(`/states/${_r.country._id}`);
    setSelectedStates(res.data.data);
  };

  const Actions = [
    {
      name: "Edit",
      icon: FaEdit,
      onClick: editCategory,
      colorScheme: "linkedin",
    },

    {
      name: "Delete",
      icon: FaTrash,
      onClick: deleteCategory,
      colorScheme: "red",
    },
  ];

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      cell: (_r) => (
        <Text
          onClick={() => {
            caconOpen();
            setSelectedLawsandReg(_r);
          }}
          _hover={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color={"#00A0DC"}
        >
          {_r.name}
        </Text>
      ),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row, i, column) => row.type,
      sortable: true,
    },
    {
      name: "Court",
      selector: (row, i, column) =>
        row && row.court ? row.court.map((e) => e.name).join(" , ") : "-",
      sortable: true,
    },
    {
      name: "Place",
      selector: (row, i, column) =>
        row && row.country
          ? row.state.map((e) => e.name).join(" , ")
          : "-" + row && row.country
          ? row.country.map((e) => e.name).join(" , ")
          : "-",
    },
    {
      name: "Tribunals",
      selector: (row, i, column) => row.tribunals,
      sortable: true,
    },
    {
      name: "Month and Date",
      selector: (row, i, column) =>
        row.month > 1
          ? "0" + row.month + "," + row.year
          : row.month + "," + row.year,
      sortable: true,
    },
    // {
    //   name: "Document",
    //   selector: (row, i, column) =>
    //     row.document_name ? row.document_name : "Contract not uploaded",
    //   cell: (_r) =>
    //     _r.document_name ? (
    //       <Link
    //         href={`${config.baseURL}/CaC/download/${_r.document_name}`}
    //         target={"_blank"}
    //         _
    //       >
    //         <Button colorScheme={"linkedin"}>View</Button>
    //       </Link>
    //     ) : (
    //       <Button colorScheme={"linkedin"}>Upload</Button>
    //     ),
    // },
    {
      name: "Actions",
      cell: (_r) => (
        <Box
          position={"fixed"}
          zIndex={10}
          _hover={{
            cursor: "pointer",
          }}
        >
          <Popover closeOnBlur={true}>
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={6}>
              <PopoverHeader fontWeight="semibold" textAlign={"center"}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={"column"} flexGrow={"initial"}>
                  {_r.document_name ? (
                    <Button
                      onClick={() => {
                        window.open(
                          `${config.baseURL}/CaC/download/${_r.document_name}`,
                          "_blank"
                        );
                      }}
                      colorScheme={"linkedin"}
                    >
                      Document
                    </Button>
                  ) : (
                    <Button colorScheme={"linkedin"}>Upload</Button>
                  )}
                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      colorScheme={link.colorScheme}
                      onClick={() => link.onClick(_r)}
                      _hover={{
                        color: "black",
                      }}
                    >
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  // Custom component for filtering in a table
  const FilterComponent = ({ filterText, onFilter, onClear, addUser }) => (
    <Box display={"flex"} gap={4}>
      {currentSelectedRows.length > 0 && (
        <Button
          width={"120px"}
          colorScheme={"red"}
          onClick={() => {
            handleMultipleDelete();
          }}
        >
          Delete ({currentSelectedRows.length})
        </Button>
      )}

      <Input
        id="search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        placeholder="Search"
      />
      <IconButton
        colorScheme={"linkedin"}
        onClick={onClear}
        icon={<CloseIcon />}
      />
    </Box>
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        fetchData(1);
      }
    };

    return (
      <Box
        width={"100%"}
        display={"flex"}
        gap={4}
        margin={"0"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}>
          Judgment and Case Laws
        </Text>
        <Box
          display={"flex"}
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <DeleteManyDialog
            title={"Judgment and Case Laws"}
            onDelete={() => handleMultipleDelete("all")}
          />
          <Box>
            <FilterComponent
              onFilter={(e) => {
                setFilterText(e.target.value);
                if (e.target.value && e.target.value.length > 2) {
                  handleSearch(e.target.value);
                } else if (!e.target.value) {
                  fetchData(1);
                }
                // get the input with id "search" and focus after 400 ms
                setTimeout(() => {
                  document.getElementById("search").focus();
                }, 200);
              }}
              onClear={handleClear}
              filterText={filterText}
            />
          </Box>

          <IconButton
            onClick={openCreateModel}
            m={4}
            colorScheme={"linkedin"}
            icon={<FaPlusCircle />}
          />
        </Box>
      </Box>
    );
  }, [filterText, resetPaginationToggle, currentSelectedRows]);

  return (
    <Layout>
      <Flex
        as={"main"}
        w={"100%"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Modal
          initialFocusRef={initialRef}
          isOpen={isOpen}
          size={"full"}
          onClose={() => {
            onClose();
            setCurrentOne({
              edit: false,
              data: {},
            });
            setEditorText("");
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader ml={10}>
              {!currentOne.edit ? "Add New Judgement and Case Law" : "Edit"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
              <Formik
                initialValues={{
                  name: currentOne.edit ? currentOne.data.name : "",
                  type: currentOne.edit ? currentOne.data.type : "",
                  court: currentOne.edit
                    ? currentOne.data.court.map((c) => ({
                        label: c.name,
                        value: c.name,
                      }))
                    : [],
                  description: currentOne.edit
                    ? currentOne.data.description
                    : "",
                  state: currentOne.edit
                    ? currentOne.data.state.map((c) => ({
                        label: c.name,
                        value: c.name,
                      }))
                    : [],
                  judgmentCategory: currentOne.edit
                    ? currentOne.data.judgmentCategory.map((c) => ({
                        label: c.name,
                        value: c.name,
                      }))
                    : [],
                  similar: currentOne.edit ? currentOne.data.similar : [],
                  country: currentOne.edit
                    ? currentOne.data.country.map((c) => ({
                        label: c.name,
                        value: c.name,
                      }))
                    : [],
                  tribunals: currentOne.edit ? currentOne.data.tribunals : "",
                  // year: currentOne.edit ? currentOne.data.year : "",
                  // month: currentOne.edit ? currentOne.data.month : "",
                  date: currentOne.edit
                    ? currentOne.data.year +
                      "-" +
                      config.commonMonths[currentOne.data.month]
                    : "",
                  seoName: currentOne.edit ? currentOne.data.seoName : "",
                  seoDescription: currentOne.edit
                    ? currentOne.data.seoDescription
                    : "",
                }}
                onSubmit={async (values, { resetForm }) => {
                  const state = values.state.map((s) => s.value);
                  const country = values.country.map((s) => s.value);
                  const court = values.court.map((s) => s.value);
                  const judgmentCategory = values.judgmentCategory.map(
                    (s) => s.value
                  );

                  const similar = values.similar.map((s) => s.value);

                  let formData = new FormData();

                  formData.set("name", values.name);
                  formData.set("type", values.type);
                  formData.set("description", values.description);
                  formData.set("state", state);
                  formData.set("judgmentCategory", judgmentCategory);
                  formData.set("similar", similar);
                  formData.set("country", country);
                  formData.set("court", court);
                  formData.set("tribunals", values.tribunals);
                  formData.set("year", +values.date.split("-")[0]);
                  formData.set("month", +values.date.split("-")[1]);
                  formData.set("document", selectedFile);
                  formData.set("doc", selectedFile2);
                  formData.set("seoName", values.seoName);
                  formData.set("seoDescription", values.seoDescription);
                  formData.set("docContent", editorText);

                  const url = currentOne.edit
                    ? "/judgementandcase/" + currentOne.data._id
                    : "/judgementandcase/create";

                  const _res = currentOne.edit
                    ? await axios.put(url, formData)
                    : await axios.post(url, formData);

                  if (_res.data.ok) {
                    toast({
                      description: _res.data.message,
                      status: "success",
                      position: "bottom-right",
                    });
                    // resetForm();
                    onClose();
                    fetch();
                    changeFile(null);
                    changeFile2(null);
                    setEditorText("");
                    setCurrentOne({
                      edit: false,
                      data: {},
                    });
                  } else {
                    toast({
                      description: _res.data.message,
                      status: "error",
                      position: "bottom-right",
                    });
                  }
                }}
                validationSchema={judgementandCaseSchema}
              >
                {({ errors, touched, values, setValues, setFieldValue }) => (
                  <Form>
                    <Flex
                      gap={16}
                      direction={"column"}
                      textAlign="center"
                      w={"100%"}
                    >
                      <Flex
                        direction={"row"}
                        justifyContent={"space-around"}
                        // alignItems={"center"}
                        gap={46}
                      >
                        <Stack spacing={4} width={"45%"}>
                          <FormControl
                            id="name"
                            isInvalid={touched.name && errors.name}
                            onChange={(e) => {
                              setFieldValue("seoName", e.target.value);
                            }}
                          >
                            <FormLabel>
                              Name of the Judgement and Case Law
                            </FormLabel>
                            <Field as={Input} type="text" name="name" />
                            {errors.name && (
                              <FormErrorMessage>{errors.name}</FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="type"
                            isInvalid={touched.type && errors.type}
                          >
                            <FormLabel>Type of the Law</FormLabel>
                            <Field
                              as={Select}
                              placeholder={"Select Type"}
                              name="type"
                              onChange={(e) => {
                                setFieldValue("type", e.target.value);
                                judgementandcaseByType(e.target.value);
                              }}
                            >
                              <option value="Case">Case</option>
                              <option value="Judgement">Judgement</option>
                            </Field>
                            {errors.type && (
                              <FormErrorMessage>{errors.type}</FormErrorMessage>
                            )}
                          </FormControl>
                          <FormControl
                            id="country"
                            isInvalid={touched.country && errors.country}
                            name={"country"}
                          >
                            <FormLabel>Country</FormLabel>
                            <Field
                              as={MultiSelect}
                              placeholder={"Select Country"}
                              name="country"
                              labelledBy={"Select Country"}
                              value={values.country}
                              onChange={async (e) => {
                                try {
                                  setValues({
                                    ...values,
                                    country: e,
                                    state: [],
                                    court: [],
                                  });

                                  const _tempCont = e
                                    .map((_) => _.value)
                                    .join(",");

                                  const res = await axios.get(
                                    `/states/${_tempCont}`
                                  );
                                  setSelectedStates(res.data.data);
                                } catch (err) {
                                  console.log(err);
                                }
                              }}
                              options={
                                !countriesLoading
                                  ? countries.data.map((e, i) => ({
                                      label: e.name,
                                      value: e.name,
                                    }))
                                  : []
                              }
                            ></Field>
                            {errors.country && (
                              <FormErrorMessage>
                                {errors.country}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="state"
                            isDisabled={values.country == ""}
                            isInvalid={touched.state && errors.state}
                          >
                            <FormLabel>States</FormLabel>
                            <Field
                              as={MultiSelect}
                              placeholder={"Select State"}
                              labelledBy={"Select State"}
                              name="state"
                              onChange={async (e) => {
                                setValues({
                                  ...values,
                                  state: e,
                                  court: [],
                                });
                                try {
                                  const _tempstate = e
                                    .map((e) => e.value)
                                    .join(",");
                                  const _tempCont = values.country.map
                                    ? values.country
                                        .map((e) => e.value)
                                        .join(",")
                                    : values.country;

                                  console.log(_tempstate, _tempCont);

                                  const res = await axios.get(
                                    `/courts/${_tempCont}/${_tempstate}`
                                  );
                                  setSelectedCourts(res.data.data);
                                } catch (err) {
                                  console.log(err);
                                }
                              }}
                              options={
                                seletedStates && seletedStates.length > 0
                                  ? seletedStates.map((e, i) => ({
                                      label: e.name,
                                      value: e.name,
                                    }))
                                  : []
                              }
                            ></Field>

                            {errors.country && (
                              <FormErrorMessage>
                                {errors.country}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                          <FormControl
                            id="court"
                            isDisabled={
                              values.country == "" || values.state == ""
                            }
                            isInvalid={touched.court && errors.court}
                          >
                            <FormLabel>Court</FormLabel>
                            <Field
                              as={MultiSelect}
                              placeholder={"Select Court"}
                              name="court"
                              labelledBy={"Select Court"}
                              selectedValues={
                                currentOne.edit ? values.court : []
                              }
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  court: e,
                                });
                              }}
                              options={
                                selectedCourts && selectedCourts.length > 0
                                  ? selectedCourts.map((e, i) => ({
                                      label: e.name,
                                      value: e.name,
                                    }))
                                  : []
                              }
                            ></Field>

                            {errors.country && (
                              <FormErrorMessage>
                                {errors.country}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                          <FormControl
                            id="judgmentCategory"
                            isDisabled={
                              values.country == "" || values.state == ""
                            }
                            isInvalid={
                              touched.judgmentCategory &&
                              errors.judgmentCategory
                            }
                          >
                            <FormLabel>Judgment Category</FormLabel>
                            <Field
                              as={MultiSelect}
                              placeholder={"Select judgment category"}
                              name="judgmentCategory"
                              selectedValues={
                                currentOne.edit ? values.judgmentCategory : []
                              }
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  judgmentCategory: e,
                                });
                              }}
                              options={
                                judgmentCategory &&
                                judgmentCategory.data.length > 0
                                  ? judgmentCategory.data.map((e, i) => ({
                                      label: e.name,
                                      value: e.name,
                                    }))
                                  : []
                              }
                            ></Field>

                            {errors.judgmentCategory && (
                              <FormErrorMessage>
                                {errors.judgmentCategory}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="similar"
                            isInvalid={touched.similar && errors.similar}
                          >
                            <FormLabel>Similar Judgement or Case</FormLabel>
                            <Field
                              as={MultiSelect}
                              placeholder={"Select Judgement or Case"}
                              name="similar"
                              value={values.similar}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  similar: e,
                                });
                              }}
                              options={
                                similarList &&
                                similarList.data &&
                                similarList.data.length > 0
                                  ? similarList.data.map((e, i) => ({
                                      label: e.name,
                                      value: e._id,
                                    }))
                                  : []
                              }
                            ></Field>

                            {errors.similar && (
                              <FormErrorMessage>
                                {errors.similar}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="tribunals"
                            isInvalid={touched.tribunals && errors.tribunals}
                          >
                            <FormLabel>Tribunals</FormLabel>
                            <Field as={Input} type="text" name="tribunals" />
                            {errors.tribunals && (
                              <FormErrorMessage>
                                {errors.tribunals}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Stack>

                        <Stack spacing={4} width={"45%"}>
                          <FormControl id="file">
                            <FormLabel>Document</FormLabel>
                            <Input
                              type={"file"}
                              // required={1}
                              accept={"application/pdf"}
                              onChange={(e) => {
                                changeFile(e.target.files[0]);
                                console.log(e.target.files);
                              }}
                            />
                          </FormControl>

                          <FormControl
                            id="date"
                            isInvalid={touched.date && errors.date}
                          >
                            <FormLabel>Date</FormLabel>
                            <Field as={Input} type="month" name="date" />
                            {errors.date && (
                              <FormErrorMessage>{errors.date}</FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="description"
                            name="description"
                            isInvalid={
                              touched.description && errors.description
                            }
                          >
                            <FormLabel>Description</FormLabel>
                            <Field
                              as={Textarea}
                              type="text"
                              name="description"
                              value={values.description}
                            />
                            {errors.description && (
                              <FormErrorMessage>
                                {errors.description}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="seoName"
                            isInvalid={touched.seoName && errors.seoName}
                          >
                            <FormLabel>Seo Title</FormLabel>
                            <Field as={Input} type="text" name="seoName" />
                            {errors.seoName && (
                              <FormErrorMessage>
                                {errors.seoName}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl id="file">
                            <FormLabel>Doc</FormLabel>
                            <Input
                              type={"file"}
                              accept={"application/pdf"}
                              onChange={(e) => {
                                changeFile2(e.target.files[0]);
                              }}
                            />
                          </FormControl>

                          <FormControl
                            id="seoDescription"
                            name="seoDescription"
                            isInvalid={
                              touched.seoDescription && errors.seoDescription
                            }
                          >
                            <FormLabel>Seo Description</FormLabel>
                            <Field
                              as={Textarea}
                              type="text"
                              name="seoDescription"
                              value={values.seoDescription}
                            />
                            {errors.seoDescription && (
                              <FormErrorMessage>
                                {errors.seoDescription}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Stack>

                        <Stack spacing={4} width={"60%"}>
                          <Editor
                            setEditorText={setEditorText}
                            editorText={editorText}
                          />
                        </Stack>
                      </Flex>
                      <Flex
                        spacing={10}
                        ml={`${54 - 10}%`}
                        width={"10%"}
                        gap={6}
                      >
                        <Button
                          colorScheme={"linkedin"}
                          type={"submit"}
                          color={"white"}
                          _hover={{
                            bg: "blue.500",
                          }}
                          mb={"8"}
                        >
                          {currentOne.edit ? "Update" : "Create"}
                        </Button>
                        <Button
                          colorScheme={"red"}
                          onClick={() => {
                            onClose();
                            setEditorText("");
                            setCurrentOne({
                              edit: false,
                              data: {},
                            });
                          }}
                          type={"submit"}
                          color={"white"}
                          _hover={{
                            bg: "red.600",
                          }}
                          mb={"8"}
                        >
                          Close
                        </Button>
                      </Flex>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* Display the data of model */}

        <Modal
          initialFocusRef={initialRef}
          isOpen={cacisOpen}
          onClose={() => {
            caconClose();
            setSelectedLawsandReg({});
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
              <Text as={"h3"}>Name</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedLawsandReg.name}
              </Text>
              <Text as={"h3"}>Type</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedLawsandReg.type}
              </Text>
              <Text as={"h3"}>Country</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedLawsandReg.country}
              </Text>
              <Text as={"h3"}>Description</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedLawsandReg.description}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme={"linkedin"} onClick={caconClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box
          rounded={"xl"}
          px={4}
          w={"100%"}
          m={6}
          backgroundColor={"#FFFFFF"}
          // m={10}
          border={"1px"}
          borderColor={"gray.200"}
        >
          <DataTable
            striped
            columns={columns}
            data={data.data}
            paginationRowsPerPageOptions={[10, 100, 500, 1000, 2000, 5000]}
            pagination
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            fixedHeader={true}
            paginationComponentOptions={{
              selectAllRowsItemText: "All",
            }}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            selectableRows
            selectedRows={currentSelectedRows}
            onSelectedRowsChange={handleRowSelected}
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
            clearSelectedRows={toggleCleared}
          />
        </Box>
      </Flex>
    </Layout>
  );
}
