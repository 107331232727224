import Layout from '../../components/layouts/base.jsx';
import { useState, useMemo, useEffect } from 'react';
import { Link as NextLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Link,
  useColorModeValue,
  useToast,
  Text,
  Button,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import { axiosInstance as axios } from '../../lib/axios.js';
import { useNavigate } from 'react-router';
import { RangePicker } from 'react-trip-date';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const customStyles = {
  rows: {
    style: {
      minHeight: '72px',
      width: '100%',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      color: 'black',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      width: '10vw',
      height: '7vh',
      color: 'black',
    },
  },
};

export default function ClickRateSoftware() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [calenderShow, setCalenderShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    from: null,
    to: null,
  });
  const dates = {
    from: selectedDate.from,
    to: selectedDate.to,
  };

  const fetchData = async (page) => {
    console.log(dates);
    if (!dates.from && !dates.to) {
      setLoading(true);
      const res = await axios.get(
        `/legalsoftware/paginate?page=${page}&limit=${perPage}`
      );
      setData(res.data);
      setTotalRows(res.data.total);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/legalsoftware/countDateRange?page=${page}&limit=${perPage}&from=${dates.from}&to=${dates.to}`
      );
      setData(res.data);
      setTotalRows(res.data.total);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const today = new Date().toISOString().split('T')[0];

  const handlePerRowsChange = async (newPerPage, page) => {
    if (!dates.from && !dates.to) {
      setLoading(true);
      const res = await axios.get(
        `/legalsoftware/paginate?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setTotalRows(res.data.total);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/legalsoftware/countDateRange?page=${page}&limit=${newPerPage}&from=${dates.from}&to=${dates.to}`
      );
      setData(res.data);
      setTotalRows(res.data.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  const toast = useToast();

  const columns = [
    {
      name: 'S/N',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Software Name',
      cell: (_r) => <Text>{_r.softwareName}</Text>,
      sortable: true,
    },
    {
      name: 'Download Rate (Today)',
      selector: (row, index) =>
        row.clickCountModelId &&
        row.clickCountModelId.clickRate.filter((item) => item.date === today)
          .length > 0
          ? row.clickCountModelId.clickRate.filter(
              (item) => item.date === today
            )[0].count
          : !row.clickCountModelId
          ? row.clickRate
          : 0,
      sortable: true,
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Box
        width={'100%'}
        display={'flex'}
        gap={4}
        margin={'0'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize="2xl" fontWeight={'extrabold'}>
          Manage Legal Software
        </Text>
        <Box display={'flex'} gap={4}>
          <Button onClick={() => setCalenderShow(!calenderShow)}>
            {selectedDate.from && selectedDate.to
              ? `${selectedDate.from} - ${selectedDate.to}`
              : 'Select Date'}
          </Button>
          <IconButton
            colorScheme={'linkedin'}
            icon={<AiOutlineCloseCircle />}
            onClick={async () => {
              setSelectedDate({ from: null, to: null });
              dates.from = null;
              dates.to = null;

              fetchData(1);
            }}
          />
        </Box>
      </Box>
    );
  }, [selectedDate.from, selectedDate.to, calenderShow, dates]);

  return (
    <Layout>
      <Box
        position={'absolute'}
        right={0}
        top={0}
        zIndex={7}
        bg={'white'}
        padding={4}
        shadow={'md'}
        display={calenderShow ? 'block' : 'none'}
      >
        <RangePicker
          numberOfMonths={1}
          startDatePlaceholder="Start Date"
          endDatePlaceholder="End Date"
          onChange={(e) => {
            if (e.from && e.to && calenderShow) {
              setSelectedDate(e);
              setCalenderShow(false);
              dates.from = e.from;
              dates.to = e.to;

              fetchData(1);
            }
          }}
          autoResponsive={true}
        />
      </Box>
      <Flex
        as={'main'}
        w={'100%'}
        bg={useColorModeValue('gray.50', 'gray.800')}
      >
        <Box
          rounded={'xl'}
          px={4}
          w={'100%'}
          m={6}
          backgroundColor={'#FFFFFF'}
          // m={10}
          border={'1px'}
          borderColor={'gray.200'}
        >
          <DataTable
            striped
            columns={columns}
            data={data.data}
            pagination
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
          />
        </Box>
      </Flex>
    </Layout>
  );
}

const NavItem = ({ icon, children, url, ...rest }) => {
  const router = useNavigate();
  return (
    <Link
      as={NextLink}
      href={url}
      color={'whatsapp.100'}
      style={{ textDecoration: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color={router.pathname === url ? 'whiteAlpha.900' : 'gray.400'}
        _hover={{
          bg: 'lightWhite',
          color: 'black',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'black',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};
