import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { Link as NavLink } from "react-router-dom";
import {
  MdOutlineAdminPanelSettings,
  MdOutlineAddToPhotos,
  MdMultipleStop,
} from "react-icons/md";
import { motion } from "framer-motion";
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FiHome,
  FiTrendingUp,
  FiLogOut,
  FiPenTool,
  FiClipboard,
  FiCompass,
  FiChevronDown,
  FiSettings,
  FiAirplay,
  FiActivity,
  FiCheckSquare,
  FiArchive,
} from "react-icons/fi";
import { BiCategoryAlt } from "react-icons/bi";
import {
  FaHandshake,
  FaFlagUsa,
  FaHandsHelping,
  FaCookieBite,
  FaSchool,
  FaIndustry,
} from "react-icons/fa";
import { BsFillPenFill, BsPeople } from "react-icons/bs";
import {
  MdPrivacyTip,
  MdOutlineNoteAdd,
  MdOutlinePolicy,
} from "react-icons/md";
import {
  AiTwotoneCrown,
  AiFillDiff,
  AiFillExclamationCircle,
  AiFillContacts,
  AiFillQuestionCircle,
  AiFillFileWord,
} from "react-icons/ai";
import { BiBookBookmark } from "react-icons/bi";
import { wipeOut } from "../../common/storage";
import { useGlobalContext } from "../../context/GlobalContext";

export default function Sidebar({ children }) {
  const { onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.900", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  const { setUser, setLoggedIn, setRole } = useGlobalContext();

  const [LinkItems, setLinkItems] = useState([
    { name: "User Management", icon: FiHome, url: "/admin/usermanagment" },
    { name: "Sectors", icon: FiTrendingUp, url: "/admin/sectors" },
    { name: "Contracts and Clauses", icon: FiPenTool, url: "/admin/cac" },
    {
      name: "Laws and Regulatories",
      icon: FiClipboard,
      url: "/admin/lawsandregulatory",
    },
    {
      name: "Judgment and Case Laws",
      icon: FaHandshake,
      url: "/admin/judgmentandcaselaws",
    },
    {
      name: "Countries",
      icon: FaFlagUsa,
      url: "/admin/countries",
    },
    {
      name: "States",
      icon: FiCompass,
      url: "/admin/states",
    },
    {
      name: "Districts",
      icon: FiCompass,
      url: "/admin/district",
    },
    {
      name: "Local",
      icon: FaHandsHelping,
      url: "/admin/local",
    },
    {
      name: "Central",
      icon: AiTwotoneCrown,
      url: "/admin/central",
    },
    {
      name: "Courts",
      icon: BsFillPenFill,
      url: "/admin/courts",
    },
    {
      name: "Judgement Category",
      icon: BiCategoryAlt,
      url: "/admin/judgementcategory",
    },
    {
      name: "Aggrement Type",
      icon: FaCookieBite,
      url: "/admin/aggrementtype",
    },
    {
      name: "Industries",
      icon: FaIndustry,
      url: "/admin/industrytype",
    },
    {
      name: "Gazzets",
      icon: AiFillFileWord,
      url: "/admin/gazzets",
    },
    {
      name: "Gazzet Types",
      icon: MdMultipleStop,
      url: "/admin/gazettestype",
    },
    {
      name: "Institutions",
      icon: FaSchool,
      url: "/admin/instituions",
    },
    {
      name: "Language",
      icon: AiTwotoneCrown,
      url: "/admin/language",
    },

    {
      name: "Plans",
      icon: AiFillDiff,
      url: "/admin/plans",
    },
    {
      name: "Members",
      icon: BsPeople,
      url: "/admin/members",
    },
    {
      name: "Important Links",
      icon: MdOutlineNoteAdd,
      url: "/admin/importantlinks",
    },
    {
      name: "News and Legal Updates",
      icon: FiArchive,
      isOpen:
        localStorage.getItem("News and Legal Updates") === "true"
          ? true
          : false,
      children: [
        {
          name: "Library and E-Books",
          icon: BiBookBookmark,
          url: "/admin/libraryandebooks",
        },
        {
          name: "Resources",
          icon: FiArchive,
          url: "/admin/resources",
        },
      ],
    },
    {
      name: "Software",
      icon: FiArchive,
      isOpen: localStorage.getItem("Software") === "true" ? true : false,
      children: [
        {
          name: "Legal Software",
          icon: FiAirplay,
          url: "/admin/legalsoftware",
        },
        { name: "Click Rate", icon: FiActivity, url: "/admin/clickrate" },
      ],
    },
    {
      name: "Options",
      icon: FiSettings,
      isOpen: localStorage.getItem("Options") === "true" ? true : false,
      children: [
        {
          name: "About",
          icon: AiFillExclamationCircle,
          url: "/admin/aboutsec",
        },
        { name: "Contact Us", icon: AiFillContacts, url: "/admin/contactsec" },
        {
          name: "Faq Manager",
          icon: AiFillQuestionCircle,
          url: "/admin/faq",
        },
        {
          name: "Terms and Conditions",
          icon: FaHandsHelping,
          url: "/admin/terms",
        },
        {
          name: "Privacy Policy",
          icon: MdPrivacyTip,
          url: "/admin/privacy",
        },
        {
          name: "Cookies Policy",
          icon: FaCookieBite,
          url: "/admin/cookies",
        },
        {
          name: "Member's Policy",
          icon: MdOutlinePolicy,
          url: "/admin/memberspolicy",
        },
      ],
    },
    {
      name: "Gallery",
      icon: MdOutlineAddToPhotos,
      url: "/admin/gallery",
    },
    {
      name: "Drafting Notes",
      icon: MdOutlineNoteAdd,
      url: "/admin/drafting",
    },
    {
      name: "Check List",
      icon: FiCheckSquare,
      url: "/admin/checklist",
    },
  ]);

  function Logout() {
    setUser(null);
    setLoggedIn(false);
    setRole("");
    wipeOut();
  }

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("gray.900", "gray.900")}
      overflowY="scroll"
      display={{ base: "none", md: "block" }}
      borderRight="1px"
      color={useColorModeValue("white", "gray.100")}
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 80 }}
      h="100vh"
      {...rest}
    >
      <Flex h="24" gap={4} alignItems="center" justifyContent="center">
        <Icon
          fontSize={"3xl"}
          as={MdOutlineAdminPanelSettings}
          color={useColorModeValue("white", "white")}
        />
        <Text
          fontSize="2xl"
          fontFamily={"heading"}
          fontWeight="bold"
          letterSpacing={"1.5px"}
        >
          Dashboard
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Box
        h={"2px"}
        w={"95%"}
        margin={"auto"}
        backgroundColor={"darkGray"}
        borderRadius={"2xl"}
        marginBottom={"1rem"}
      />
      {LinkItems.map((link, i) => (
        <Box key={i}>
          <Box
            onClick={() => {
              if (link.children) {
                if (link.isOpen) {
                  localStorage.removeItem(link.name);
                }
                setLinkItems(
                  LinkItems.map((item) => {
                    if (item.name === link.name) {
                      item.isOpen = !item.isOpen;
                      localStorage.setItem(link.name, item.isOpen);
                    } else {
                      item.isOpen = false;
                    }
                    return item;
                  })
                );
              }
            }}
          >
            <NavItem
              key={i}
              icon={link.icon}
              url={link.url ? link.url : "#"}
              submenu={link.children ? true : false}
              show={link.isOpen}
            >
              {link.name}
            </NavItem>
          </Box>
          <Box
            as={motion.div}
            animate={{
              opacity: link.isOpen ? 1 : 0,
              display: link.isOpen ? "block" : "none",
            }}
            exit={{ y: -20, height: 0 }}
          >
            {link.children &&
              link.children.map((child, i) => (
                <Box paddingLeft={"15px"} key={i}>
                  <NavItem
                    key={i}
                    icon={child.icon}
                    url={child.url ? child.url : "#"}
                  >
                    {"  " + child.name}
                  </NavItem>
                </Box>
              ))}
          </Box>
        </Box>
      ))}
      <Box
        h={"2px"}
        w={"95%"}
        margin={"auto"}
        backgroundColor={"darkGray"}
        borderRadius={"2xl"}
        marginTop={"1rem"}
      />
      <Box onClick={() => Logout()}>
        <NavItem key="Logout" icon={FiLogOut} url="/">
          Logout
        </NavItem>
      </Box>
    </Box>
  );
};

const NavItem = ({ icon, children, url, submenu, show, ...rest }) => {
  const location = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    if (location.pathname === url && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.pathname, url]);

  return (
    <Link
      as={NavLink}
      to={url}
      color={"whatsapp.100"}
      style={{ textDecoration: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color={location.pathname === url ? "white" : "gray.400"}
        fontWeight={location.pathname === url ? "bold" : "normal"}
        _hover={{
          bg: "lightWhite",
          color: "black",
        }}
        ref={ref}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "black",
            }}
            as={icon}
          />
        )}
        {children}
        {submenu && (
          <Icon
            ml="auto"
            transition="all 0.2s"
            transform={show ? "rotate(0deg)" : "rotate(-90deg)"}
            w={4}
            h={4}
            _groupHover={{
              color: "black",
            }}
            as={FiChevronDown}
          />
        )}
      </Flex>
    </Link>
  );
};

// const MobileNav = ({ onOpen, ...rest }) => {
//   return (
//     <Flex
//       ml={{ base: 0, md: 60 }}
//       px={{ base: 4, md: 4 }}
//       height="20"
//       alignItems="center"
//       bg={useColorModeValue("white", "gray.900")}
//       borderBottomWidth="1px"
//       borderBottomColor={useColorModeValue("gray.200", "gray.700")}
//       justifyContent={{ base: "space-between", md: "flex-end" }}
//       {...rest}
//     >
//       <IconButton
//         display={{ base: "flex", md: "none" }}
//         onClick={onOpen}
//         variant="outline"
//         aria-label="open menu"
//         icon={<FiMenu />}
//       />

//       <Text
//         display={{ base: "flex", md: "none" }}
//         fontSize="2xl"
//         fontFamily="monospace"
//         fontWeight="bold"
//       >
//         Logo
//       </Text>

//       <HStack spacing={{ base: "0", md: "6" }}>
//         <IconButton
//           size="lg"
//           variant="ghost"
//           aria-label="open menu"
//           icon={<FiBell />}
//         />
//         <Flex alignItems={"center"}>
//           <Menu>
//             <MenuButton
//               py={2}
//               transition="all 0.3s"
//               _focus={{ boxShadow: "none" }}
//             >
//               <HStack>
//                 <Avatar
//                   size={"sm"}
//                   src={
//                     "https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
//                   }
//                 />
//                 <VStack
//                   display={{ base: "none", md: "flex" }}
//                   alignItems="flex-start"
//                   spacing="1px"
//                   ml="2"
//                 >
//                   <Text fontSize="sm">Root</Text>
//                   <Text fontSize="xs" color="gray.600">
//                     Admin
//                   </Text>
//                 </VStack>
//                 <Box display={{ base: "none", md: "flex" }}>
//                   <FiChevronDown />
//                 </Box>
//               </HStack>
//             </MenuButton>
//             <MenuList
//               bg={useColorModeValue("white", "gray.900")}
//               borderColor={useColorModeValue("gray.200", "gray.700")}
//             >
//               <MenuItem>Profile</MenuItem>
//               <MenuItem>Settings</MenuItem>
//               <MenuItem>Billing</MenuItem>
//               <MenuDivider />
//               <MenuItem>Sign out</MenuItem>
//             </MenuList>
//           </Menu>
//         </Flex>
//       </HStack>
//     </Flex>
//   );
// };
