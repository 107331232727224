import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const styles = {
  global: (props) => ({
    body: {
      bg: mode("#FDFEFF", "#D3C4D1")(props),
      color: "#000000",
      fontWeight: "bold",
    },
  }),
};

const components = {
  Heading: {
    variants: {
      "section-title": {
        textDecoration: "underline",
        fontSize: 20,
        textUnderlineOffset: 6,
        textDecorationColor: "#525252",
        textDecorationThickness: 4,
        marginTop: 3,
        marginBottom: 4,
      },
    },
  },
  Box: {
    baseStyle: (props) => ({
      backgroundColor: mode("#FDFEFF", "#000000")(props),
    }),
  },

  Link: {
    baseStyle: (props) => ({
      color: mode("#3d7aed", "#ff63c3")(props),
      textUnderlineOffset: 3,
    }),
  },
};

const fonts = {
  base: "'PT Sans', sans-serif",
  heading: "'PT Sans', serif",
};

const colors = {
  grassTeal: "#88ccca",
  darkGray: "#ffffff25",
  lightWhite: "#f5f5f5",
  darkWhite: "#ececec",
  niceVoilet: "#5F30E2",
};

const config = {
  initialColorMode: "light",
  useSystemColorMode: true,
};

const theme = extendTheme({ config, styles, components, fonts, colors });
export default theme;
