import Layout from '../../components/layouts/base.jsx';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  IconButton,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  ModalCloseButton,
  Button,
  Select,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Badge,
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import useFetch from '../../hooks/useFetch.jsx';
import { axiosInstance as axios } from '../../lib/axios.js';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import DeleteManyDialog from '../../components/admin/DeleteAll.jsx';
import { Dropdown } from 'primereact/dropdown';

const CategorieSchema = Yup.object().shape({
  name: Yup.string().required('Name must Required'),
  country: Yup.string().required('Central must have a country'),
  seoName: Yup.string(),
  seoDescription: Yup.string(),
});

const customStyles = {
  rows: {
    style: {
      minHeight: '72px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      height: '7vh',
    },
  },
};

export default function Central() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [refetch, setrefetch] = useState(false);
  const [activeState, setactiveState] = useState({});

  const [currentSelectedRows, setcurrentSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/central/pagination/get?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handleSearch = async (e, page = 1) => {
    setLoading(true);
    const res = await axios.get(
      `/central/search/${e}?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    if (!filterText) {
      fetchData(page);
      return;
    }
    handleSearch(filterText, page);
  };

  const handleRowSelected = useCallback((state) => {
    setcurrentSelectedRows(state.selectedRows);
  }, []);

  const handleMultipleDelete = async (s) => {
    let ids;
    if (Array.isArray(s)) {
      ids = s.map((row) => row._id).join(',');
    } else if (s === 'all') {
      ids = 'all';
    }
    const _res = await axios.delete(`/central/deleteall/${ids}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
    setToggleCleared(!toggleCleared);
    setFilterText('');
    setcurrentSelectedRows([]);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (!filterText) {
      setLoading(true);
      const res = await axios.get(
        `/central/pagination/get?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/central/search/${filterText}?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  const {
    data: _countrydata,
    loading: _countryloading,
    error: _countryerror,
  } = useFetch('countries/', {}, refetch);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);

  const toast = useToast();

  const fetch = () => setrefetch(!refetch);

  const deleteCentral = async (_) => {
    const _res = await axios.delete(`/central/${_._id}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
  };

  const _openeditModel = (_data) => {
    onOpen();
    setactiveState(_data);
  };

  // a custom component act like search bar

  const FilterComponent = ({ filterText, onFilter, onClear, addUser }) => (
    <Box display={'flex'} gap={4} p={2}>
      {currentSelectedRows.length > 0 && (
        <Button
          width={'120px'}
          colorScheme={'red'}
          onClick={() => {
            handleMultipleDelete(currentSelectedRows);
          }}
        >
          Delete ({currentSelectedRows.length})
        </Button>
      )}

      <Input
        id="search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        placeholder="Search"
        width={'300px'}
      />
      <IconButton
        colorScheme={'linkedin'}
        onClick={onClear}
        icon={<CloseIcon />}
      />
    </Box>
  );

  const columns = [
    {
      name: 'S/N',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Country',
      selector: (row, i, column) =>
        row && row.country ? row.country.name : '',
      sortable: true,
    },
    {
      name: 'Delete',
      cell: (_r) => (
        <Button colorScheme={'red'} onClick={() => deleteCentral(_r)}>
          Delete
        </Button>
      ),
    },
    {
      name: 'Edit',
      cell: (_r) => (
        <Button colorScheme={'blue'} onClick={() => _openeditModel(_r)}>
          Edit
        </Button>
      ),
    },
  ];

  const BulkUpload = async (file) => {
    if (!file)
      return toast({
        description: 'Please Select a file',
        status: 'error',
        position: 'bottom-right',
      });

    const formData = new FormData();
    formData.append('file', file);
    const res = await axios.post('/central/bulk', formData);

    if (res.data.ok) {
      toast({
        description: res.data.message,
        status: 'success',
        position: 'bottom-right',
        isClosable: true,
      });
      fetch();
    } else {
      toast({
        description: res.data.message,
        status: 'error',
        position: 'bottom-right',
        isClosable: true,
      });
    }

    document.getElementById('file').value = '';
  };

  let filteredItems =
    data && data.data
      ? data?.data?.filter((item) =>
          item.name.toLowerCase().includes(filterText.toLowerCase())
        )
      : [];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        fetchData(1);
      }
    };

    return (
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        margin={'0'}
      >
        <Text fontSize="2xl" fontWeight={'extrabold'}></Text>
        <DeleteManyDialog
          title={'Central'}
          onDelete={() => handleMultipleDelete('all')}
        />
        <FilterComponent
          onFilter={(e) => {
            setFilterText(e.target.value);
            if (e.target.value && e.target.value.length > 2) {
              handleSearch(e.target.value);
            } else if (!e.target.value) {
              fetchData(1);
            }
            // get the input with id "search" and focus after 400 ms
            setTimeout(() => {
              document.getElementById('search').focus();
            }, 200);
          }}
          onClear={handleClear}
          filterText={filterText}
        />
      </Box>
    );
  }, [filterText, resetPaginationToggle, currentSelectedRows]);

  return (
    <>
      <input
        type={'file'}
        id={'file'}
        accept={
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        }
        onChange={(e) => {
          BulkUpload(e.target.files[0]);
        }}
        style={{ display: 'none' }}
      />
      <Layout>
        <Flex
          as={'main'}
          w={'97%'}
          bg={useColorModeValue('gray.50', 'gray.800')}
        >
          <Box
            bg={useColorModeValue('white', 'gray.700')}
            px={6}
            m={6}
            rounded={'xl'}
            w={'30%'}
          >
            <Text as={'h1'} fontSize={'2xl'} pb={'4'}>
              Managing Central
            </Text>
            <Formik
              initialValues={{
                name: '',
                country: '',
                seoName: '',
                seoDescription: '',
              }}
              onSubmit={async (
                { name, country, seoName, seoDescription },
                { resetForm }
              ) => {
                const res = await axios.post('/central/create', {
                  name,
                  countryId: country,
                  seoName,
                  seoDescription,
                });

                if (res.data.ok) {
                  toast({
                    description: res.data.message,
                    status: 'success',
                    position: 'bottom-right',
                  });
                  resetForm();
                  fetch();
                } else {
                  toast({
                    description: res.data.message,
                    status: 'error',
                    position: 'bottom-right',
                  });
                }
              }}
              validationSchema={CategorieSchema}
            >
              {({ errors, touched, resetForm, values, setFieldValue }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl
                      id="name"
                      isInvalid={errors.name}
                      onChange={(e) => {
                        setFieldValue('seoName', e.target.value);
                      }}
                    >
                      <FormLabel>Name</FormLabel>
                      <Field as={Input} type="text" name="name" />
                      {touched.name && errors.name && (
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="country"
                      isInvalid={touched.country && errors.country}
                    >
                      <FormLabel>Countries</FormLabel>
                      <Dropdown
                        filter
                        inputId="country"
                        name="country"
                        style={{ width: '100%' }}
                        value={values.country}
                        options={
                          !_countryloading && _countrydata?.data?.length > 0
                            ? _countrydata.data.map((id) => ({
                                name: id.name,
                                value: id._id,
                              }))
                            : []
                        }
                        optionLabel="name"
                        placeholder="Select Country"
                        onChange={(e) => {
                          setFieldValue('country', e.value);
                        }}
                      ></Dropdown>
                      {errors.country && (
                        <FormErrorMessage>{errors.country}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="seoName"
                      isInvalid={touched.seoName && errors.seoName}
                    >
                      <FormLabel>Seo Title</FormLabel>
                      <Field as={Input} type="text" name="seoName" />
                      {errors.seoName && (
                        <FormErrorMessage>{errors.seoName}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="seoDescription"
                      name="seoDescription"
                      isInvalid={
                        touched.seoDescription && errors.seoDescription
                      }
                    >
                      <FormLabel>Seo Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="seoDescription"
                        value={values.seoDescription}
                      />
                      {errors.seoDescription && (
                        <FormErrorMessage>
                          {errors.seoDescription}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <Button
                    bg={'blue.400'}
                    mt={4}
                    type={'submit'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                  >
                    Create
                  </Button>
                  <Button
                    bg={'blue.400'}
                    mt={4}
                    mx={4}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    onClick={() => {
                      document.getElementById('file').value = '';
                      document.getElementById('file').click();
                    }}
                  >
                    Bulk Upload
                  </Button>

                  <Badge my={3} variant="subtle" colorScheme="green">
                    <a
                      href="/assets/sample/centrallist.xlsx"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sample Excel File
                    </a>
                  </Badge>
                </Form>
              )}
            </Formik>
          </Box>
          <Box
            rounded={'xl'}
            px={4}
            w={'70%'}
            m={6}
            backgroundColor={'#FFFFFF'}
            border={'1px'}
            borderColor={'gray.200'}
          >
            <DataTable
              striped
              columns={columns}
              data={data.data}
              paginationRowsPerPageOptions={[10, 100, 500, 1000, 2000, 5000]}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              paginationServer
              progressPending={loading}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeader
              selectableRows
              selectedRows={currentSelectedRows}
              onSelectedRowsChange={handleRowSelected}
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              customStyles={customStyles}
              clearSelectedRows={toggleCleared}
            />
          </Box>
        </Flex>
      </Layout>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setactiveState({});
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Central</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={'flex'} flexDirection={'column'}>
            <Formik
              initialValues={{
                _name: activeState.name,
                _country:
                  activeState && activeState.country
                    ? activeState.country._id
                    : '',
              }}
              onSubmit={async ({ _name, _country }, { resetForm }) => {
                const res = await axios.put(`/central/${activeState._id}`, {
                  name: _name,
                  countryId: _country,
                });

                if (res.data.ok) {
                  toast({
                    description: res.data.message,
                    status: 'success',
                    position: 'bottom-right',
                  });
                  resetForm();
                  onClose();
                  fetch();
                } else {
                  toast({
                    description: res.data.message,
                    status: 'error',
                    position: 'bottom-right',
                  });
                }
              }}
            >
              {({ errors: _e, touched: _t, resetForm }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl id="_name" isInvalid={_e._name}>
                      <FormLabel>Name</FormLabel>
                      <Field as={Input} type="text" name="_name" />
                      {_t._name && _e._name && (
                        <FormErrorMessage>{_e._name}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="_country"
                      isInvalid={_t._country && _e._country}
                    >
                      <FormLabel>Country</FormLabel>
                      <Field
                        as={Select}
                        placeholder={'Select Country'}
                        name="_country"
                      >
                        {!_countryloading &&
                          !_countryerror &&
                          _countrydata.data.map((e, i) => {
                            return (
                              <option key={i} value={e._id}>
                                {e.name}
                              </option>
                            );
                          })}
                      </Field>
                      {_e._country && (
                        <FormErrorMessage>{_e._country}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <ModalFooter>
                    <Button type="submit" colorScheme="blue" mr={3}>
                      Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
