import Layout from '../../components/layouts/base.jsx';
import useFetch from '../../hooks/useFetch.jsx';
import { useState, useMemo, useRef, useEffect } from 'react';
import {
  Box,
  Flex,
  Link,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Icon,
  IconButton,
  Textarea,
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import { axiosInstance as axios } from '../../lib/axios.js';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { MultiSelect } from 'react-multi-select-component';

import { FaEllipsisV, FaEdit, FaTrash, FaPlusCircle } from 'react-icons/fa';
import { config } from '../../common/config.js';

const customStyles = {
  rows: {
    style: {
      minHeight: '72px',
      width: '100%',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      color: 'black',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      width: '10vw',
      height: '7vh',
      color: 'black',
    },
  },
};

const AddFaqSchema = Yup.object().shape({
  question: Yup.string().required('Question is required'),
  answer: Yup.string().required('Answer is required'),
});

export default function FaqManager() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [editorText, setEditorText] = useState('');

  const [refetch, setrefetch] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(`/faqs/paginate?page=${page}&limit=${perPage}`);
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const res = await axios.get(
      `/faqs/paginate?page=${page}&limit=${newPerPage}`
    );
    setData(res.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  const [currentOne, setCurrentOne] = useState({
    edit: false,
    data: {},
  });
  const [selectedFaq, setSelectedFaq] = useState({});

  const fetch = () => setrefetch(!refetch);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: cacisOpen,
    onOpen: caconOpen,
    onClose: caconClose,
  } = useDisclosure();

  const toast = useToast();
  const initialRef = useRef(null);

  const openCreateModel = () => {
    setCurrentOne({
      edit: false,
      data: {},
    });
    onOpen();
  };
  const deleteFaq = async (_r) => {
    const _res = await axios.delete('/faqs/' + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
    fetch();
  };

  const editFaq = (_r) => {
    setCurrentOne({
      edit: true,
      data: _r,
    });
    onOpen();
    setEditorText(_r.docContent ? _r.docContent : '');
  };

  const Actions = [
    {
      name: 'Edit',
      icon: FaEdit,
      onClick: editFaq,
      colorSchema: 'linkedin',
    },

    {
      name: 'Delete',
      icon: FaTrash,
      onClick: deleteFaq,
      colorSchema: 'red',
    },
  ];

  const _helpText = {
    clause: 'Clause',
    contract: 'Contract',
  };

  const columns = [
    {
      name: 'S/N',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Question',
      cell: (_r) => (
        <Text
          onClick={() => {
            caconOpen();
            setSelectedFaq(_r);
          }}
          _hover={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          color={'#00A0DC'}
        >
          {_r.question}
        </Text>
      ),
      sortable: true,
    },
    {
      name: 'Answer',
      selector: (row, i, column) => row.answer,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (_r) => (
        <Box position={'fixed'} zIndex={10}>
          <Popover closeOnBlur={true}>
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={6}>
              <PopoverHeader fontWeight="semibold" textAlign={'center'}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={'column'}>
                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      onClick={() => link.onClick(_r)}
                      colorScheme={link.colorSchema}
                      _hover={{
                        color: 'black',
                      }}
                    >
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Box
        width={'100%'}
        display={'flex'}
        gap={4}
        margin={'0'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize="2xl" fontWeight={'extrabold'}>
          Faq Manager
        </Text>
        <Box
          display={'flex'}
          gap={2}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <IconButton
            onClick={openCreateModel}
            m={4}
            colorScheme={'linkedin'}
            icon={<FaPlusCircle />}
          />
        </Box>
      </Box>
    );
  }, []);

  return (
    <Layout>
      <Flex
        as={'main'}
        w={'100%'}
        bg={useColorModeValue('gray.50', 'gray.800')}
      >
        <Modal
          initialFocusRef={initialRef}
          size={'3xl'}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setCurrentOne({
              edit: false,
              data: {},
            });
            setEditorText('');
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {!currentOne.edit ? 'Add New Faq' : 'Edit'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-evenly'}
              gap={10}
            >
              <Box w={'60%'}>
                <Formik
                  initialValues={{
                    question: currentOne.data.question || '',
                    answer: currentOne.data.answer || '',
                    category: '_',
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    const url = currentOne.edit
                      ? '/faqs/' + currentOne.data._id
                      : '/faqs/create';

                    const _res = currentOne.edit
                      ? await axios.put(url, {
                          ...values,
                        })
                      : await axios.post(url, {
                          ...values,
                        });
                    if (_res.data.ok) {
                      toast({
                        description: _res.data.message,
                        status: 'success',
                        position: 'bottom-right',
                      });
                      resetForm();
                      setEditorText('');
                      onClose();
                      fetch();
                      setCurrentOne({
                        edit: false,
                        data: {},
                      });
                    } else {
                      toast({
                        description: _res.data.message,
                        status: 'error',
                        position: 'bottom-right',
                      });
                    }
                  }}
                  validationSchema={AddFaqSchema}
                >
                  {({ errors, touched, values, setValues, setFieldValue }) => (
                    <Form>
                      <Stack spacing={4}>
                        <FormControl
                          id="question"
                          isInvalid={touched.question && errors.question}
                        >
                          <FormLabel>Question</FormLabel>
                          <Field as={Input} type="text" name="question" />
                          {errors.question && (
                            <FormErrorMessage>
                              {errors.question}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="answer"
                          name="answer"
                          isInvalid={touched.answer && errors.answer}
                        >
                          <FormLabel>Answer</FormLabel>
                          <Field
                            as={Textarea}
                            type="text"
                            name="answer"
                            value={values.answer}
                          />
                          {errors.answer && (
                            <FormErrorMessage>{errors.answer}</FormErrorMessage>
                          )}
                        </FormControl>

                        <Stack spacing={10}>
                          <Button
                            colorScheme={'linkedin'}
                            type={'submit'}
                            color={'white'}
                            _hover={{
                              bg: 'blue.500',
                            }}
                            mb={'8'}
                          >
                            {currentOne.edit ? 'Update' : 'Create'}
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </ModalBody>

            {/* <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={() => addAccess()}>
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>

        {/* Display the data of model */}

        <Modal
          initialFocusRef={initialRef}
          isOpen={cacisOpen}
          onClose={() => {
            caconClose();
            setSelectedFaq({});
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody pb={6} display={'flex'} flexDirection={'column'}>
              <Text as={'h3'}>Question</Text>
              <Text
                fontWeight={'normal'}
                style={{
                  textIndent: '30px',
                }}
              >
                {selectedFaq.question}
              </Text>

              <Text as={'h3'}>Answer</Text>
              <Text
                fontWeight={'normal'}
                style={{
                  textIndent: '30px',
                }}
              >
                {selectedFaq.answer}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme={'linkedin'} onClick={caconClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box
          rounded={'xl'}
          px={4}
          w={'100%'}
          m={6}
          backgroundColor={'#FFFFFF'}
          // m={10}
          border={'1px'}
          borderColor={'gray.200'}
        >
          <DataTable
            striped
            columns={columns}
            data={data.data}
            pagination
            // paginationResetDefaultPage={resetPaginationToggle}
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
          />
        </Box>
      </Flex>
    </Layout>
  );
}

export async function getServerSideProps({ req }) {
  return {
    props: {},
  };
}
