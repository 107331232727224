import Layout from "../../components/layouts/base.jsx";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  Textarea,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  IconButton,
  Text,
  ModalCloseButton,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { axiosInstance as axios } from "../../lib/axios.js";
import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { FaEdit, FaTrash, FaEllipsisV } from "react-icons/fa";
import { config } from "../../common/config";
import { CloseIcon } from "@chakra-ui/icons";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      width: "100%",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "11vw",
    },
  },
};

export default function DraftingNotes() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [refetch, setrefetch] = useState(false);
  const [activeCourt, setActiveBook] = useState({});
  const [file, setFile] = useState(null);
  const [pdffile, setPdffile] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentSelectedRows, setcurrentSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/drafting/pagination?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const viewFile = (key) => {
    window.open(`${config.baseURL}/CaC/download/${key}`, "_blank");
  };

  const handleSearch = async (e, page = 1) => {
    setLoading(true);
    const res = await axios.get(
      `/drafting/search/${e}?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    if (!filterText) {
      fetchData(page);
      return;
    }
    handleSearch(filterText, page);
  };

  const handleRowSelected = useCallback((state) => {
    setcurrentSelectedRows(state.selectedRows);
  }, []);

  const handleMultipleDelete = async (s) => {
    const ids = s.map((row) => row._id);
    const _res = await axios.delete(`/drafting/deleteall/${ids.join(",")}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    setToggleCleared(!toggleCleared);
    setFilterText("");
    setcurrentSelectedRows([]);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (!filterText) {
      setLoading(true);
      const res = await axios.get(
        `/drafting/pagination?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/drafting/search/${filterText}?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  // const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const initialRef = useRef(null);
  const popoverRef = useRef(null);

  const toast = useToast();

  const fetch = () => setrefetch(!refetch);

  const deleteGallery = async (_) => {
    const _res = await axios.delete(`/drafting/${_._id}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const _openeditModel = async (_data) => {
    onOpen();
    setFile(null);
    setActiveBook(_data);
  };

  const closeModel = () => {
    onClose();
    setActiveBook({});
  };

  const FilterComponent = ({ filterText, onFilter, onClear, addUser }) => (
    <Box display={"flex"} gap={4} p={2}>
      {currentSelectedRows.length > 0 && (
        <Button
          width={"120px"}
          colorScheme={"red"}
          onClick={() => {
            handleMultipleDelete(currentSelectedRows);
          }}
        >
          Delete ({currentSelectedRows.length})
        </Button>
      )}

      <Input
        id="search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        placeholder="Search"
        width={"300px"}
      />
      <IconButton
        colorScheme={"linkedin"}
        onClick={onClear}
        icon={<CloseIcon />}
      />
    </Box>
  );

  const Actions = [
    {
      name: "Edit",
      icon: FaEdit,
      onClick: (_r) => _openeditModel(_r),
      colorSchema: "linkedin",
    },

    {
      name: "Delete",
      icon: FaTrash,
      onClick: (_r) => deleteGallery(_r),
      colorSchema: "red",
    },
  ];

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "View",
      cell: (row) => (
        <Button
          bg={"blue.400"}
          color={"white"}
          _hover={{
            bg: "blue.500",
          }}
          onClick={() => viewFile(row.draftingNote)}
        >
          {" "}
          View
        </Button>
      ),
    },

    {
      name: "Actions",
      cell: (_r) => (
        <Box position={"fixed"} zIndex={10} itemRef={popoverRef}>
          <Popover closeOnBlur={true}>
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={6}>
              <PopoverHeader fontWeight="semibold" textAlign={"center"}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={"column"} spacing={"4"}>
                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      onClick={() => link.onClick(_r)}
                      colorScheme={link.colorSchema}
                      display={"flex"}
                      gap={"4"}
                      _hover={{
                        color: "white",
                      }}
                    >
                      {<link.icon />}
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  const onSubmit = async ({ name, seoName, seoDescription }, { resetForm }) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("seoName", seoName);
    formData.append("seoDescription", seoDescription);
    formData.append("draftingNote", file);

    const res = await axios.post("/drafting/add", formData);

    if (res.data.ok) {
      resetForm();
      document.getElementById("file").value = "";
      setFile(null);
      toast({
        description: "Drafting Note Added Successfully",
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        fetchData(1);
      }
    };

    return (
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        margin={"0"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}></Text>
        <FilterComponent
          onFilter={(e) => {
            setFilterText(e.target.value);
            if (e.target.value && e.target.value.length > 2) {
              handleSearch(e.target.value);
            } else if (!e.target.value) {
              fetchData(1);
            }
            // get the input with id "search" and focus after 400 ms
            setTimeout(() => {
              document.getElementById("search").focus();
            }, 200);
          }}
          onClear={handleClear}
          filterText={filterText}
        />
      </Box>
    );
  }, [filterText, resetPaginationToggle, currentSelectedRows]);

  return (
    <>
      <Layout>
        <Flex
          as={"main"}
          w={"100%"}
          bg={useColorModeValue("gray.50", "gray.800")}
        >
          <Box
            bg={useColorModeValue("white", "gray.700")}
            px={6}
            m={6}
            rounded={"xl"}
            w={"30%"}
          >
            <Text as={"h1"} fontSize={"2xl"} pb={"4"}>
              Managing Drafting Notes
            </Text>
            <Formik
              initialValues={{
                name: "",
                seoName: "",
                seoDescription: "",
              }}
              onSubmit={onSubmit}
            >
              {({
                errors,
                touched,
                resetForm,
                values,
                setValues,
                setFieldValue,
              }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl
                      id="name"
                      isInvalid={errors.name}
                      onChange={(e) => {
                        setFieldValue("seoName", e.target.value);
                      }}
                    >
                      <FormLabel>Name</FormLabel>
                      <Field
                        as={Input}
                        type="text"
                        name="name"
                        placeholder={"Enter name of the Image"}
                      />
                      {touched.name && errors.name && (
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl id="file">
                      <FormLabel>Drafting Note</FormLabel>
                      <Input
                        required
                        type={"file"}
                        accept={"image/*"}
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                    </FormControl>

                    <FormControl
                      id="seoName"
                      isInvalid={touched.seoName && errors.seoName}
                    >
                      <FormLabel>Seo Title</FormLabel>
                      <Field as={Input} type="text" name="seoName" />
                      {errors.seoName && (
                        <FormErrorMessage>{errors.seoName}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="seoDescription"
                      name="seoDescription"
                      isInvalid={
                        touched.seoDescription && errors.seoDescription
                      }
                    >
                      <FormLabel>Seo Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="seoDescription"
                        value={values.seoDescription}
                      />
                      {errors.seoDescription && (
                        <FormErrorMessage>
                          {errors.seoDescription}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <Box display={"flex"} gap={4} justifyContent={"flex-end"}>
                    <Button
                      bg={"blue.400"}
                      mt={4}
                      type={"submit"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                    >
                      Create
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          <Box
            rounded={"xl"}
            px={4}
            w={"70%"}
            m={6}
            backgroundColor={"#FFFFFF"}
            // m={10}
            border={"1px"}
            borderColor={"gray.200"}
          >
            <DataTable
              striped
              columns={columns}
              data={data.data}
              paginationRowsPerPageOptions={[10, 100, 500, 1000, 2000, 5000]}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              paginationServer
              progressPending={loading}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeader
              selectableRows
              selectedRows={currentSelectedRows}
              onSelectedRowsChange={handleRowSelected}
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              customStyles={customStyles}
              clearSelectedRows={toggleCleared}
            />
          </Box>
        </Flex>
      </Layout>
      <Modal isOpen={isOpen} onClose={closeModel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={"flex"} flexDirection={"column"}>
            <Formik
              initialValues={{
                _name: activeCourt.name,
                _seoName: activeCourt.seoName,
                _seoDescription: activeCourt.seoDescription,
              }}
              onSubmit={async (
                { _name, _seoName, _seoDescription, _shortDescription },
                { resetForm }
              ) => {
                const formData = new FormData();
                formData.append("name", _name);
                formData.append("seoName", _seoName);
                formData.append("seoDescription", _seoDescription);
                if (file) {
                  formData.append("doc", file);
                }

                const res = await axios.put(
                  `/drafting/${activeCourt._id}`,
                  formData
                );

                if (res.data.ok) {
                  toast({
                    description: "Drafting Note Updated",
                    status: "success",
                    position: "bottom-right",
                  });
                  onClose();
                  fetch();
                } else {
                  toast({
                    description: res.data.message,
                    status: "error",
                    position: "bottom-right",
                  });
                }
              }}
            >
              {({ errors: _e, touched: _t, values, setFieldValue }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl
                      id="_name"
                      isInvalid={_e._name}
                      onChange={(e) => {
                        setFieldValue("_seoName", e.target.value);
                      }}
                    >
                      <FormLabel>Name</FormLabel>
                      <Field
                        as={Input}
                        type="text"
                        name="_name"
                        placeholder={"Enter name of the Note"}
                      />
                      {_t._name && _e._name && (
                        <FormErrorMessage>{_e._name}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl id="file">
                      <FormLabel>Drafting Note</FormLabel>
                      <Input
                        type={"file"}
                        accept={"application/pdf"}
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                    </FormControl>

                    <FormControl
                      id="seoName"
                      isInvalid={_t._seoName && _e._seoName}
                    >
                      <FormLabel>Seo Title</FormLabel>
                      <Field as={Input} type="text" name="_seoName" />
                      {_e._seoName && (
                        <FormErrorMessage>{_e._seoName}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="_seoDescription"
                      name="_seoDescription"
                      isInvalid={_t._seoDescription && _e._seoDescription}
                    >
                      <FormLabel>Seo Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="_seoDescription"
                        value={values._seoDescription}
                      />
                      {_e._seoDescription && (
                        <FormErrorMessage>
                          {_e._seoDescription}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <ModalFooter>
                    <Button type="submit" colorScheme="blue" mr={3}>
                      Save
                    </Button>
                    <Button
                      onClick={closeModel}
                      type={"button"}
                      colorScheme="red"
                      mr={3}
                    >
                      Close
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
