import Layout from "../../components/layouts/base.jsx";
import UsersList from "../../components/admin/UsersList.jsx";
import useFetch from "../../hooks/useFetch.jsx";
import { useState } from "react";
import { Box } from "@chakra-ui/react";

export default function UserManagment() {
  const [refetch, setrefetch] = useState(false);
  const { data, loading, error } = useFetch("user/", {}, refetch);
  const isLoaded = !loading && !error && data && data.data;

  const fetch = () => setrefetch(!refetch);

  return (
    <Layout>
      <Box as={"main"}>
        {isLoaded && <UsersList data={data.data} fetch={fetch} />}
      </Box>
    </Layout>
  );
}

export async function getServerSideProps({ req }) {
  return {
    props: {},
  };
}
