import Layout from "../../components/layouts/base.jsx";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  Textarea,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  ModalCloseButton,
  Button,
  IconButton,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Checkbox,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import useFetch from "../../hooks/useFetch.jsx";
import { axiosInstance as axios } from "../../lib/axios.js";
import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { FaEdit, FaTrash, FaEllipsisV } from "react-icons/fa";
import { MultiSelect } from "react-multi-select-component";
import { CloseIcon } from "@chakra-ui/icons";
import DeleteManyDialog from "../../components/admin/DeleteAll.jsx";

const CourtsSchema = Yup.object().shape({
  name: Yup.string().required("Name must Required"),
  country: Yup.array().required("Court must have a country"),
  state: Yup.array().required("Court must have a country"),
  seoName: Yup.string(),
  seoDescription: Yup.string(),
});

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      height: "7vh",
    },
  },
};

export default function Courts() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [refetch, setrefetch] = useState(false);
  const [activeCourt, setActiveCourt] = useState({});
  const [file, setFile] = useState(null);

  const [currentSelectedRows, setcurrentSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/courts/paginate?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handleSearch = async (e, page = 1) => {
    setLoading(true);
    const res = await axios.get(
      `/courts/search/docs/${e}?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    if (!filterText) {
      fetchData(page);
      return;
    }
    handleSearch(filterText, page);
  };

  const handleRowSelected = useCallback((state) => {
    setcurrentSelectedRows(state.selectedRows);
  }, []);

  const handleMultipleDelete = async (s) => {
    let ids;
    if (Array.isArray(s)) {
      ids = s.map((row) => row._id).join(",");
    } else if (s === "all") {
      ids = "all";
    }
    const _res = await axios.delete(`/courts/deleteall/${ids}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    setToggleCleared(!toggleCleared);
    setFilterText("");
    setcurrentSelectedRows([]);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (!filterText) {
      setLoading(true);
      const res = await axios.get(
        `/courts/paginate?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/courts/search/docs/${filterText}?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  const [seletedStates, setSelectedStates] = useState([]);
  // const { data, loading, error } = useFetch("courts/", {}, refetch);
  const { data: _countrydata, loading: _countryloading } = useFetch(
    "countries/",
    {},
    refetch
  );

  // a custom component act like search bar

  const FilterComponent = ({ filterText, onFilter, onClear, addUser }) => (
    <Box display={"flex"} gap={4}>
      {currentSelectedRows.length > 0 && (
        <Button
          width={"120px"}
          colorScheme={"red"}
          onClick={() => {
            handleMultipleDelete(currentSelectedRows);
          }}
        >
          Delete ({currentSelectedRows.length})
        </Button>
      )}

      <Input
        id="search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        placeholder="Search"
        width={"300px"}
      />
      <IconButton
        colorScheme={"linkedin"}
        onClick={onClear}
        icon={<CloseIcon />}
      />
    </Box>
  );

  // const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const initialRef = useRef(null);
  const popoverRef = useRef(null);

  const toast = useToast();

  const fetch = () => setrefetch(!refetch);

  const deleteCategory = async (_) => {
    const _res = await axios.delete(`/courts/${_._id}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const _openeditModel = async (_data) => {
    onOpen();
    setFile(null);
    setActiveCourt(_data);
    const _tempCont = _data.country.map((_) => _.name).join(",");
    const res = await axios.get(`/states/${_tempCont}`);
    setSelectedStates(res.data.data);
  };

  const closeModel = () => {
    onClose();
    setActiveCourt({});
    setSelectedStates([]);
  };

  const Actions = [
    {
      name: "Edit",
      icon: FaEdit,
      onClick: (_r) => _openeditModel(_r),
      colorSchema: "linkedin",
    },

    {
      name: "Delete",
      icon: FaTrash,
      onClick: (_r) => deleteCategory(_r),
      colorSchema: "red",
    },
  ];

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Country",
      selector: (row, i, column) =>
        row && row.country ? row.country.map((e) => e.name).join(" , ") : "-",
      sortable: true,
    },
    {
      name: "State",
      selector: (row) =>
        row && row.state ? row.state.map((e) => e.name).join(" , ") : "-",
      sortable: true,
    },

    {
      name: "Actions",
      cell: (_r) => (
        <Box position={"fixed"} zIndex={10} itemRef={popoverRef}>
          <Popover closeOnBlur={true}>
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={6}>
              <PopoverHeader fontWeight="semibold" textAlign={"center"}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={"column"} spacing={"4"}>
                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      onClick={() => link.onClick(_r)}
                      colorScheme={link.colorSchema}
                      display={"flex"}
                      gap={"4"}
                      _hover={{
                        color: "white",
                      }}
                    >
                      {<link.icon />}
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  let filteredItems =
    data && data.data
      ? data?.data?.filter((item) =>
          item.name.toLowerCase().includes(filterText.toLowerCase())
        )
      : [];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        fetchData(1);
      }
    };

    return (
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        margin={"0"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}></Text>
        <DeleteManyDialog
          title={"Courts"}
          onDelete={() => handleMultipleDelete("all")}
        />
        <FilterComponent
          onFilter={(e) => {
            setFilterText(e.target.value);
            if (e.target.value && e.target.value.length > 2) {
              handleSearch(e.target.value);
            } else if (!e.target.value) {
              fetchData(1);
            }
            // get the input with id "search" and focus after 400 ms
            setTimeout(() => {
              document.getElementById("search").focus();
            }, 200);
          }}
          onClear={handleClear}
          filterText={filterText}
        />
      </Box>
    );
  }, [filterText, resetPaginationToggle, currentSelectedRows]);

  return (
    <>
      <Layout>
        <Flex
          as={"main"}
          w={"93%"}
          bg={useColorModeValue("gray.50", "gray.800")}
        >
          <Box
            bg={useColorModeValue("white", "gray.700")}
            px={6}
            m={6}
            rounded={"xl"}
            w={"30%"}
          >
            <Text as={"h1"} fontSize={"2xl"} pb={"4"}>
              Managing Courts
            </Text>
            <Formik
              initialValues={{
                name: "",
                country: [],
                state: [],
                shortDescription: "",
                description: "",
                seoName: "",
                seoDescription: "",
                showonHome: false,
              }}
              onSubmit={async (
                {
                  name,
                  country,
                  state,
                  seoName,
                  seoDescription,
                  description,
                  shortDescription,
                  showonHome,
                },
                { resetForm }
              ) => {
                country = country.map((_) => _.value);
                state = state.map((_) => _.value);

                const formData = new FormData();
                formData.append("name", name);
                formData.append("country", country);
                formData.append("state", state);
                formData.append("seoName", seoName);
                formData.append("seoDescription", seoDescription);
                formData.append("description", description);
                formData.append("shortDescription", shortDescription);
                formData.append("doc", file);
                formData.append("showonHome", showonHome);

                const res = await axios.post("/courts/", formData);

                if (res.data.ok) {
                  toast({
                    description: res.data.message,
                    status: "success",
                    position: "bottom-right",
                  });
                  resetForm();
                  document.getElementById("file").value = "";
                  setFile(null);
                  fetch();
                } else {
                  toast({
                    description: res.data.message,
                    status: "error",
                    position: "bottom-right",
                  });
                }
              }}
              validationSchema={CourtsSchema}
            >
              {({
                errors,
                touched,
                resetForm,
                values,
                setValues,
                setFieldValue,
              }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl
                      id="name"
                      isInvalid={errors.name}
                      onChange={(e) => {
                        setFieldValue("seoName", e.target.value);
                      }}
                    >
                      <FormLabel>Name</FormLabel>

                      <Field
                        as={Input}
                        type="text"
                        name="name"
                        placeholder={"Enter name of the court"}
                      />
                      {touched.name && errors.name && (
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="country"
                      isInvalid={touched.country && errors.country}
                      name={"country"}
                    >
                      <FormLabel>Country</FormLabel>
                      <Field
                        as={MultiSelect}
                        placeholder={"Select Country"}
                        name="country"
                        labelledBy={"Select Country"}
                        value={values.country}
                        onChange={async (e) => {
                          try {
                            setValues({
                              ...values,
                              country: e,
                            });

                            const _tempCont = e.map((_) => _.value).join(",");

                            const res = await axios.get(`/states/${_tempCont}`);
                            setSelectedStates(res.data.data);
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                        options={
                          !_countryloading
                            ? _countrydata.data.map((e, i) => ({
                                label: e.name,
                                value: e.name,
                              }))
                            : []
                        }
                      ></Field>
                      {errors.country && (
                        <FormErrorMessage>{errors.country}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="state"
                      isDisabled={values.country === ""}
                      isInvalid={touched.state && errors.state}
                    >
                      <FormLabel>States</FormLabel>
                      <Field
                        as={MultiSelect}
                        disabled={values.country.length === 0}
                        placeholder={"Select State"}
                        name="state"
                        value={values.state}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            state: e,
                          });
                        }}
                        options={
                          seletedStates && seletedStates.length > 0
                            ? seletedStates.map((e, i) => ({
                                label: e.name,
                                value: e.name,
                              }))
                            : []
                        }
                      ></Field>

                      {errors.country && (
                        <FormErrorMessage>{errors.country}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="shortDescription"
                      name="shortDescription"
                      isInvalid={
                        touched.shortDescription && errors.shortDescription
                      }
                    >
                      <FormLabel>Short Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="shortDescription"
                        value={values.shortDescription}
                      />
                      {errors.shortDescription && (
                        <FormErrorMessage>
                          {errors.shortDescription}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="description"
                      name="description"
                      isInvalid={touched.description && errors.description}
                    >
                      <FormLabel>Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="description"
                        value={values.description}
                      />
                      {errors.description && (
                        <FormErrorMessage>
                          {errors.description}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl id="file">
                      <FormLabel>Court Image (Optional)</FormLabel>
                      <Input
                        type={"file"}
                        accept={"image/*"}
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                    </FormControl>

                    <FormControl
                      id="seoName"
                      isInvalid={touched.seoName && errors.seoName}
                    >
                      <FormLabel>Seo Title</FormLabel>
                      <Field as={Input} type="text" name="seoName" />
                      {errors.seoName && (
                        <FormErrorMessage>{errors.seoName}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="seoDescription"
                      name="seoDescription"
                      isInvalid={
                        touched.seoDescription && errors.seoDescription
                      }
                    >
                      <FormLabel>Seo Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="seoDescription"
                        value={values.seoDescription}
                      />
                      {errors.seoDescription && (
                        <FormErrorMessage>
                          {errors.seoDescription}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <Box display={"flex"} gap={4} justifyContent={"flex-end"}>
                    <Button
                      bg={"blue.400"}
                      mt={4}
                      type={"submit"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                    >
                      Create
                    </Button>

                    <FormControl
                      isInvalid={errors.showonHome && touched.showonHome}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      gap={2}
                    >
                      <Field
                        as={Checkbox}
                        id="showonHome"
                        name="showonHome"
                        colorScheme={"green"}
                      />
                      <FormLabel htmlFor="showonHome">
                        Show on Homepage
                      </FormLabel>
                      <FormErrorMessage>{errors.showonHome}</FormErrorMessage>
                    </FormControl>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          <Box
            rounded={"xl"}
            px={4}
            w={"70%"}
            m={6}
            backgroundColor={"#FFFFFF"}
            // m={10}
            border={"1px"}
            borderColor={"gray.200"}
          >
            <DataTable
              striped
              columns={columns}
              data={data.data}
              paginationRowsPerPageOptions={[10, 100, 500, 1000, 2000, 5000]}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              paginationServer
              progressPending={loading}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeader
              selectableRows
              selectedRows={currentSelectedRows}
              onSelectedRowsChange={handleRowSelected}
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              customStyles={customStyles}
              clearSelectedRows={toggleCleared}
            />
          </Box>
        </Flex>
      </Layout>
      <Modal isOpen={isOpen} onClose={closeModel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Court</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={"flex"} flexDirection={"column"}>
            <Formik
              initialValues={{
                _name: activeCourt.name,
                _country: activeCourt.country
                  ? activeCourt.country.map((e) => ({
                      label: e.name,
                      value: e.name,
                    }))
                  : [],
                _state: activeCourt.state
                  ? activeCourt.state.map((e) => ({
                      label: e.name,
                      value: e.name,
                    }))
                  : [],
                _seoName: activeCourt.seoName,
                _seoDescription: activeCourt.seoDescription,
                _shortDescription: activeCourt.shortDescription,
                _description: activeCourt.description,
                _showonHome: activeCourt.showOnHomePage,
              }}
              onSubmit={async (
                {
                  _name,
                  _country,
                  _state,
                  _seoName,
                  _seoDescription,
                  _shortDescription,
                  _description,
                  _showonHome,
                },
                { resetForm }
              ) => {
                _country = _country.map((_) => _.value);
                _state = _state.map((_) => _.value);

                const formData = new FormData();
                formData.append("name", _name);
                formData.append("country", _country);
                formData.append("state", _state);
                formData.append("seoName", _seoName);
                formData.append("seoDescription", _seoDescription);
                formData.append("shortDescription", _shortDescription);
                formData.append("description", _description);
                formData.append("showonHome", _showonHome);
                formData.append("doc", file);

                const res = await axios.put(
                  `/courts/${activeCourt._id}`,
                  formData
                );

                if (res.data.ok) {
                  toast({
                    description: res.data.message,
                    status: "success",
                    position: "bottom-right",
                  });
                  onClose();
                  fetch();
                } else {
                  toast({
                    description: res.data.message,
                    status: "error",
                    position: "bottom-right",
                  });
                }
              }}
            >
              {({
                errors: _e,
                touched: _t,
                resetForm,
                values,
                setValues,
                setFieldValue,
              }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl
                      id="_name"
                      isInvalid={_e._name}
                      onChange={(e) => {
                        setFieldValue("_seoName", e.target.value);
                      }}
                    >
                      <FormLabel>Name</FormLabel>
                      <Field
                        as={Input}
                        type="text"
                        name="_name"
                        placeholder={"Enter name of the court"}
                      />
                      {_t._name && _e._name && (
                        <FormErrorMessage>{_e._name}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="country"
                      isInvalid={_t._country && _e._country}
                      name={"country"}
                    >
                      <FormLabel>Country</FormLabel>
                      <Field
                        as={MultiSelect}
                        value={values._country}
                        placeholder={"Select Country"}
                        options={
                          !_countryloading
                            ? _countrydata.data.map((e, i) => ({
                                label: e.name,
                                value: e.name,
                              }))
                            : []
                        }
                        name="_country"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            _country: e,
                          });
                        }}
                      ></Field>
                      {_e._country && (
                        <FormErrorMessage>{_e._country}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="state"
                      isDisabled={values._country === ""}
                      isInvalid={_t._state && _e._state}
                    >
                      <FormLabel>States</FormLabel>
                      <Field
                        as={MultiSelect}
                        disabled={values._country.length === 0}
                        placeholder={"Select State"}
                        name="state"
                        value={values._state}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            state: e,
                          });
                        }}
                        options={
                          seletedStates && seletedStates.length > 0
                            ? seletedStates.map((e, i) => ({
                                label: e.name,
                                value: e.name,
                              }))
                            : []
                        }
                      ></Field>
                      {_e._state && (
                        <FormErrorMessage>{_e._state}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="_shortDescription"
                      name="_shortDescription"
                      isInvalid={_t._shortDescription && _e._shortDescription}
                    >
                      <FormLabel>Short Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="_shortDescription"
                        value={values._shortDescription}
                      />
                      {_e._shortDescription && (
                        <FormErrorMessage>
                          {_e._shortDescription}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="description"
                      name="_description"
                      isInvalid={_t._description && _e._description}
                    >
                      <FormLabel>Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="_description"
                        value={values._description}
                      />
                      {_e._description && (
                        <FormErrorMessage>{_e._description}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl id="file">
                      <FormLabel>Court Image</FormLabel>
                      <Input
                        id="file"
                        type={"file"}
                        accept={"image/*"}
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                    </FormControl>

                    <FormControl
                      id="seoName"
                      isInvalid={_t._seoName && _e._seoName}
                    >
                      <FormLabel>Seo Title</FormLabel>
                      <Field as={Input} type="text" name="_seoName" />
                      {_e._seoName && (
                        <FormErrorMessage>{_e._seoName}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="_seoDescription"
                      name="_seoDescription"
                      isInvalid={_t._seoDescription && _e._seoDescription}
                    >
                      <FormLabel>Seo Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="_seoDescription"
                        value={values._seoDescription}
                      />
                      {_e._seoDescription && (
                        <FormErrorMessage>
                          {_e._seoDescription}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      isInvalid={_e._showonHome && _e._showonHome}
                      display={"flex"}
                      name={"_showonHome"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      gap={2}
                    >
                      <Field
                        as={Checkbox}
                        id="_showonHome"
                        name="_showonHome"
                        colorScheme={"green"}
                        isChecked={values._showonHome}
                      />
                      <FormLabel htmlFor="_showonHome">
                        Show on Homepage
                      </FormLabel>
                      <FormErrorMessage>{_e._showonHome}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <ModalFooter>
                    <Button type="submit" colorScheme="blue" mr={3}>
                      Save
                    </Button>
                    <Button
                      onClick={closeModel}
                      type={"button"}
                      colorScheme="red"
                      mr={3}
                    >
                      Close
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
