import Layout from "../../components/layouts/base.jsx";
import useFetch from "../../hooks/useFetch.jsx";
import { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { MultiSelect } from "react-multi-select-component";
import Editor from "../../components/admin/Editor.jsx";
import { config } from "../../common/config.js";
import { CloseIcon } from "@chakra-ui/icons";

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  IconButton,
  Textarea,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { axiosInstance as axios } from "../../lib/axios.js";
import { FaEllipsisV, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import DeleteManyDialog from "../../components/admin/DeleteAll.jsx";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      color: "black",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "8vw",
      height: "7vh",
      color: "black",
    },
  },
};

const LawsandRegulatrySchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  type: Yup.string().required("Type is Required"),
  country: Yup.array().required("Country is Required"),
  language: Yup.string().required("Language is Required"),
  state: Yup.array().required("State is Required"),
  similar: Yup.array().optional(),
  description: Yup.string().required("Description Required"),
  seoName: Yup.string(),
  seoDescription: Yup.string(),
});

export default function Lawsandregulatory() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [refetch, setrefetch] = useState(false);
  const [similarLaws, setSimilarLaws] = useState([]);
  const [editorText, setEditorText] = useState("");
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentSelectedRows, setcurrentSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/lawsandreg/paginate?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handleSearch = async (e, page = 1) => {
    setLoading(true);
    const res = await axios.get(
      `/lawsandreg/search/${e}?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    if (!filterText) {
      fetchData(page);
      return;
    }
    handleSearch(filterText, page);
  };

  const handleRowSelected = useCallback((state) => {
    setcurrentSelectedRows(state.selectedRows);
  }, []);

  const handleMultipleDelete = async (s) => {
    let ids;
    if (Array.isArray(s)) {
      ids = s.map((row) => row._id).join(",");
    } else if (s === "all") {
      ids = "all";
    }
    const _res = await axios.delete(`/lawsandreg/deleteall/${ids}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    setToggleCleared(!toggleCleared);
    setFilterText("");
    setcurrentSelectedRows([]);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (!filterText) {
      setLoading(true);
      const res = await axios.get(
        `/lawsandreg/paginate?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/lawsandreg/search/${filterText}?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  const [selectedLawsandReg, setSelectedLawsandReg] = useState({});
  const [seletedStates, setSelectedStates] = useState([]);
  const [selectedFile, changeFile] = useState(null);
  const [selectedFile2, changeFile2] = useState(null);

  const [currentOne, setCurrentOne] = useState({
    edit: false,
    data: {},
  });
  const {
    data: countries,
    loading: countriesLoading,
    error: countriesError,
  } = useFetch("/countries/");

  const {
    data: Languages,
    loading: LanguagesLoading,
    error: LanguagesError,
  } = useFetch("/language/");
  const fetch = () => setrefetch(!refetch);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: cacisOpen,
    onOpen: caconOpen,
    onClose: caconClose,
  } = useDisclosure();

  const toast = useToast();
  const initialRef = useRef(null);

  const lawbytype = async (type) => {
    const _res = await axios.get("/lawsandreg/getbytype/" + type);
    const data = _res.data;
    setSimilarLaws(data.data.map((r) => ({ label: r.name, value: r._id })));
  };

  const openCreateModel = () => {
    setCurrentOne({
      edit: false,
      data: {},
    });
    onOpen();
  };

  const deleteCategory = async (_r) => {
    const _res = await axios.delete("/lawsandreg/" + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    fetch();
  };

  const editCategory = async (_r) => {
    setCurrentOne({
      edit: true,
      data: _r,
    });
    onOpen();

    setEditorText(_r.docContent ? _r.docContent : "");

    // make a  call and find states belongs to the current country
    const _tempCont = _r.country.map((_) => _.name).join(",");
    const res = await axios.get(`/states/${_tempCont}`);
    setSelectedStates(res.data.data);
  };

  const Actions = [
    {
      name: "Edit",
      icon: FaEdit,
      onClick: editCategory,
      colorScheme: "linkedin",
    },

    {
      name: "Delete",
      icon: FaTrash,
      onClick: deleteCategory,
      colorScheme: "red",
    },
  ];

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "7%",
    },
    {
      name: "Name",
      cell: (row) => (
        <Text
          onClick={() => {
            caconOpen();
            setSelectedLawsandReg(row);
          }}
          _hover={{ textDecoration: "underline", cursor: "pointer" }}
          color={"#00A0DC"}
          isTruncated
        >
          {row.name}
        </Text>
      ),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row, i, column) => row.type,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Country",
      selector: (row) =>
        row && row.country.length > 0
          ? row.country.map((e) => e.name).join(" , ")
          : "",
    },
    {
      name: "State",
      selector: (row) =>
        row && row.state.length > 0
          ? row.state.map((e) => e.name).join(" , ")
          : "-",
    },
    {
      name: "Actions",
      cell: (_r) => (
        <Box zIndex={10}>
          <Popover closeOnBlur={true} placement="bottom">
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={6}>
              <PopoverHeader fontWeight="semibold" textAlign={"center"}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={"column"}>
                  <Button
                    colorScheme={"linkedin"}
                    onClick={() =>
                      window.open(
                        `${config.baseURL}/CaC/download/${_r.document_name}`,
                        "_blank"
                      )
                    }
                    _hover={{
                      color: "black",
                    }}
                  >
                    View
                  </Button>

                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      colorScheme={link.colorScheme}
                      onClick={() => link.onClick(_r)}
                      _hover={{
                        color: "black",
                      }}
                    >
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  const FilterComponent = ({ filterText, onFilter, onClear, addUser }) => (
    <Box display={"flex"} gap={4}>
      {currentSelectedRows.length > 0 && (
        <Button
          width={"120px"}
          colorScheme={"red"}
          onClick={() => {
            handleMultipleDelete(currentSelectedRows);
          }}
        >
          Delete ({currentSelectedRows.length})
        </Button>
      )}

      <Input
        id="search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        placeholder="Search"
      />
      <IconButton
        colorScheme={"linkedin"}
        onClick={onClear}
        icon={<CloseIcon />}
      />
    </Box>
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        fetchData(1);
      }
    };

    return (
      <Box
        width={"100%"}
        display={"flex"}
        gap={4}
        margin={"0"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}>
          Laws and Regulatories
        </Text>
        <Box
          display={"flex"}
          gap={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <DeleteManyDialog
            title={"Laws and Regulatories"}
            onDelete={() => handleMultipleDelete("all")}
          />
          <Box>
            <FilterComponent
              onFilter={(e) => {
                setFilterText(e.target.value);
                if (e.target.value && e.target.value.length > 2) {
                  handleSearch(e.target.value);
                } else if (!e.target.value) {
                  fetchData(1);
                }
                // get the input with id "search" and focus after 400 ms
                setTimeout(() => {
                  document.getElementById("search").focus();
                }, 200);
              }}
              onClear={handleClear}
              filterText={filterText}
            />
          </Box>

          <IconButton
            onClick={openCreateModel}
            colorScheme={"linkedin"}
            icon={<FaPlusCircle />}
          />
        </Box>
      </Box>
    );
  }, [filterText, resetPaginationToggle, currentSelectedRows]);

  return (
    <Layout>
      <Flex as={"main"} w={"95%"} bg={useColorModeValue("gray.50", "gray.800")}>
        <Modal
          initialFocusRef={initialRef}
          isOpen={isOpen}
          size={"full"}
          onClose={() => {
            onClose();
            setCurrentOne({
              edit: false,
              data: {},
            });
            setEditorText("");
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {!currentOne.edit ? "Add New Law or Regulatory" : "Edit"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
              <Box>
                <Formik
                  initialValues={{
                    name: currentOne.edit ? currentOne.data.name : "",
                    type: currentOne.edit ? currentOne.data.type : "",
                    description: currentOne.edit
                      ? currentOne.data.description
                      : "",
                    country: currentOne.edit
                      ? currentOne.data.country.map((c) => ({
                          label: c.name,
                          value: c.name,
                        }))
                      : [],
                    language: currentOne.edit ? currentOne?.data?.language : "",
                    state: currentOne.edit
                      ? currentOne.data.state.map((c) => ({
                          label: c.name,
                          value: c.name,
                        }))
                      : [],
                    similar: currentOne.edit ? currentOne.data.similar : [],
                    seoName: currentOne.edit ? currentOne.data.seoName : "",
                    seoDescription: currentOne.edit
                      ? currentOne.data.seoDescription
                      : "",
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    values.country = values.country.map((c) => c.value);
                    values.state = values.state.map((c) => c.value);
                    let similarList = values.similar.map((c) => c.value);

                    const formData = new FormData();
                    formData.set("name", values.name);
                    formData.set("type", values.type);
                    formData.set("description", values.description);
                    formData.set("country", values.country);
                    formData.set("language", values.language);
                    formData.set("state", values.state);
                    formData.set("similar", similarList);
                    formData.set("seoName", values.seoName);
                    formData.set("seoDescription", values.seoDescription);
                    formData.set("document", selectedFile);
                    formData.set("docContent", editorText);

                    const url = currentOne.edit
                      ? "/lawsandreg/" + currentOne.data._id
                      : "/lawsandreg/create";

                    const _res = currentOne.edit
                      ? await axios.put(url, formData)
                      : await axios.post(url, formData);

                    if (_res.data.ok) {
                      toast({
                        description: _res.data.message,
                        status: "success",
                        position: "bottom-right",
                      });
                      resetForm();
                      changeFile(null);
                      changeFile2(null);
                      setEditorText("");
                      onClose();
                      fetch();
                      setCurrentOne({
                        edit: false,
                        data: {},
                      });
                    } else {
                      toast({
                        description: _res.data.message,
                        status: "error",
                        position: "bottom-right",
                      });
                    }
                  }}
                  validationSchema={LawsandRegulatrySchema}
                >
                  {({ errors, touched, values, setValues, setFieldValue }) => (
                    <Form>
                      <Stack spacing={4}>
                        <Flex
                          gap={10}
                          justifyContent={"space-between"}
                          alignItems={"start"}
                        >
                          <Stack spacing={8} width={"100%"}>
                            <FormControl
                              id="name"
                              isInvalid={touched.name && errors.name}
                              onChange={(e) => {
                                setFieldValue("seoName", e.target.value);
                              }}
                            >
                              <FormLabel>
                                Name of the Law or Regulatory
                              </FormLabel>
                              <Field as={Input} type="text" name="name" />
                              {errors.name && (
                                <FormErrorMessage>
                                  {errors.name}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="type"
                              isInvalid={touched.type && errors.type}
                            >
                              <FormLabel>Law or Reguation</FormLabel>
                              <Field
                                as={Select}
                                placeholder={"Select Type"}
                                name="type"
                                onChange={(e) => {
                                  setFieldValue("type", e.target.value);
                                  lawbytype(e.target.value);
                                }}
                              >
                                <option value="law">Law</option>
                                <option value="regulation">Regulation</option>
                              </Field>
                              {errors.type && (
                                <FormErrorMessage>
                                  {errors.type}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="language"
                              isInvalid={touched.language && errors.language}
                            >
                              <FormLabel>Language</FormLabel>
                              <Field
                                as={Select}
                                placeholder={"Select language"}
                                name="language"
                              >
                                {!LanguagesLoading &&
                                  Languages.data.map((l) => (
                                    <option key={l._id} value={l._id}>
                                      {l.name}
                                    </option>
                                  ))}
                              </Field>
                              {errors.language && (
                                <FormErrorMessage>
                                  {errors.language}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="country"
                              isInvalid={touched.country && errors.country}
                              name={"country"}
                            >
                              <FormLabel>Country</FormLabel>
                              <Field
                                as={MultiSelect}
                                placeholder={"Select Country"}
                                name="country"
                                labelledBy={"Select Country"}
                                value={values.country}
                                onChange={async (e) => {
                                  try {
                                    setValues({
                                      ...values,
                                      state: [],
                                      country: e,
                                    });

                                    const _tempCont = e
                                      .map((_) => _.value)
                                      .join(",");

                                    const res = await axios.get(
                                      `/states/${_tempCont}`
                                    );
                                    setSelectedStates(res.data.data);
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                                options={
                                  !countriesLoading
                                    ? countries.data.map((e, i) => ({
                                        label: e.name,
                                        value: e.name,
                                      }))
                                    : []
                                }
                              ></Field>
                              {errors.country && (
                                <FormErrorMessage>
                                  {errors.country}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            <FormControl
                              id="state"
                              isDisabled={values.country == ""}
                              isInvalid={touched.state && errors.state}
                            >
                              <FormLabel>States</FormLabel>
                              <Field
                                as={MultiSelect}
                                disabled={values.country.length == 0}
                                placeholder={"Select State"}
                                name="state"
                                value={values.state}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    state: e,
                                  });
                                }}
                                options={
                                  seletedStates && seletedStates.length > 0
                                    ? seletedStates.map((e, i) => ({
                                        label: e.name,
                                        value: e.name,
                                      }))
                                    : []
                                }
                              ></Field>

                              {errors.country && (
                                <FormErrorMessage>
                                  {errors.country}
                                </FormErrorMessage>
                              )}
                            </FormControl>

                            <FormControl
                              id="similar"
                              isDisabled={values.country == ""}
                              isInvalid={touched.similar && errors.similar}
                            >
                              <FormLabel>Similar</FormLabel>
                              <Field
                                as={MultiSelect}
                                placeholder={"Select Similar Law or Regulation"}
                                name="similar"
                                value={values.similar}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    similar: e,
                                  });
                                }}
                                options={
                                  similarLaws && similarLaws.length > 0
                                    ? similarLaws.map((e, i) => ({
                                        label: e.label,
                                        value: e.value,
                                      }))
                                    : []
                                }
                                disabled={similarLaws.length === 0}
                              ></Field>

                              {errors.similar && (
                                <FormErrorMessage>
                                  {errors.similar}
                                </FormErrorMessage>
                              )}
                            </FormControl>

                            <FormControl id="file">
                              <FormLabel>Document</FormLabel>
                              <Input
                                type={"file"}
                                accept={"application/pdf"}
                                onChange={(e) => {
                                  changeFile(e.target.files[0]);
                                }}
                              />
                            </FormControl>
                          </Stack>

                          <Stack spacing={8} width={"100%"}>
                            <FormControl
                              id="description"
                              name="description"
                              isInvalid={
                                touched.description && errors.description
                              }
                            >
                              <FormLabel>Description</FormLabel>
                              <Field
                                as={Textarea}
                                type="text"
                                name="description"
                                value={values.description}
                              />
                              {errors.description && (
                                <FormErrorMessage>
                                  {errors.description}
                                </FormErrorMessage>
                              )}
                            </FormControl>

                            {/* <FormControl id="file">
                              <FormLabel>Additional Document</FormLabel>
                              <Input
                                type={"file"}
                                accept={"application/pdf"}
                                onChange={(e) => {
                                  changeFile2(e.target.files[0]);
                                }}
                              />
                            </FormControl> */}

                            <FormControl
                              id="seoName"
                              isInvalid={touched.seoName && errors.seoName}
                            >
                              <FormLabel>Seo Title</FormLabel>
                              <Field as={Input} type="text" name="seoName" />
                              {errors.seoName && (
                                <FormErrorMessage>
                                  {errors.seoName}
                                </FormErrorMessage>
                              )}
                            </FormControl>

                            <FormControl
                              id="seoDescription"
                              name="seoDescription"
                              isInvalid={
                                touched.seoDescription && errors.seoDescription
                              }
                            >
                              <FormLabel>Seo Description</FormLabel>
                              <Field
                                as={Textarea}
                                type="text"
                                name="seoDescription"
                                value={values.seoDescription}
                              />
                              {errors.seoDescription && (
                                <FormErrorMessage>
                                  {errors.seoDescription}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Stack>

                          <Stack spacing={8} width={"100%"}>
                            <Editor
                              setEditorText={setEditorText}
                              editorText={editorText}
                            />
                          </Stack>
                        </Flex>

                        <Box
                          display={"flex"}
                          gap={2}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Button
                            colorScheme={"linkedin"}
                            type={"submit"}
                            color={"white"}
                            _hover={{
                              bg: "blue.500",
                            }}
                            mb={"8"}
                          >
                            {currentOne.edit ? "Update" : "Create"}
                          </Button>
                        </Box>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal
          initialFocusRef={initialRef}
          isOpen={cacisOpen}
          onClose={() => {
            caconClose();
            setSelectedLawsandReg({});
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
              <Text as={"h3"}>Name</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedLawsandReg.name}
              </Text>
              <Text as={"h3"}>Type</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedLawsandReg.type}
              </Text>
              <Text as={"h3"}>Country</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedLawsandReg.country}
              </Text>
              <Text as={"h3"}>Description</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedLawsandReg.description}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme={"linkedin"} onClick={caconClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box
          rounded={"xl"}
          px={4}
          w={"100%"}
          m={6}
          backgroundColor={"#FFFFFF"}
          border={"1px"}
          borderColor={"gray.200"}
        >
          <DataTable
            striped
            columns={columns}
            data={data.data}
            paginationRowsPerPageOptions={[10, 100, 500, 1000, 2000, 5000]}
            pagination
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            fixedHeader={true}
            paginationComponentOptions={{
              selectAllRowsItemText: "All",
            }}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            selectableRows
            selectedRows={currentSelectedRows}
            onSelectedRowsChange={handleRowSelected}
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={{
              ...customStyles,
              responsiveWrapper: {
                overflow: "hidden",
              },
            }}
            clearSelectedRows={toggleCleared}
          />
        </Box>
      </Flex>
    </Layout>
  );
}
