import SignIn from "../../components/auth/Signin";
import NotFound from "../404";
import { useLocation } from "react-router";

export default function AuthComponent() {
  const location = useLocation();

  const action = location.pathname.trim().split("/")[3];
  const role = location.pathname.split("/")[2];

  if (action === "signin" && (role === "admin" || role === "user"))
    return <SignIn role={role} />;
  else return <NotFound />;
}
