import Layout from "../../components/layouts/base.jsx";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  Text,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import useFetch from "../../hooks/useFetch.jsx";
import { useState } from "react";
import { axiosInstance as axios } from "../../lib/axios.js";

const TermsandCondtionsSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
});

export default function TermsandCondtions() {
  const [refetch, setrefetch] = useState(false);
  const [editableformcontactus, setEditableformContactus] = useState(false);

  const { data, loading } = useFetch("termsandcond/getall", {}, refetch);

  const toast = useToast();

  const fetch = () => setrefetch(!refetch);
  return (
    <>
      <Layout>
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          height={"100%"}
          overflowX={"hidden"}
        >
          <Flex
            width={"100%"}
            as={"main"}
            bg={useColorModeValue("gray.50", "gray.800")}
          >
            <Box
              px={6}
              m={6}
              rounded={"xl"}
              width={"100%"}
              boxShadow={"md"}
              borderWidth={"1px"}
              p={5}
            >
              <Text as={"h1"} fontSize={"2xl"} pb={"4"}>
                Managing Terms and Conditions
              </Text>

              {!loading && data && (
                <Formik
                  initialValues={{
                    title:
                      !loading && data && data.data && data.data.title
                        ? data.data.title
                        : "",
                    description:
                      !loading && data && data.data && data.data.description
                        ? data.data.description
                        : "",
                  }}
                  onSubmit={async ({ title, description }) => {
                    const res = await axios.post("/termsandcond/create", {
                      title,
                      description,
                    });

                    if (res.data.ok) {
                      toast({
                        title: "Terms and Conditions Updated",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });
                      setEditableformContactus(false);
                      fetch();
                    } else {
                      toast({
                        title: "Something went wrong",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                    }
                  }}
                  validationSchema={TermsandCondtionsSchema}
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <Form>
                      <Stack spacing={8} direction={"column"} width={"100%"}>
                        <FormControl
                          id="title"
                          isInvalid={errors.title}
                          name="title"
                          isReadOnly={!editableformcontactus}
                        >
                          <FormLabel>Title Url</FormLabel>
                          <Field as={Input} type="text" name="title" />
                          {touched.title && errors.title && (
                            <FormErrorMessage>{errors.title}</FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="description"
                          name="description"
                          isReadOnly={!editableformcontactus}
                          isInvalid={touched.description && errors.description}
                        >
                          <FormLabel>Description</FormLabel>
                          <Field
                            as={Textarea}
                            type="text"
                            name="description"
                            rows={20}
                            value={values.description}
                          />
                          {errors.description && (
                            <FormErrorMessage>
                              {errors.description}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Stack>
                      <Box display={"flex"} gap={4}>
                        <Button
                          mt={4}
                          type={"button"}
                          colorScheme={"green"}
                          color={"white"}
                          _hover={{
                            bg: "blue.500",
                          }}
                          onClick={() => {
                            setEditableformContactus(true);
                            console.log(editableformcontactus);
                          }}
                          disabled={editableformcontactus}
                        >
                          Edit
                        </Button>
                        <Button
                          bg={"blue.400"}
                          colorScheme={"blue"}
                          mt={4}
                          type={"submit"}
                          color={"white"}
                          _hover={{
                            bg: "blue.500",
                          }}
                          disabled={!editableformcontactus}
                        >
                          Save
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )}
            </Box>
          </Flex>
        </Box>
      </Layout>
    </>
  );
}
