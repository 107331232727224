import Layout from "../../components/layouts/base.jsx";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  Text,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import useFetch from "../../hooks/useFetch.jsx";
import { useState } from "react";
import { axiosInstance as axios } from "../../lib/axios.js";
import PhoneInput from "react-phone-input-2";

const AdminOptionsSchema = Yup.object().shape({
  facebooklink: Yup.string().required("Required"),
  linkdinlink: Yup.string().required("Required"),
  youtubelink: Yup.string().required("Required"),
  twitterlink: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
});

export default function ContactOptions() {
  const [refetch, setrefetch] = useState(false);
  const [editableformcontactus, setEditableformContactus] = useState(false);

  const { data, loading } = useFetch(
    "adminoptions/getcontactsection",
    {},
    refetch
  );

  const toast = useToast();

  const fetch = () => setrefetch(!refetch);

  return (
    <>
      <Layout>
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          height={"100%"}
          overflowX={"hidden"}
        >
          <Flex
            width={"100%"}
            as={"main"}
            bg={useColorModeValue("gray.50", "gray.800")}
          >
            <Box
              px={6}
              m={6}
              rounded={"xl"}
              width={"100%"}
              boxShadow={"md"}
              borderWidth={"1px"}
              p={5}
            >
              <Text as={"h1"} fontSize={"2xl"} pb={"4"}>
                Managing Contact Us Section
              </Text>

              {!loading && data && (
                <Formik
                  initialValues={{
                    facebooklink:
                      !loading && data && data.data && data.data.facebooklink
                        ? data.data.facebooklink
                        : "",
                    linkdinlink:
                      !loading && data && data.data && data.data.linkedinlink
                        ? data.data.linkedinlink
                        : "",
                    youtubelink:
                      !loading && data && data.data && data.data.youtubelink
                        ? data.data.youtubelink
                        : "",
                    twitterlink:
                      !loading && data && data.data && data.data.twitterlink
                        ? data.data.twitterlink
                        : "",
                    email:
                      !loading && data && data.data && data.data.email
                        ? data.data.email
                        : "",
                    phone:
                      !loading && data && data.data && data.data.phone
                        ? data.data.phone
                        : "",
                    address:
                      !loading && data && data.data && data.data.address
                        ? data.data.address
                        : "",
                  }}
                  onSubmit={async ({
                    facebooklink,
                    linkdinlink,
                    youtubelink,
                    twitterlink,
                    email,
                    phone,
                    address,
                  }) => {
                    const res = await axios.post(
                      "/adminoptions/createcontactsection",
                      {
                        facebooklink,
                        linkedinlink: linkdinlink,
                        youtubelink,
                        twitterlink,
                        email,
                        phone,
                        address,
                      }
                    );

                    if (res.data.ok) {
                      toast({
                        title: "Contact Us Section Updated",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });
                      setEditableformContactus(false);
                      fetch();
                    } else {
                      toast({
                        title: "Something went wrong",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                    }
                  }}
                  validationSchema={AdminOptionsSchema}
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <Form>
                      <Stack spacing={8} direction={"row"} width={"100%"}>
                        <Box
                          width={"40%"}
                          display={"flex"}
                          gap={5}
                          flexDirection={"column"}
                        >
                          <FormControl
                            id="facebooklink"
                            isInvalid={errors.facebooklink}
                            name="facebooklink"
                            isReadOnly={!editableformcontactus}
                          >
                            <FormLabel>Facebook Url</FormLabel>
                            <Field as={Input} type="text" name="facebooklink" />
                            {touched.facebooklink && errors.facebooklink && (
                              <FormErrorMessage>
                                {errors.facebooklink}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="linkdinlink"
                            isInvalid={errors.linkdinlink}
                            name="linkdinlink"
                            isReadOnly={!editableformcontactus}
                          >
                            <FormLabel>Linkdin Url</FormLabel>
                            <Field as={Input} type="text" name="linkdinlink" />
                            {touched.linkdinlink && errors.linkdinlink && (
                              <FormErrorMessage>
                                {errors.linkdinlink}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="email"
                            isInvalid={errors.email}
                            name="email"
                            isReadOnly={!editableformcontactus}
                          >
                            <FormLabel>Email</FormLabel>
                            <Field as={Input} type="text" name="email" />
                            {touched.email && errors.email && (
                              <FormErrorMessage>
                                {errors.email}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="twitterlink"
                            isInvalid={errors.twitterlink}
                            name="twitterlink"
                            isReadOnly={!editableformcontactus}
                          >
                            <FormLabel>Twitter Url</FormLabel>
                            <Field as={Input} type="text" name="twitterlink" />
                            {touched.twitterlink && errors.twitterlink && (
                              <FormErrorMessage>
                                {errors.twitterlink}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Box>

                        <Box
                          width={"40%"}
                          display={"flex"}
                          gap={5}
                          flexDirection={"column"}
                        >
                          <FormControl
                            id="youtubelink"
                            isInvalid={errors.youtubelink}
                            name="youtubelink"
                            isReadOnly={!editableformcontactus}
                          >
                            <FormLabel>Youtube Url</FormLabel>
                            <Field as={Input} type="text" name="youtubelink" />
                            {touched.youtubelink && errors.youtubelink && (
                              <FormErrorMessage>
                                {errors.youtubelink}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="phone"
                            isInvalid={errors.phone}
                            isReadOnly={!editableformcontactus}
                          >
                            <FormLabel>Phone Number</FormLabel>
                            {/* <Field as={Input} type="text" name="phone" /> */}
                            <Field name="phone">
                              {({ field, form }) => (
                                <PhoneInput
                                  {...field}
                                  country={"us"}
                                  disabled={!editableformcontactus}
                                  inputStyle={{
                                    width: "100%",
                                    height: "40px",
                                  }}
                                  value={field.value}
                                  onChange={(value) => {
                                    form.setFieldValue("phone", value);
                                  }}
                                />
                              )}
                            </Field>

                            {touched.phone && errors.phone && (
                              <FormErrorMessage>
                                {errors.phone}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <FormControl
                            id="address"
                            name="address"
                            isReadOnly={!editableformcontactus}
                            isInvalid={touched.address && errors.address}
                          >
                            <FormLabel>Address</FormLabel>
                            <Field
                              as={Textarea}
                              type="text"
                              name="address"
                              value={values.address}
                            />
                            {errors.address && (
                              <FormErrorMessage>
                                {errors.address}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Box>
                      </Stack>
                      <Box display={"flex"} gap={4}>
                        <Button
                          mt={4}
                          type={"button"}
                          colorScheme={"green"}
                          color={"white"}
                          _hover={{
                            bg: "blue.500",
                          }}
                          onClick={() => {
                            setEditableformContactus(true);
                            console.log(editableformcontactus);
                          }}
                          disabled={editableformcontactus}
                        >
                          Edit
                        </Button>
                        <Button
                          bg={"blue.400"}
                          colorScheme={"blue"}
                          mt={4}
                          type={"submit"}
                          color={"white"}
                          _hover={{
                            bg: "blue.500",
                          }}
                          disabled={!editableformcontactus}
                        >
                          Save
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )}
            </Box>
          </Flex>
        </Box>
      </Layout>
    </>
  );
}
