export const config = Object.freeze({
  websiteName: "Linkinlegal",
  // baseURL: "http://localhost:4000/api/v1",
  baseURL: "https://api-demo.linkinlegal.com/api/v1",
  commonMonths: {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  },
  commonModels: [
    "cacmodels",
    "categories",
    "countries",
    "courts",
    "judgementandcaselaws",
    "judgementcategories",
    "lawsandregulatories",
    "states",
    "users",
  ],
});
