import Layout from "../../components/layouts/base.jsx";
import useFetch from "../../hooks/useFetch.jsx";
import { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { Chips } from "primereact/chips";
import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  InputGroup,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Icon,
  IconButton,
  Textarea,
  Checkbox,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import PhoneInput from "react-phone-input-2";
import { axiosInstance as axios } from "../../lib/axios.js";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { FaEllipsisV, FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import { config } from "../../common/config.js";
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      color: "black",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      height: "7vh",
      width: "3vw",
      color: "black",
    },
  },
};

const AddCaCSchema = Yup.object().shape(
  {
    professionalname: Yup.string().required("Professional Name is Required"),
    realname: Yup.string().required("Real Name is Required"),
    shortDescription: Yup.string(),
    description: Yup.string(),
    type: Yup.string().required("Type is Required"),
    email: Yup.string()
      .email("Invalid email")
      .when("mobile", {
        is: (mobile) => !mobile,
        then: Yup.string().required("Email is Required"),
      }),
    mobile: Yup.string().when("email", {
      is: (email) => !email,
      then: Yup.string().required("Mobile Number is Required"),
    }),
    address: Yup.string(),
    seoName: Yup.string(),
    seoDescription: Yup.string(),
    pinCode: Yup.string()
      .min(6, "Pin Code must be 6 digit")
      .max(6, "Pin Code must be 6 digit")
      .required("Pin Code is Required"),
    country: Yup.string().required("Country is Required"),
    state: Yup.string().required("State is Required"),
    district: Yup.string().required("District is Required"),
    website: Yup.string().required("Website is Required"),
    canBuyUserPlan: Yup.boolean(),
  },
  [["email", "mobile"]]
);

export default function MemberListing() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [refetch, setrefetch] = useState(false);
  const [phoneFields, setPhoneFields] = useState(1);
  const [phoneFieldValues, setPhoneFieldValues] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const {
    data: _countrydata,
    loading: _countryloading,
    error: _countryerror,
  } = useFetch("countries/", {}, refetch);

  console.log(_countrydata);

  const [selectedFile, changeFile] = useState(null);
  const [currentOne, setCurrentOne] = useState({
    edit: false,
    data: {},
  });
  const [selectedCaC, setSelectedCaC] = useState({});

  const [currentSelectedRows, setcurrentSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const fetch = () => setrefetch(!refetch);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: cacisOpen,
    onOpen: caconOpen,
    onClose: caconClose,
  } = useDisclosure();

  const toast = useToast();
  const initialRef = useRef(null);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/memberlisting/paginate?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handleSearch = async (e, page = 1) => {
    setLoading(true);
    const res = await axios.get(
      `/memberlisting/search/${e}?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    if (!filterText) {
      fetchData(page);
      return;
    }
    handleSearch(filterText, page);
  };

  const handleRowSelected = useCallback((state) => {
    setcurrentSelectedRows(state.selectedRows);
  }, []);

  const handleMultipleDelete = async (s) => {
    const ids = s.map((row) => row._id);
    const _res = await axios.delete(
      `/memberlisting/deleteall/${ids.join(",")}`
    );
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    setToggleCleared(!toggleCleared);
    setFilterText("");
    setcurrentSelectedRows([]);
  };

  const BulkUpload = async (file) => {
    if (!file)
      return toast({
        description: "Please Select a file",
        status: "error",
        position: "bottom-right",
      });

    const formData = new FormData();
    formData.append("file", file);
    const res = await axios.post("/memberlisting/bulk", formData);

    if (res.data.ok) {
      toast({
        description: res.data.message,
        status: "success",
        position: "bottom-right",
        isClosable: true,
      });
      fetch();
    } else {
      toast({
        description: res.data.message,
        status: "error",
        position: "bottom-right",
        isClosable: true,
      });
    }

    document.getElementById("file").value = "";
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (!filterText) {
      setLoading(true);
      const res = await axios.get(
        `/memberlisting/paginate?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/memberlisting/search/${filterText}?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    }
  };

  const fetchState = async (country) => {
    const res = await axios.get(`/states/findbycountryid?country=${country}`);
    setStateData(res.data.data);
  };

  const fetchDistrict = async (state) => {
    const res = await axios.get(`/district/findbystate?state=${state}`);
    setDistrictData(res.data.data);
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  const openCreateModel = () => {
    setCurrentOne({
      edit: false,
      data: {},
    });
    onOpen();
  };
  const deleteCategory = async (_r) => {
    const _res = await axios.delete("/memberlisting/" + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
    fetch();
  };

  const FilterComponent = ({ filterText, onFilter, onClear, addUser }) => (
    <Box display={"flex"} gap={4}>
      {currentSelectedRows.length > 0 && (
        <Button
          width={"120px"}
          colorScheme={"red"}
          onClick={() => {
            handleMultipleDelete(currentSelectedRows);
          }}
        >
          Delete ({currentSelectedRows.length})
        </Button>
      )}

      <Input
        id="search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        width={"300px"}
        placeholder="Search"
      />
      <IconButton
        colorScheme={"linkedin"}
        onClick={onClear}
        icon={<CloseIcon />}
      />

      <IconButton
        onClick={openCreateModel}
        colorScheme={"linkedin"}
        icon={<FaPlusCircle />}
      />
    </Box>
  );

  const editCategory = async (_r) => {
    if (_r.mobile) {
      setPhoneFields(_r.mobile.split(",").length);
    }

    // fetch state and district
    await fetchState(_r.country);
    await fetchDistrict(_r.state);

    setCurrentOne({
      edit: true,
      data: _r,
    });
    onOpen();
  };

  const viewFile = (key) => {
    window.open(`${config.baseURL}/CaC/download/${key}`, "_blank");
  };

  const Actions = [
    {
      name: "Edit",
      icon: FaEdit,
      onClick: editCategory,
      colorSchema: "linkedin",
    },

    {
      name: "Delete",
      icon: FaTrash,
      onClick: deleteCategory,
      colorSchema: "red",
    },
  ];

  const columns = [
    {
      name: "S/N",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "7%",
    },
    {
      name: "Professional Name",
      cell: (_r) => (
        <Text
          onClick={() => {
            caconOpen();
            setSelectedCaC(_r);
          }}
          _hover={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color={"#00A0DC"}
        >
          {_r.professionalname}
        </Text>
      ),
      sortable: true,
    },
    {
      name: "Real Name",
      cell: (_r) => <Text>{_r.realname}</Text>,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row, i, column) =>
        row.type[0].toUpperCase() + row.type.slice(1),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row, i, column) => row.email,
      sortable: true,
      width: "7%",
    },
    {
      name: "Phone",
      selector: (row, i, column) => row.mobile || "N/A",
      sortable: true,
    },
    {
      name: "Website",
      selector: (row, i, column) => row.website || "N/A",
      sortable: true,
      width: "12%",
    },

    {
      name: "Profile",
      width: "7%",
      selector: (row, i, column) =>
        row.image ? row.image : "Profile not uploaded",
      cell: (_r) =>
        _r.image ? (
          <Button colorScheme={"linkedin"} onClick={() => viewFile(_r.image)}>
            View
          </Button>
        ) : (
          <Button colorScheme={"linkedin"}>Upload</Button>
        ),
    },
    {
      name: "Actions",
      cell: (_r) => (
        <Box zIndex={10}>
          <Popover closeOnBlur={true}>
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={5}>
              <PopoverHeader fontWeight="semibold" textAlign={"center"}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={"column"}>
                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      onClick={() => link.onClick(_r)}
                      colorScheme={link.colorSchema}
                      _hover={{
                        color: "black",
                      }}
                    >
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        fetchData(1);
      }
    };

    return (
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        margin={"0"}
      >
        <Text fontSize="2xl" fontWeight={"extrabold"}>
          Manage Members
        </Text>
        <FilterComponent
          onFilter={(e) => {
            setFilterText(e.target.value);
            if (e.target.value && e.target.value.length > 2) {
              handleSearch(e.target.value);
            } else if (!e.target.value) {
              fetchData(1);
            }
            // get the input with id "search" and focus after 400 ms
            setTimeout(() => {
              document.getElementById("search").focus();
            }, 200);
          }}
          onClear={handleClear}
          filterText={filterText}
        />
      </Box>
    );
  }, [filterText, resetPaginationToggle, currentSelectedRows]);

  const handleSubmit = async (values, { resetForm }) => {
    delete values.mobile;
    let mblStr = "";
    new Array(phoneFields).fill(0).map((e, i) => {
      if (values[`mobile${i}`]) {
        mblStr += values[`mobile${i}`] + ",";
      }
    });

    values.mobile = mblStr.slice(0, -1);
    const _values = {
      ...values,
      document: selectedFile,
    };

    let formData = new FormData();
    for (let key in _values) {
      if (
        _values[key] === null ||
        _values[key] === undefined ||
        _values[key] === ""
      ) {
        continue;
      }
      formData.append(key, _values[key]);
    }

    const url = currentOne.edit
      ? "/memberlisting/" + currentOne.data._id
      : "/memberlisting/create";

    const _res = currentOne.edit
      ? await axios.put(url, formData)
      : await axios.post(url, formData);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: "success",
        position: "bottom-right",
      });
      resetForm();
      changeFile(null);
      onClose();
      fetch();
      setCurrentOne({
        edit: false,
        data: {},
      });
      setPhoneFields(1);
    } else {
      toast({
        description: _res.data.message,
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Layout>
      <Flex
        as={"main"}
        w={"100%"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <input
          type={"file"}
          id={"file"}
          accept={
            ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          }
          onChange={(e) => {
            BulkUpload(e.target.files[0]);
          }}
          style={{ display: "none" }}
        />

        <Modal
          initialFocusRef={initialRef}
          size={"4xl"}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setCurrentOne({
              edit: false,
              data: {},
            });
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              marginLeft={"18%"}
              fontFamily={"heading"}
              fontSize={"2xl"}
            >
              {!currentOne.edit ? "Add a Member" : "Edit a Member"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-evenly"}
              gap={10}
            >
              <Box w={"60%"}>
                <Formik
                  initialValues={{
                    professionalname: currentOne.edit
                      ? currentOne.data.professionalname
                      : "",
                    realname: currentOne.edit ? currentOne.data.realname : "",
                    type: currentOne.edit ? currentOne.data.type : "",
                    description: currentOne.edit
                      ? currentOne.data.description
                      : "",
                    shortDescription: currentOne.edit
                      ? currentOne.data.shortDescription
                      : "",
                    email: currentOne.edit ? currentOne.data.email : "",
                    mobile: currentOne.edit ? currentOne.data.mobile : "",
                    country: currentOne.edit ? currentOne.data.country : "",
                    state: currentOne.edit ? currentOne.data.state : "",
                    district: currentOne.edit ? currentOne.data.district : "",
                    pinCode: currentOne.edit ? currentOne.data.pinCode : "",
                    address: currentOne.edit ? currentOne.data.address : "",
                    seoName: currentOne.edit ? currentOne.data.seoName : "",
                    seoDescription: currentOne.edit
                      ? currentOne.data.seoDescription
                      : "",
                    website: currentOne.edit ? currentOne.data?.website : "",
                    canBuyUserPlan: currentOne.edit
                      ? currentOne.data?.canBuyUserPlan
                      : false,
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={AddCaCSchema}
                >
                  {({ errors, touched, values, setValues, setFieldValue }) => (
                    <Form>
                      <Stack spacing={4}>
                        <FormControl
                          id="professionalname"
                          isInvalid={
                            touched.professionalname && errors.professionalname
                          }
                          onChange={(e) => {
                            setFieldValue("seoName", e.target.value);
                          }}
                        >
                          <FormLabel>Professional Name of the Member</FormLabel>
                          <Field
                            as={Input}
                            type="text"
                            name="professionalname"
                          />
                          {errors.professionalname && (
                            <FormErrorMessage>
                              {errors.professionalname}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="realname"
                          isInvalid={touched.realname && errors.realname}
                        >
                          <FormLabel>Real Name of the Member</FormLabel>
                          <Field as={Input} type="text" name="realname" />
                          {errors.realname && (
                            <FormErrorMessage>
                              {errors.realname}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="type"
                          isInvalid={touched.type && errors.type}
                        >
                          <FormLabel>Type of the Member</FormLabel>
                          <Field
                            as={Select}
                            placeholder={"Select Type"}
                            name="type"
                          >
                            <option value="lawyer">Lawyer</option>
                            <option value="lawfirm">Law Firm</option>
                            <option value="auditor">
                              Auditors &amp; Accountants
                            </option>
                            <option value="buisnessconsultent">
                              Buisness Consultent
                            </option>{" "}
                          </Field>
                          {errors.type && (
                            <FormErrorMessage>{errors.type}</FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="email"
                          isInvalid={touched.email && errors.email}
                        >
                          <FormLabel>Email</FormLabel>
                          <Field
                            as={Input}
                            type="email"
                            name="email"
                            display={"block"}
                          />
                          {errors.email && (
                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="country"
                          isInvalid={touched.country && errors.country}
                        >
                          <FormLabel>Country</FormLabel>
                          <Field
                            as={Select}
                            placeholder={"Select Country"}
                            name="country"
                            onChange={(e) => {
                              setFieldValue("country", e.target.value);
                              setFieldValue("state", "");
                              setFieldValue("city", "");
                              fetchState(e.target.value);
                            }}
                          >
                            {!_countryloading &&
                              !_countryerror &&
                              _countrydata.data.map((e, i) => {
                                return (
                                  <option key={i} value={e._id}>
                                    {e.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.country && (
                            <FormErrorMessage>
                              {errors.country}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="state"
                          isInvalid={touched.state && errors.state}
                        >
                          <FormLabel>State</FormLabel>
                          <Field
                            as={Select}
                            placeholder={"Select state"}
                            name="state"
                            onChange={(e) => {
                              setFieldValue("state", e.target.value);
                              setFieldValue("district", "");
                              fetchDistrict(e.target.value);
                            }}
                          >
                            {stateData.length > 0 &&
                              stateData.map((e, i) => {
                                return (
                                  <option key={i} value={e._id}>
                                    {e.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.state && (
                            <FormErrorMessage>{errors.state}</FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="district"
                          isInvalid={touched.district && errors.district}
                        >
                          <FormLabel>District</FormLabel>
                          <Field
                            as={Select}
                            placeholder={"Select district"}
                            name="district"
                          >
                            {districtData.length > 0 &&
                              districtData.map((e, i) => {
                                return (
                                  <option key={i} value={e._id}>
                                    {e.name}
                                  </option>
                                );
                              })}
                          </Field>
                          {errors.district && (
                            <FormErrorMessage>
                              {errors.district}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="pinCode"
                          isInvalid={touched.pinCode && errors.pinCode}
                        >
                          <FormLabel>Pin Code</FormLabel>
                          <Field as={Input} type="pinCode" name="pinCode" />
                          {errors.pinCode && (
                            <FormErrorMessage>
                              {errors.pinCode}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <InputGroup display={"flex"} flexDir={"column"} gap={4}>
                          {new Array(phoneFields).fill(0).map((e, i) => {
                            return (
                              <FormControl
                                key={i}
                                name={"mobile" + i}
                                id={"mobile" + i}
                              >
                                <Flex
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  {i === 0 ? (
                                    <>
                                      <FormLabel>Phone</FormLabel>

                                      <Flex gap={2}>
                                        <AiOutlinePlusSquare
                                          size={25}
                                          cursor={"pointer"}
                                          onClick={() => {
                                            setPhoneFields(phoneFields + 1);
                                          }}
                                        />

                                        <AiOutlineMinusSquare
                                          size={25}
                                          cursor={"pointer"}
                                          onClick={() => {
                                            if (phoneFields > 1) {
                                              setPhoneFields(phoneFields - 1);
                                            }
                                          }}
                                        />
                                      </Flex>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Flex>
                                <Field name={`mobile${i}`}>
                                  {({ field, form }) => (
                                    <PhoneInput
                                      {...field}
                                      country={"us"}
                                      inputStyle={{
                                        width: "100%",
                                        height: "40px",
                                      }}
                                      value={
                                        currentOne.edit &&
                                        currentOne.data.mobile &&
                                        currentOne.data.mobile.split(",")[i]
                                      }
                                      name={`mobile${i}`}
                                      id={`mobile${i}`}
                                      onChange={(value, ___, _, formatted) => {
                                        form.setFieldValue("mobile" + i, value);
                                        setPhoneFieldValues([
                                          ...phoneFieldValues,
                                          value,
                                        ]);
                                      }}
                                    />
                                  )}
                                </Field>
                                {errors.mobile && (
                                  <FormErrorMessage>
                                    {errors.mobile}
                                  </FormErrorMessage>
                                )}
                              </FormControl>
                            );
                          })}
                        </InputGroup>

                        <FormControl
                          id="website"
                          isInvalid={touched.website && errors.website}
                        >
                          <FormLabel>Website</FormLabel>
                          <Field as={Input} type="text" name="website" />
                          {errors.website && (
                            <FormErrorMessage>
                              {errors.website}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl id="image">
                          <FormLabel>Profile of the member</FormLabel>
                          <Input
                            type={"file"}
                            required={!currentOne.edit}
                            accept={"image/*"}
                            onChange={(e) => {
                              changeFile(e.target.files[0]);
                            }}
                          />
                        </FormControl>

                        <FormControl
                          id="description"
                          name="description"
                          isInvalid={touched.description && errors.description}
                        >
                          <FormLabel>Description</FormLabel>
                          <Field
                            as={Textarea}
                            type="text"
                            name="description"
                            value={values.description}
                          />
                          {errors.description && (
                            <FormErrorMessage>
                              {errors.description}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="shortDescription"
                          name="shortDescription"
                          isInvalid={
                            touched.shortDescription && errors.shortDescription
                          }
                        >
                          <FormLabel>Short Description</FormLabel>
                          <Field
                            as={Textarea}
                            type="text"
                            name="shortDescription"
                            value={values.shortDescription}
                          />
                          {errors.shortDescription && (
                            <FormErrorMessage>
                              {errors.shortDescription}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="address"
                          name="address"
                          isInvalid={touched.address && errors.address}
                        >
                          <FormLabel>Address</FormLabel>
                          <Field
                            as={Textarea}
                            type="text"
                            name="address"
                            value={values.address}
                          />
                          {errors.address && (
                            <FormErrorMessage>
                              {errors.address}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="seoName"
                          isInvalid={touched.seoName && errors.seoName}
                        >
                          <FormLabel>Seo Title</FormLabel>
                          <Field as={Input} type="text" name="seoName" />
                          {errors.seoName && (
                            <FormErrorMessage>
                              {errors.seoName}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="seoDescription"
                          name="seoDescription"
                          isInvalid={
                            touched.seoDescription && errors.seoDescription
                          }
                        >
                          <FormLabel>Seo Description</FormLabel>
                          <Field
                            as={Textarea}
                            type="text"
                            name="seoDescription"
                            value={values.seoDescription}
                          />
                          {errors.seoDescription && (
                            <FormErrorMessage>
                              {errors.seoDescription}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        {currentOne.edit && (
                          <FormControl
                            id="canBuyUserPlan"
                            name="canBuyUserPlan"
                            isInvalid={
                              touched.canBuyUserPlan && errors.canBuyUserPlan
                            }
                          >
                            <input
                              type="checkbox"
                              name="canBuyUserPlan"
                              checked={values.canBuyUserPlan}
                              onChange={(e) => {
                                setFieldValue(
                                  "canBuyUserPlan",
                                  e.target.checked
                                );
                              }}
                            />
                            Can Buy User Plan
                            {errors.canBuyUserPlan && (
                              <FormErrorMessage>
                                {errors.canBuyUserPlan}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        )}

                        <Stack spacing={10} width={"20%"}>
                          <Button
                            colorScheme={"linkedin"}
                            type={"submit"}
                            color={"white"}
                            _hover={{
                              bg: "blue.500",
                            }}
                            mb={"8"}
                          >
                            {currentOne.edit ? "Update" : "Create"}
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* Display the data of model */}

        <Modal
          initialFocusRef={initialRef}
          isOpen={cacisOpen}
          onClose={() => {
            caconClose();
            setSelectedCaC({});
            setPhoneFields(1);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody pb={6} display={"flex"} flexDirection={"column"}>
              <Text as={"h3"}>Name</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedCaC.name}
              </Text>
              <Text as={"h3"}>Type</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedCaC.type}
              </Text>
              <Text as={"h3"}>Category</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedCaC.category}
              </Text>
              <Text as={"h3"}>Description</Text>
              <Text
                fontWeight={"normal"}
                style={{
                  textIndent: "30px",
                }}
              >
                {selectedCaC.description}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme={"linkedin"} onClick={caconClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box
          rounded={"xl"}
          px={4}
          w={"100%"}
          m={6}
          backgroundColor={"#FFFFFF"}
          border={"1px"}
          borderColor={"gray.200"}
        >
          <DataTable
            striped
            columns={columns}
            data={data.data}
            paginationRowsPerPageOptions={[10, 100, 500, 1000, 2000, 5000]}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            selectableRows
            selectedRows={currentSelectedRows}
            onSelectedRowsChange={handleRowSelected}
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
            clearSelectedRows={toggleCleared}
          />
        </Box>
      </Flex>
    </Layout>
  );
}
