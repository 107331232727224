import Layout from '../../components/layouts/base.jsx';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  IconButton,
  ModalCloseButton,
  Button,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { axiosInstance as axios } from '../../lib/axios.js';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import DeleteManyDialog from '../../components/admin/DeleteAll.jsx';

const JudgementCategory = Yup.object().shape({
  name: Yup.string().required('Name must Required'),
  seoName: Yup.string(),
  seoDescription: Yup.string(),
});

const customStyles = {
  rows: {
    style: {
      minHeight: '72px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      height: '7vh',
    },
  },
};

export default function Categories() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [currentSelectedRows, setcurrentSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [refetch, setrefetch] = useState(false);
  const [activeCategory, setActiveCategory] = useState({});

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(
      `/jcategories/paginate?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handleSearch = async (e, page = 1) => {
    setLoading(true);
    const res = await axios.get(
      `/jcategories/search/${e}?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    if (!filterText) {
      fetchData(page);
      return;
    }
    handleSearch(filterText, page);
  };

  const handleRowSelected = useCallback((state) => {
    setcurrentSelectedRows(state.selectedRows);
  }, []);

  const handleMultipleDelete = async (s) => {
    let ids;
    if (Array.isArray(s)) {
      ids = s.map((row) => row._id).join(',');
    } else if (s === 'all') {
      ids = 'all';
    }
    const _res = await axios.delete(`/jcategories/deleteall/${ids}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
    setToggleCleared(!toggleCleared);
    setFilterText('');
    setcurrentSelectedRows([]);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (!filterText) {
      setLoading(true);
      const res = await axios.get(
        `/jcategories/paginate?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/jcategories/search/${filterText}?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [refetch]);

  // a custom component act like search bar

  const FilterComponent = ({ filterText, onFilter, onClear, addUser }) => (
    <Box display={'flex'} gap={4}>
      {currentSelectedRows.length > 0 && (
        <Button
          width={'120px'}
          colorScheme={'red'}
          onClick={() => {
            handleMultipleDelete(currentSelectedRows);
          }}
        >
          Delete ({currentSelectedRows.length})
        </Button>
      )}

      <Input
        id="search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        width={'300px'}
        placeholder="Search"
      />
      <IconButton
        colorScheme={'linkedin'}
        onClick={onClear}
        icon={<CloseIcon />}
      />
    </Box>
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);

  const toast = useToast();

  const fetch = () => setrefetch(!refetch);

  // delete the category
  const deleteCategory = async (_) => {
    const _res = await axios.delete(`/jcategories/${_._id}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
  };

  const _openeditModel = (_data) => {
    onOpen();
    setActiveCategory({ ..._data });
  };

  const columns = [
    {
      name: 'S/N',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: 'Delete',
      cell: (_r) => (
        <Button colorScheme={'red'} onClick={() => deleteCategory(_r)}>
          Delete
        </Button>
      ),
    },
    {
      name: 'Edit',
      cell: (_r) => (
        <Button colorScheme={'blue'} onClick={() => _openeditModel(_r)}>
          Edit
        </Button>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        fetchData(1);
      }
    };

    return (
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        margin={'0'}
      >
        <Text fontSize="2xl" fontWeight={'extrabold'}></Text>
        <DeleteManyDialog
          title={'Judgement Categories'}
          onDelete={() => handleMultipleDelete('all')}
        />
        <FilterComponent
          onFilter={(e) => {
            setFilterText(e.target.value);
            if (e.target.value && e.target.value.length > 2) {
              handleSearch(e.target.value);
            } else if (!e.target.value) {
              fetchData(1);
            }
            // get the input with id "search" and focus after 400 ms
            setTimeout(() => {
              document.getElementById('search').focus();
            }, 200);
          }}
          onClear={handleClear}
          filterText={filterText}
        />
      </Box>
    );
  }, [filterText, resetPaginationToggle, currentSelectedRows]);

  return (
    <>
      <Layout>
        <Flex
          as={'main'}
          w={'100%'}
          bg={useColorModeValue('gray.50', 'gray.800')}
        >
          <Box
            bg={useColorModeValue('white', 'gray.700')}
            px={6}
            boxShadow={'md'}
            p={6}
            m={6}
            rounded={'xl'}
            w={'30%'}
          >
            <Text as={'h1'} fontSize={'2xl'} pb={'4'}>
              Managing Judgment Categories
            </Text>
            <Formik
              initialValues={{
                name: '',
                seoName: '',
                seoDescription: '',
              }}
              onSubmit={async (
                { name, seoName, seoDescription },
                { resetForm }
              ) => {
                const res = await axios.post('/jcategories/', {
                  name,
                  seoName,
                  seoDescription,
                });

                if (res.data.ok) {
                  toast({
                    description: res.data.message,
                    status: 'success',
                    position: 'bottom-right',
                  });
                  resetForm();
                  fetch();
                } else {
                  toast({
                    description: res.data.message,
                    status: 'error',
                    position: 'bottom-right',
                  });
                }
              }}
              validationSchema={JudgementCategory}
            >
              {({ errors, touched, resetForm, values, setFieldValue }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl
                      id="name"
                      isInvalid={errors.name}
                      onChange={(e) => {
                        setFieldValue('seoName', e.target.value);
                      }}
                    >
                      <FormLabel>Name</FormLabel>
                      <Field as={Input} type="text" name="name" />
                      {touched.name && errors.name && (
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="seoName"
                      isInvalid={touched.seoName && errors.seoName}
                    >
                      <FormLabel>Seo Title</FormLabel>
                      <Field as={Input} type="text" name="seoName" />
                      {errors.seoName && (
                        <FormErrorMessage>{errors.seoName}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="seoDescription"
                      name="seoDescription"
                      isInvalid={
                        touched.seoDescription && errors.seoDescription
                      }
                    >
                      <FormLabel>Seo Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="seoDescription"
                        value={values.seoDescription}
                      />
                      {errors.seoDescription && (
                        <FormErrorMessage>
                          {errors.seoDescription}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    {/* <FormControl
                      id="judgementCategory"
                      isInvalid={
                        touched.judgementCategory && errors.judgementCategory
                      }
                    >
                      <FormLabel>Parent Category</FormLabel>
                      <Field
                        as={Select}
                        placeholder={"Select Category"}
                        name="judgementCategory"
                      >
                        {!loading &&
                          !error &&
                          data.data.map((e, i) => {
                            return (
                              <option key={i} value={e.name}>
                                {e.name}
                              </option>
                            );
                          })}
                      </Field>
                      {errors.judgementCategory && (
                        <FormErrorMessage>
                          {errors.judgementCategory}
                        </FormErrorMessage>
                      )}
                    </FormControl> */}
                  </Stack>
                  <Button
                    bg={'blue.400'}
                    mt={4}
                    type={'submit'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                  >
                    Create
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
          <Box
            rounded={'xl'}
            px={4}
            boxShadow={'md'}
            w={'70%'}
            m={6}
            backgroundColor={'#FFFFFF'}
            border={'1px'}
            borderColor={'gray.200'}
          >
            <DataTable
              striped
              columns={columns}
              data={data.data}
              paginationRowsPerPageOptions={[10, 100, 500, 1000, 2000, 5000]}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              paginationServer
              progressPending={loading}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeader
              selectableRows
              selectedRows={currentSelectedRows}
              onSelectedRowsChange={handleRowSelected}
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              customStyles={customStyles}
              clearSelectedRows={toggleCleared}
            />
          </Box>
        </Flex>
      </Layout>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setActiveCategory({});
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={'flex'} flexDirection={'column'}>
            <Formik
              initialValues={{
                _name: activeCategory.name,
                _parentCategory: activeCategory.parent_id,
                _seoName: activeCategory.seoName,
                _seoDescription: activeCategory.seoDescription,
              }}
              onSubmit={async (
                { _name, _seoName, _seoDescription },
                { resetForm }
              ) => {
                const res = await axios.put(
                  `/jcategories/${activeCategory._id}`,
                  {
                    name: _name,
                    seoName: _seoName,
                    seoDescription: _seoDescription,
                  }
                );

                if (res.data.ok) {
                  toast({
                    description: res.data.message,
                    status: 'success',
                    position: 'bottom-right',
                  });
                  resetForm();
                  onClose();
                  fetch();
                } else {
                  toast({
                    description: res.data.message,
                    status: 'error',
                    position: 'bottom-right',
                  });
                }
              }}
            >
              {({ errors: _e, touched: _t, resetForm, values }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl id="_name" isInvalid={_e._name}>
                      <FormLabel>Name</FormLabel>
                      <Field as={Input} type="text" name="_name" />
                      {_t._name && _e._name && (
                        <FormErrorMessage>{_e._name}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="_seoName"
                      isInvalid={_t._seoName && _e._seoName}
                    >
                      <FormLabel>Seo Title</FormLabel>
                      <Field as={Input} type="text" name="_seoName" />
                      {_e._seoName && (
                        <FormErrorMessage>{_e._seoName}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="_seoDescription"
                      name="_seoDescription"
                      isInvalid={_t._seoDescription && _e._seoDescription}
                    >
                      <FormLabel>Seo Description</FormLabel>
                      <Field
                        as={Textarea}
                        type="text"
                        name="_seoDescription"
                        value={values._seoDescription}
                      />
                      {_e._seoDescription && (
                        <FormErrorMessage>
                          {_e._seoDescription}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Stack>
                  <ModalFooter>
                    <Button type="submit" colorScheme="blue" mr={3}>
                      Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
            {/* <Box
              h={"2px"}
              w={"100%"}
              margin={"auto"}
              backgroundColor={"#24232323"}
              borderRadius={"2xl"}
              marginTop={"1rem"}
            /> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
