import React from "react";
import { Editor } from "primereact/editor";
import { Box } from "@chakra-ui/react";

export default function TextEditor({ setEditorText, editorText }) {


  return (
    <Box
      width={"100%"}
      height={"500px"}
      fontWeight={"normal"}
      boxShadow={"sm"}
      border={"1px solid"}
      borderColor={"gray.200"}
    >
      <Editor
        value={editorText}
        style={{ width: "100%", all: "unset", height: "500px" }}
        onTextChange={(e) => {
          setEditorText(e.htmlValue);
        }}
        height={"500px"}
      />
    </Box>
  );
}
