import { createContext, useContext } from "react";

// its a global context that means, we can use it anywhere in our app, also it allows us to pass data to any component without having to pass props down manually at every level.
const GlobalContext = createContext({
  loggedIn: false,
  setLoggedIn: () => {},
  user: null,
  setUser: () => {},
  role: "",
  setRole: () => {},
});

export default GlobalContext;

export const useGlobalContext = () => useContext(GlobalContext);
