import Layout from '../../components/layouts/base.jsx';
import useFetch from '../../hooks/useFetch.jsx';
import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { MultiSelect } from 'primereact/multiselect';

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Icon,
  IconButton,
  Textarea,
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import { axiosInstance as axios } from '../../lib/axios.js';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';

import { FaEllipsisV, FaEdit, FaTrash, FaPlusCircle } from 'react-icons/fa';
import { config } from '../../common/config.js';
import Editor from '../../components/admin/Editor.jsx';
import DeleteManyDialog from '../../components/admin/DeleteAll.jsx';

const customStyles = {
  responsiveWrapper: {
    style: {
      overflowX: 'hidden',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      color: 'black',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      width: '10vw',
      height: '7vh',
      color: 'black',
    },
  },
};

const AddCaCSchema = Yup.object().shape({
  name: Yup.string().required('Name is Required'),
  type: Yup.string().required('Type is Required'),
  contracttype: Yup.array().required('Contract Type is Required'),
  similar: Yup.array().optional(),
  description: Yup.string().required('Description Required'),
  country: Yup.string(),
  industryType: Yup.string().required('Industry Type is Required'),
  language: Yup.string().required('Language is Required'),
  seoName: Yup.string(),
  seoDescription: Yup.string(),
});

const fetchCountry = async () => {
  const res = await axios.get('/countries');
  return res.data;
};

export default function ContractsAndClauses() {
  const [data, setData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [country, setCountry] = useState([]);
  const [editorText, setEditorText] = useState('');
  const [similarList, setsimilarList] = useState([]);
  const [refetch, setrefetch] = useState(false);

  const [currentSelectedRows, setcurrentSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [selectAll, setSelectAll] = useState(false);

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const fetchData = async (page) => {
    setLoading(true);
    const res = await axios.get(`/cac/paginate?page=${page}&limit=${perPage}`);
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handleSearch = async (e, page = 1) => {
    setLoading(true);
    const res = await axios.get(
      `/cac/search/${e}?page=${page}&limit=${perPage}`
    );
    setData(res.data);
    setTotalRows(res.data.total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    if (!filterText) {
      fetchData(page);
      return;
    }
    handleSearch(filterText, page);
  };

  const handleRowSelected = useCallback((state) => {
    setcurrentSelectedRows(state.selectedRows);
  }, []);

  const handleMultipleDelete = async (s) => {
    let ids;
    if (Array.isArray(s)) {
      ids = s.map((row) => row._id).join(',');
    } else if (s === 'all') {
      ids = 'all';
    }
    const _res = await axios.delete(`/cac/deleteall/${ids}`);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
    setToggleCleared(!toggleCleared);
    setFilterText('');
    setcurrentSelectedRows([]);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (!filterText) {
      setLoading(true);
      const res = await axios.get(
        `/cac/paginate?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await axios.get(
        `/cac/search/${filterText}?page=${page}&limit=${newPerPage}`
      );
      setData(res.data);
      setPerPage(newPerPage);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);

    fetchCountry().then((res) => {
      const data = res.data.map((r) => {
        return {
          label: r.name,
          value: r._id,
        };
      });
      setCountry(data);
    });

    return () => {};
  }, [refetch]);

  const [selectedFile, changeFile] = useState(null);
  const [selectedFile2, changeFile2] = useState(null);
  const [currentOne, setCurrentOne] = useState({
    edit: false,
    data: {},
  });
  const [selectedCaC, setSelectedCaC] = useState({});
  // const { data: categories, loading: categoriesLoading } =
  //   useFetch("/categories/getAll");
  const { data: Languages, loading: LanguagesLoading } = useFetch('/language');
  const { data: IndustryTypes, loading: IndustryTypesLoading } =
    useFetch('/industrytype');
  const { data: aggrementTypes, loading: aggrementtypesLoading } =
    useFetch('/aggrementtype');

  const fetch = () => setrefetch(!refetch);

  const CaCByType = async (type) => {
    const res = await axios.get('/cac/findbytype/' + type);
    if (res.data.ok) {
      const data = res.data.data.map((r) => {
        return {
          label: r.name,
          value: r._id,
        };
      });

      setsimilarList(data);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: cacisOpen,
    onOpen: caconOpen,
    onClose: caconClose,
  } = useDisclosure();

  const toast = useToast();
  const initialRef = useRef(null);

  const openCreateModel = () => {
    setCurrentOne({
      edit: false,
      data: {},
    });
    onOpen();
  };
  const deleteCategory = async (_r) => {
    const _res = await axios.delete('/cac/' + _r._id);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      fetch();
    } else {
      toast({
        description: _res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
    fetch();
  };

  const BulkUpload = async (file) => {
    if (!file)
      return toast({
        description: 'Please Select a file',
        status: 'error',
        position: 'bottom-right',
      });

    const formData = new FormData();
    formData.append('file', file);
    const res = await axios.post('/cac/bulk', formData);

    if (res.data.ok) {
      toast({
        description: res.data.message,
        status: 'success',
        position: 'bottom-right',
        isClosable: true,
      });
      fetch();
    } else {
      toast({
        description: res.data.message,
        status: 'error',
        position: 'bottom-right',
        isClosable: true,
      });
    }

    document.getElementById('file').value = '';
  };

  const editCategory = (_r) => {
    setCurrentOne({
      edit: true,
      data: _r,
    });

    onOpen();
    CaCByType(_r.type);
    setEditorText(_r.docContent ? _r.docContent : '');
  };

  const viewFile = (key) => {
    window.open(`${config.baseURL}/CaC/download/${key}`, '_blank');
  };

  const Actions = [
    {
      name: 'Edit',
      icon: FaEdit,
      onClick: editCategory,
      colorSchema: 'linkedin',
    },

    {
      name: 'Delete',
      icon: FaTrash,
      onClick: deleteCategory,
      colorSchema: 'red',
    },
  ];

  const _helpText = {
    clause: 'Clause',
    contract: 'Contract',
  };

  const columns = [
    {
      name: 'S/N',
      selector: (row, index) => index + 1,
      sortable: true,
      width: '3%',
    },
    {
      name: 'Name',
      cell: (_r) => (
        <Text
          onClick={() => {
            caconOpen();
            setSelectedCaC(_r);
          }}
          _hover={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          color={'#00A0DC'}
        >
          {_r.name}
        </Text>
      ),
      sortable: true,
      width: '170px',
    },
    {
      name: 'Contract / Clause',
      selector: (row, i, column) => _helpText[row.type],
      sortable: true,
      width: '200px',
    },
    {
      name: 'Country',
      selector: (row, i, column) =>
        country.find((c) => c.value === row.country)?.label || 'N/A',
      sortable: true,
      width: '7%',
    },
    {
      name: 'Industry',
      selector: (row, i, column) =>
        IndustryTypes?.data?.find((c) => c._id === row.industrytype)?.name ||
        'N/A',
      sortable: true,
      width: '10%',
    },
    {
      name: 'Aggre..Type',
      selector: (row, i, column) =>
        row?.aggrementType.length > 0
          ? [...row.aggrementType.map((e) => e.name)].join(',')
          : 'N/A',
      sortable: true,
      width: '7%',
    },
    {
      name: 'Description',
      selector: (row, i, column) => row.description,
      sortable: true,
      width: '11%',
    },
    {
      name: 'Document',
      selector: (row, i, column) =>
        row.document_name ? row.document_name : 'Contract not uploaded',
      cell: (_r) =>
        _r.document_name ? (
          <Button
            colorScheme={'linkedin'}
            onClick={() => viewFile(_r.document_name)}
          >
            View
          </Button>
        ) : (
          <Button colorScheme={'linkedin'}>Upload</Button>
        ),
      width: '6%',
    },
    {
      name: 'Actions',
      cell: (_r) => (
        <Box zIndex={100} position={'fixed'}>
          <Popover closeOnBlur={true}>
            <PopoverTrigger>
              <FaEllipsisV />
            </PopoverTrigger>
            <PopoverContent className="last_one" ml={6} minW={6}>
              <PopoverHeader fontWeight="semibold" textAlign={'center'}>
                Actions
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack direction={'column'}>
                  {Actions.map((link, i) => (
                    <Button
                      key={i}
                      onClick={() => link.onClick(_r)}
                      colorScheme={link.colorSchema}
                      _hover={{
                        color: 'black',
                      }}
                    >
                      {link.name}
                    </Button>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
    },
  ];

  // Custom component for filtering in a table
  const FilterComponent = ({ filterText, onFilter, onClear, addUser }) => (
    <Box display={'flex'} gap={4}>
      {currentSelectedRows.length > 0 && (
        <Button
          width={'120px'}
          colorScheme={'red'}
          onClick={() => {
            handleMultipleDelete(currentSelectedRows);
          }}
        >
          Delete ({currentSelectedRows.length})
        </Button>
      )}

      <Input
        id="search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        placeholder="Search"
        width={'300px'}
      />
      <IconButton
        colorScheme={'linkedin'}
        onClick={onClear}
        icon={<CloseIcon />}
      />
    </Box>
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        fetchData(1);
      }
    };

    return (
      <Box
        width={'100%'}
        display={'flex'}
        gap={4}
        margin={'0'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize="2xl" fontWeight={'extrabold'}>
          Contracts And Clauses
        </Text>
        <DeleteManyDialog
          title={'Contracts and Clauses'}
          onDelete={() => handleMultipleDelete('all')}
        />
        <Box display={'flex'} gap={2}>
          <Box mt={4}>
            <FilterComponent
              onFilter={(e) => {
                setFilterText(e.target.value);
                if (e.target.value && e.target.value.length > 3) {
                  handleSearch(e.target.value);
                } else if (!e.target.value) {
                  fetchData(1);
                }
                // get the input with id "search" and focus after 400 ms
                setTimeout(() => {
                  document.getElementById('search').focus();
                }, 200);
              }}
              onClear={handleClear}
              filterText={filterText}
            />
          </Box>
          <Button
            bg={'blue.400'}
            mt={4}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            onClick={() => {
              document.getElementById('file').value = '';
              document.getElementById('file').click();
            }}
          >
            Bulk Upload
          </Button>

          <IconButton
            onClick={openCreateModel}
            m={4}
            colorScheme={'linkedin'}
            icon={<FaPlusCircle />}
          />
        </Box>
      </Box>
    );
  }, [filterText, resetPaginationToggle, currentSelectedRows]);

  const handleSubmit = async (values, { resetForm }) => {
    const _values = {
      ...values,
      document: selectedFile,
    };

    let formData = new FormData();
    formData.set('name', values.name);
    formData.set('description', values.description);
    formData.set('aggrementType', values.contracttype);
    formData.set('similar', values.similar ? values.similar : []);
    formData.set('type', values.type);
    formData.set('country', values.country);
    formData.set('industrytype', values.industryType);
    formData.set('language', values.language);
    formData.set('document', selectedFile);
    formData.set('doc', selectedFile2);
    formData.set('seoName', values.seoName);
    formData.set('seoDescription', values.seoDescription);
    formData.set('docContent', editorText);

    const url = currentOne.edit ? '/CaC/' + currentOne.data._id : '/CaC/create';

    const _res = currentOne.edit
      ? await axios.put(url, {
          ...values,
          docContent: editorText,
        })
      : await axios.post(url, formData);
    if (_res.data.ok) {
      toast({
        description: _res.data.message,
        status: 'success',
        position: 'bottom-right',
      });
      resetForm();
      changeFile(null);
      changeFile2(null);
      setEditorText('');
      onClose();
      fetch();
      setCurrentOne({
        edit: false,
        data: {},
      });
    } else {
      toast({
        description: _res.data.message,
        status: 'error',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Layout>
      <Flex as={'main'} w={'95%'} bg={useColorModeValue('gray.50', 'gray.800')}>
        <input
          type={'file'}
          id={'file'}
          accept={
            '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          }
          onChange={(e) => {
            BulkUpload(e.target.files[0]);
          }}
          style={{ display: 'none' }}
        />
        <Modal
          initialFocusRef={initialRef}
          size={'6xl'}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setCurrentOne({
              edit: false,
              data: {},
            });
            setEditorText('');
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {!currentOne.edit ? 'Add New Contract or Clause' : 'Edit'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-evenly'}
              gap={10}
            >
              <Box w={'60%'}>
                <Formik
                  initialValues={{
                    name: currentOne.edit ? currentOne.data.name : '',
                    type: currentOne.edit ? currentOne.data.type : 'contract',
                    country: currentOne.edit ? currentOne.data.country : '',
                    industryType: currentOne.edit
                      ? currentOne.data.industrytype
                      : '',
                    language: currentOne.edit
                      ? currentOne.data.language
                      : 'English',
                    description: currentOne.edit
                      ? currentOne.data.description
                      : '',
                    contracttype: currentOne.edit
                      ? currentOne.data.aggrementType.map((e) => e._id)
                      : [],
                    similar: currentOne.edit ? currentOne.data.similar : [],
                    seoName: currentOne.edit ? currentOne.data.seoName : '',
                    seoDescription: currentOne.edit
                      ? currentOne.data.seoDescription
                      : '',
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={AddCaCSchema}
                >
                  {({ errors, touched, values, setValues, setFieldValue }) => (
                    <Form>
                      <Stack spacing={4}>
                        <FormControl
                          id="name"
                          isInvalid={touched.name && errors.name}
                          onChange={(e) => {
                            setFieldValue('seoName', e.target.value);
                          }}
                        >
                          <FormLabel>Name of the Contract or Clause</FormLabel>
                          <Field as={Input} type="text" name="name" />
                          {errors.name && (
                            <FormErrorMessage>{errors.name}</FormErrorMessage>
                          )}
                        </FormControl>
                        <FormControl
                          id="type"
                          isInvalid={touched.type && errors.type}
                        >
                          <FormLabel>Contract or Clause</FormLabel>
                          <Field
                            as={Select}
                            placeholder={'Select Type'}
                            name="type"
                            onChange={(e) => {
                              setFieldValue('type', e.target.value);
                              CaCByType(e.target.value);
                            }}
                          >
                            <option value="clause">Clause</option>
                            <option value="contract">Contract</option>
                          </Field>
                          {errors.type && (
                            <FormErrorMessage>{errors.type}</FormErrorMessage>
                          )}
                        </FormControl>
                        <FormControl
                          id="country"
                          isInvalid={touched.country && errors.country}
                        >
                          <FormLabel>Country</FormLabel>
                          <Field
                            as={Select}
                            placeholder={'Select country'}
                            name="country"
                          >
                            {country.map((e, i) => (
                              <option key={i} value={e.value}>
                                {e.label}
                              </option>
                            ))}
                          </Field>
                          {errors.country && (
                            <FormErrorMessage>
                              {errors.country}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="contracttype"
                          isInvalid={
                            touched.contracttype && errors.contracttype
                          }
                          name={'contracttype'}
                        >
                          <FormLabel>Contract Types</FormLabel>
                          <Field
                            as={MultiSelect}
                            className={'multiselect-customs'}
                            placeholder={'Select Contract Type'}
                            name="contracttype"
                            value={values.contracttype}
                            virtualScrollerOptions={{ itemSize: 43 }}
                            selectAll={selectAll}
                            filterMatchMode={'startsWith'}
                            onSelectAll={(e) => {
                              setValues({
                                ...values,
                                contracttype: e.checked
                                  ? []
                                  : aggrementTypes.data.map((item) => item._id),
                              });
                              setSelectAll(!e.checked);
                            }}
                            maxSelectedLabels={1}
                            filter
                            onChange={(e) => {
                              setValues({
                                ...values,
                                contracttype: e.value,
                              });
                              setSelectAll(
                                e.value.length === aggrementTypes.data.length
                              );
                            }}
                            options={
                              aggrementTypes && aggrementTypes.data
                                ? aggrementTypes.data.map((e) => ({
                                    value: e._id,
                                    label: e.name,
                                  }))
                                : []
                            }
                          ></Field>
                          {errors.contracttype && (
                            <FormErrorMessage>
                              {errors.contracttype}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="industryType"
                          isInvalid={
                            touched.industryType && errors.industryType
                          }
                        >
                          <FormLabel>Industry Type</FormLabel>
                          <Field
                            as={Select}
                            placeholder={'Select Industry Type'}
                            name="industryType"
                          >
                            {IndustryTypes.data.map((e, i) => (
                              <option key={i} value={e._id}>
                                {e.name}
                              </option>
                            ))}
                          </Field>
                          {errors.industryType && (
                            <FormErrorMessage>
                              {errors.industryType}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                        <FormControl
                          id="language"
                          isInvalid={touched.language && errors.language}
                        >
                          <FormLabel>Language</FormLabel>
                          <Field
                            as={Select}
                            placeholder={'Select language'}
                            name="language"
                          >
                            {Languages.data.map((e, i) => (
                              <option key={e.name} value={e.name}>
                                {e.name}
                              </option>
                            ))}
                          </Field>
                          {errors.language && (
                            <FormErrorMessage>
                              {errors.language}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="similar"
                          isInvalid={touched.similar && errors.similar}
                        >
                          <FormLabel>Similar Contract or Clause</FormLabel>
                          <Field
                            as={MultiSelect}
                            placeholder={'Select similar'}
                            name="similar"
                            maxSelectedLabels={1}
                            value={currentOne.edit ? values.similar : []}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                similar: e,
                              });
                            }}
                            options={
                              similarList && similarList?.length > 0
                                ? similarList.map((e, i) => ({
                                    label: e.label,
                                    value: e.value,
                                  }))
                                : []
                            }
                            disabled={similarList?.length === 0}
                          ></Field>
                          {errors.similar && (
                            <FormErrorMessage>
                              {errors.similar}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl id="file">
                          <FormLabel>Document for Contract or Clause</FormLabel>
                          <Input
                            type={'file'}
                            accept={'application/pdf'}
                            onChange={(e) => {
                              changeFile(e.target.files[0]);
                            }}
                          />
                        </FormControl>

                        <FormControl
                          id="description"
                          name="description"
                          isInvalid={touched.description && errors.description}
                        >
                          <FormLabel>Description</FormLabel>
                          <Field
                            as={Textarea}
                            type="text"
                            name="description"
                            value={values.description}
                          />
                          {errors.description && (
                            <FormErrorMessage>
                              {errors.description}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl id="file">
                          <FormLabel>Additional Document</FormLabel>
                          <Input
                            type={'file'}
                            accept={'application/pdf'}
                            onChange={(e) => {
                              changeFile2(e.target.files[0]);
                            }}
                          />
                        </FormControl>

                        <FormControl
                          id="seoName"
                          isInvalid={touched.seoName && errors.seoName}
                        >
                          <FormLabel>Seo Title</FormLabel>
                          <Field as={Input} type="text" name="seoName" />
                          {errors.seoName && (
                            <FormErrorMessage>
                              {errors.seoName}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          id="seoDescription"
                          name="seoDescription"
                          isInvalid={
                            touched.seoDescription && errors.seoDescription
                          }
                        >
                          <FormLabel>Seo Description</FormLabel>
                          <Field
                            as={Textarea}
                            type="text"
                            name="seoDescription"
                            value={values.seoDescription}
                          />
                          {errors.seoDescription && (
                            <FormErrorMessage>
                              {errors.seoDescription}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <Stack spacing={10}>
                          <Button
                            colorScheme={'linkedin'}
                            type={'submit'}
                            color={'white'}
                            _hover={{
                              bg: 'blue.500',
                            }}
                            mb={'8'}
                          >
                            {currentOne.edit ? 'Update' : 'Create'}
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Box>
              <Editor setEditorText={setEditorText} editorText={editorText} />
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* Display the data of model */}

        <Modal
          initialFocusRef={initialRef}
          isOpen={cacisOpen}
          onClose={() => {
            caconClose();
            setSelectedCaC({});
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody pb={6} display={'flex'} flexDirection={'column'}>
              <Text as={'h3'}>Name</Text>
              <Text
                fontWeight={'normal'}
                style={{
                  textIndent: '30px',
                }}
              >
                {selectedCaC.name}
              </Text>
              <Text as={'h3'}>Type</Text>
              <Text
                fontWeight={'normal'}
                style={{
                  textIndent: '30px',
                }}
              >
                {selectedCaC.type}
              </Text>
              <Text as={'h3'}>Category</Text>
              <Text
                fontWeight={'normal'}
                style={{
                  textIndent: '30px',
                }}
              >
                {selectedCaC.category}
              </Text>
              <Text as={'h3'}>Description</Text>
              <Text
                fontWeight={'normal'}
                style={{
                  textIndent: '30px',
                }}
              >
                {selectedCaC.description}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme={'linkedin'} onClick={caconClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box
          rounded={'xl'}
          px={4}
          w={'85%'}
          m={6}
          backgroundColor={'#FFFFFF'}
          border={'1px'}
          borderColor={'gray.200'}
        >
          <DataTable
            striped
            columns={columns}
            data={data.data}
            paginationRowsPerPageOptions={[10, 100, 500, 1000, 2000, 5000]}
            pagination
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            paginationComponentOptions={{
              selectAllRowsItemText: 'All',
            }}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            selectableRows
            selectedRows={currentSelectedRows}
            onSelectedRowsChange={handleRowSelected}
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles}
            clearSelectedRows={toggleCleared}
          />
        </Box>
      </Flex>
    </Layout>
  );
}
